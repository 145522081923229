import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Snackbar } from '@mui/material';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import './assets/css/App.css';
import { getSnackbarVisibilityStatus, getSnackbarMessage,setLoadingStatus,
  getSnackbarSeverity, setSnackbarVisibilityStatus, getLoadingStatus, getSnackbarAutoHideDuration
} from './store/layout/reducer';
  // ----------------------------------------------------------------------

export default function App() {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => getLoadingStatus(state));

  const showSnackbar = useSelector(state => getSnackbarVisibilityStatus(state));
  const snackbarMessage = useSelector(state => getSnackbarMessage(state));
  const snackbarSeverity = useSelector(state => getSnackbarSeverity(state));
  const autohideDuration = useSelector(state => getSnackbarAutoHideDuration(state));

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text='Loading...' styles={{zIndex: '9999999999'}}>
      <ThemeProvider>
        <ScrollToTop />
        <BaseOptionChartStyle />
        <Router />
      </ThemeProvider>
      <Snackbar open={showSnackbar} autoHideDuration={autohideDuration} onClose={(e) => {
          dispatch(setSnackbarVisibilityStatus({'status' : false}));
        }} sx={{zIndex:'99999'}}>
        <Alert onClose={( ) => { }} 
          severity={snackbarSeverity} sx={{ width: '100%', zIndex:'9999' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </LoadingOverlay>
  );
}
