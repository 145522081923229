import { useSelector, useDispatch } from 'react-redux';  
import { Form, FormikProvider, useFormik } from 'formik';
import { useState,useEffect  } from 'react';
import * as Yup from 'yup';
import { useCookies } from 'react-cookie';

// material
import { Grid, Button, Stack, Link, SwipeableDrawer, Typography, TextField, Box, InputAdornment, DialogContent, Dialog } from '@mui/material';
import { useTheme } from '@mui/material/styles';


// components 
import { getActiveClient } from '../../../store/coach/reducer'; 
import { addClientAddress } from '../../../data/network/client/api';

export default function AddressMapContainer( {handleClose} ) {
    const theme = useTheme();
    const clientDetails = useSelector(state => getActiveClient(state));
    
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const dispatch = useDispatch(); 

    const [selectedPlace, setSelectedPlace] = useState(null);   

    const handleAddressSelected = () => {
       handleClose();
    };

    const onMarkerClick = () => {

    }

    const onInfoWindowClose = () => {

    }

    return (
        <></>
        // <Map google={google} zoom={14}>
        //     <Marker onClick={onMarkerClick}
        //         name={'Current location'} />

        //     <InfoWindow onClose={onInfoWindowClose}>
        //         <div>
        //             <h1>{selectedPlace.name}</h1>
        //         </div>
        //     </InfoWindow>
        // </Map>
    );
}