import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// material
import { alpha, styled } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';
import { Box, Grid, Stack, AppBar, Toolbar, IconButton, Typography, FormControl, Select, MenuItem } from '@mui/material';
// components
import Iconify from '../../components/Iconify';
import { getUser, setActiveCorporateClient, getActiveCorporateClient, getActiveRole, setActiveRole } from '../../store/user/reducer';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import { APP_BAR_DESKTOP, APP_BAR_MOBILE, DRAWER_WIDTH } from '../../constants/layout_constants';
import {ROLES} from '../../constants/app_constants';
import { getActiveStore, setActiveStore } from '../../store/partner_store/reducer';

// ----------------------------------------------------------------------

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 1),
  // borderRadius: Number(theme.shape.borderRadius) * 1.5,
  // backgroundColor: theme.palette.grey[500_12],
  background: 'rgba(255, 255, 255, 0.2)',
	borderRadius: '5px',
	boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
	backdropFilter: 'blur(2px)',
  webkitBackdropFilter: 'blur(10px)',
	border: '1px solid rgba(255, 255, 255, 0.4)',
  marginTop: theme.spacing(1)
}));

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: '0 0px 4px rgb(0 0 0 / 20%)',
  backdropFilter: 'blur(10px)',
  WebkitBackdropFilter: 'blur(10px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.5),
  [theme.breakpoints.up('xl')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
  borderRadius: '0!important'
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APP_BAR_MOBILE,
  [theme.breakpoints.up('xl')]: {
    minHeight: APP_BAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const client = useSelector(state => getUser(state));

  const activeClient = useSelector(state => getActiveCorporateClient(state));
  const activeStore = useSelector(state => getActiveStore(state));

  const activeRole = useSelector(state => getActiveRole(state));
  const userDetails = useSelector(state => getUser(state));

  const [isRoleListVisible, setIsRoleListVisible] = useState(false); 

  // console.log(activeRole);
  const handleCorporateChange = (value) => {
    dispatch(setActiveCorporateClient({client: value}));
  }


  const handleStoreChange = (value) => {
    dispatch(setActiveStore({data: value}));
  }


  useEffect(() => {
    if( userDetails && userDetails.roles && activeRole === null){
      dispatch(setActiveRole({role: userDetails.roles[0]}));
    }

    if (userDetails && userDetails.corporate_client && userDetails.corporate_client.length > 0) {
      dispatch(setActiveCorporateClient({client : userDetails.corporate_client[0]}));
    }
  }, [userDetails, activeRole]);
 
  const handleRoleChange = (selectedOption) => {
    setIsRoleListVisible(false);
    dispatch(setActiveRole({role: selectedOption}));

    if(Object.keys(ROLES)){
      Object.keys(ROLES).forEach(element => {
        if(ROLES[element].name === selectedOption.name){
          navigate(ROLES[element].path);
        }
      });
    }
  };

  const RoleSelector = ( ) => {  
    if(userDetails && userDetails.roles && userDetails.roles.length > 1){
      return <Grid container className='w-100'>
          <Grid container justifyContent='space-between' style={{ width:'100%'}}> 
            <Grid item>
              <Typography variant="body1">{activeRole ? activeRole.display_name : ""}  </Typography>  
            </Grid>
            <Grid item>
              { isRoleListVisible 
                ? <Iconify icon="eva:arrow-ios-upward-outline" />
                : <Iconify icon="eva-arrow-ios-downward-outline" />
              } 
            </Grid>
          </Grid>          
        </Grid>  
    } 
    return  <Typography variant="body1">{activeRole && activeRole.display_name}</Typography> 
  } 
  
  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary',
         display: { xl: 'none' } 
        }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

          {
            activeRole && activeRole.name === 'CORPORATE_MANAGER' && <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  className="glass-box"
                  value={activeClient?.id}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {client?.corporate_client?.map((ele) => {
                  return <MenuItem key={ele.id} value={ele.id} onClick={() => handleCorporateChange(ele)}>{ele.name}</MenuItem>
                })}

                </Select>
              </FormControl>
            </Box>
          }

          {
            activeRole && activeRole.name === 'STORE_MANAGER' && <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  className="glass-box"
                  value={activeStore?.id}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {client?.stores?.map((ele) => {
                  return <MenuItem key={ele.id} value={ele.id} onClick={() => handleStoreChange(ele)}>{ele.display_name}</MenuItem>
                })}

                </Select>
              </FormControl>
            </Box>
          }
        
        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {!isMobile && <FormControl sx={{ m: 1, minWidth: 200 }}>
              <Select
                className="glass-box"
                value={activeRole.name}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {
                  userDetails && userDetails.roles && userDetails.roles.map((role , idx) => {
                    return ROLES[role.name] && <MenuItem key={idx} value={role.name} onClick={() => handleRoleChange(role)}>{role.display_name} </MenuItem>
                  }
                )}

              </Select>
            </FormControl>
          }
        </Box>

        <Box sx={{ padding: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          
          {/* <Box sx={{ mb: 5, mx: 2.5, cursor:'pointer' }} onClick={() =>{
            setIsRoleListVisible(!isRoleListVisible);
          }} >

            <AccountStyle>
              <Grid sx={{ mt: 1, width:'100%' }} justifyContent='space-between' >
                <RoleSelector />                
              </Grid>
            </AccountStyle> 
            <ul key="favorites-list" className={ isRoleListVisible ? `role-list-container` : 'role-list-container hidden' } >
              {
                userDetails && userDetails.roles && userDetails.roles.map((role , idx) => 
                  {
                    return ROLES[role.name] !== undefined 
                    ? <li className='role-list-item' key={`favorite-${idx}`}>
                      <Grid
                        // href='#'
                        key={`favorite-a-${idx}`}
                        // tabIndex={idx}
                        // role="button"
                        container
                        hover='true'
                        direction='row'
                        justifyContent='space-between'
                        onClick={() => handleRoleChange(role)}
                        style={{fontSize: '0.8rem', textDecoration: 'none',  cursor:'pointer', padding:16}}
                      >
                        <Grid item>
                          {role.display_name}
                        </Grid>

                        <Grid item>
                          { (role.name === activeRole.name ) 
                            ? <Typography style={{fontSize: '0.8rem', textDecoration:'underline' }}>
                                <Iconify icon="fa:check-circle-o" style={{color: 'green'}} />
                              </Typography> 
                            : <Typography style={{fontSize: '0.8rem', textDecoration:'underline' }}>Select</Typography> 
                          }
                        </Grid>
                      </Grid>
                  </li>: null })
              }
            </ul>
          </Box> */}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
