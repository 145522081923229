export default function Table(theme) {
    return {
      MuiTable: {
        styleOverrides: {
          root: {
            '.MuiTableHead-root': {
                background: '#D5FFF6',
            },
            '.MuiTableBody-root': {
                background: '#fff',
            }
          }
        }
      }
    };
  }
  