import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Stack, Typography, Box, TextField, Select, MenuItem, Button, styled } from '@mui/material';
import { useCookies } from 'react-cookie';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { updateCorporateUser, fetchCorporateUsers } from '../../data/network/corporate/api';
import { formatDate } from '../../utils/date';
import { getActiveCorporateClient } from '../../store/user/reducer';

const TextFieldStyle = styled(TextField)(() => ({
    '& .MuiInputBase-input': {
        padding: '8px 14px',
    }
}));

const SelectStyle = styled(Select)(() => ({
    '& .MuiSelect-select': {
        padding: '8px 14px',
    }
}));

export const CorporateUserDetail = ({ data, visible, setOpen }) => {
    const [editEnable, setEditEnable] = useState(true);
    const [userData, setUserData] = useState({});
    const [date, setDate] = useState(null);

    const dispatch = useDispatch();
    const activeClient = useSelector(state => getActiveCorporateClient(state));
    const [cookies] = useCookies(['mobile', 'session', 'access_token']);

    useEffect(() => {
        setUserData(data);
    }, [data])

    const handleChange = (event) => {
        setUserData({ ...userData, [event.target.name]: event.target.value })
    }

    const handleDate = (newValue) => {
        setDate(formatDate(newValue));
        setUserData({ ...userData, dob: formatDate(newValue) })
    }

    const handleUpdate = () => {
        updateCorporateUser(dispatch, cookies, userData, activeClient)
        setOpen(false);
    }
    return (
        <Drawer
            anchor='right'
            open={visible}
            onClose={() => setOpen(false)}
        >
            <Stack sx={{ width: 400 }} style={{ padding: '20px', gap: '10px' }}>
                <Box sx={{ pb: 2 }} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='h6'>Detail View</Typography>
                    <Button size='small' color='info' onClick={() => setEditEnable(false)}>Edit</Button>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>First Name</Typography>
                    <TextFieldStyle disabled={editEnable} name='first_name' value={userData?.first_name} onChange={handleChange} />
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Middle Name</Typography>
                    <TextFieldStyle disabled={editEnable} name='middle_name' value={userData?.middle_name} onChange={handleChange} />
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Last Name</Typography>
                    <TextFieldStyle disabled={editEnable} name='last_name' value={userData?.last_name} onChange={handleChange} />
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Gender</Typography>
                    <SelectStyle
                        value={userData?.gender}
                        onChange={handleChange}
                        displayEmpty
                        disabled={editEnable}
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{ width: 220 }}
                        name="gender"
                    >
                        <MenuItem value="MALE" >MALE</MenuItem>
                        <MenuItem value="FEMALE" >FEMALE</MenuItem>
                    </SelectStyle>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Email</Typography>
                    <TextFieldStyle disabled={editEnable} name='email' type='email' value={userData?.email} onChange={handleChange} />
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>DOB</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            inputFormat="YYYY/MM/DD"
                            value={date}
                            onChange={handleDate}
                            disabled={editEnable}
                            renderInput={(params) => <TextFieldStyle {...params} sx={{ width: 220 }} />}
                        />
                    </LocalizationProvider>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Mobile</Typography>
                    <TextFieldStyle disabled={editEnable} name='mobile' type='number' value={userData?.mobile} onChange={handleChange} />
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Height</Typography>
                    <TextFieldStyle disabled={editEnable} name='height' type='number' value={userData?.height} onChange={handleChange} />
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Weight</Typography>
                    <TextFieldStyle disabled={editEnable} name='weight' type='number' value={userData?.weight} onChange={handleChange} />
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Meal Preference</Typography>
                    <SelectStyle
                        value={userData?.meal_preference}
                        onChange={handleChange}
                        displayEmpty
                        name='meal_preference'
                        disabled={editEnable}
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{ width: 220 }}
                    >
                        <MenuItem value="veg" >Veg</MenuItem>
                        <MenuItem value="non-veg" >Non-Veg</MenuItem>
                    </SelectStyle>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Medical Condition</Typography>
                    <Typography>{userData?.medical_condition}</Typography>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Allergy</Typography>
                    <Typography>{userData?.allergy}</Typography>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Acitivity</Typography>
                    <Typography>{userData?.activity}</Typography>
                </Box>
                {!editEnable && <Button sx={{ mt: 2, pt: 1, pb: 1 }} size='small' variant='contained' onClick={handleUpdate}>Update</Button>}
            </Stack>
        </Drawer>
    )
}