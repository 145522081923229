import { useSelector, useDispatch } from 'react-redux';  
import { Form, FormikProvider, useFormik } from 'formik';
import { useState  } from 'react';
import * as Yup from 'yup';
import { useCookies } from 'react-cookie';

// material
import { Grid, Button, Stack, Typography, TextField, Box, InputAdornment, DialogContent, Dialog, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components 
import { getActiveClient } from '../../../store/coach/reducer'; 
import { addPartnerDish } from '../../../data/network/dish/api'; 
import { resetAddActivityStatus } from '../../../store/activity/reducer';
import { getPartnerDish, getUserId } from '../../../store/user/reducer';
import {dishCategoryList} from '../../../constants/app_constants';
import { FileUpload } from '../../../components/file-upload/FileUpload';

export default function BulkUploadActivityForm( {handleClose} ) {
    const theme = useTheme();
    const clientDetails = useSelector(state => getActiveClient(state));
    const partnerId = useSelector((state) => getUserId(state));

    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const dispatch = useDispatch();
      
    const handleBulkUpload = () => {
        addPartnerDish(dispatch, cookies, {

        });
        dispatch(resetAddActivityStatus());
        handleClose();
    };

    const dishType = [
        {
            "display_name"  : "Public",
            "value" : "public"
        },
        {
            "display_name"  : "Private",
            "value" : "private"
        }
    ];
    

    const ActivitySchema = Yup.object().shape({
        file: Yup.string().required('name is required')  ,
    });

    const formik = useFormik({
        initialValues: {
          file: '',
        },
        validationSchema: ActivitySchema,
        onSubmit: () => {
  
        },
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    const fileUploadProp = {
        accept: ".csv,.xls,.xlsx",
        onChange: event => {
          if (event.target.files !== null && event.target?.files?.length > 0) {
            console.log(`Saving ${event.target.value}`)
          }
        },
        onDrop: event => {
          console.log(`Drop ${event.dataTransfer.files[0].name}`)
        }
    }

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

                <Grid  sx={{padding:2, borderRadius: 2}}> 
                    <Grid container direction="column"  > 
                        <Grid item container direction="row" justifyContent="flex-start">
                            <Typography variant="h6" gutterBottom>
                                Bulk Upload Activity
                            </Typography>
                        </Grid>
                        <Grid item container direction="column" justifyContent="center">
                            <Box margin={2} />
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                <FileUpload {...fileUploadProp} />
                            </Stack>
                            <Box margin={2} />

                            <Grid item container direction="row" justifyContent="center">
                                <Button variant='contained' onClick={() => {
                                    handleBulkUpload();
                                }}>
                                    Upload
                                </Button>                            
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Form>
        </FormikProvider>  

    );
}
