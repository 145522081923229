import { useEffect, useState } from 'react';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Card, Stack, Button, Dialog, DialogContent, SwipeableDrawer } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import DishList from '../../sections/coach/dish/DishList';

import { getPartnerDish } from '../../store/user/reducer';
import { fetchPartnerDish, fetchPartnerMeals } from '../../data/network/dish/api';
import DishListCard from '../../sections/coach/dish/dish_list/DishListCard';
import DishDetail from '../../sections/coach/dish/DishDetail';
import CreateDishForm from '../../sections/coach/dish/dish_list/CreateDishForm';
import BulkUploadDishForm from '../../sections/coach/dish/BulkUploadDishForm';
import { setSelectedDish } from '../../store/dish/reducer';
// sections


// ----------------------------------------------------------------------

export default function Dish() {
  const theme = useTheme();

  const navigate = useNavigate();

  const dataList = useSelector(state => getPartnerDish(state));

  const dispatch = useDispatch(); 
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);
  const [open, setOpen] = useState(false);

  const [bulkUploadDrawerOpen, setBulkUploadDrawerOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const dishId = searchParams.get("dish_id");

  useEffect(() => {
      fetchPartnerDish(dispatch, cookies);
      fetchPartnerMeals(dispatch, cookies); 
  },[]);

  useEffect(() => {
    console.log(dataList);
  },[dataList]);

  const showAddNewDishDrawer = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

  const handleBulkUploadDrawer = () => {
    setBulkUploadDrawerOpen(true);
  }

  const handleBulkUploadDrawerClose = () => {
    setBulkUploadDrawerOpen(false);
  }

  const handleExportAll = () => {

  }

  const toggleBulkUploadModal = (anchor , open ) => (event ) => {
    if (
        event &&
        event.type === 'keydown' &&
        ((event ).key === 'Tab' ||
        (event ).key === 'Shift')
    ) {
        return;
    }    
    setBulkUploadDrawerOpen(open); 
  };

  return (
    <Page title="Dish">
      {
        (!dishId || dishId == null)
        ? <Container maxWidth="xl">         
          <Stack direction="row" alignItems="center" justifyContent="space-between" margin={2}>
            <Typography variant="h7" gutterBottom>
                Select an dish to view their details : 
            </Typography>

            <Grid item>
              <Button variant="contained" justifyContent="center" alignItems="center" onClick={() => {
                setBulkUploadDrawerOpen(true);
              }}>
                  Bulk Upload
              </Button>
              <Button variant="contained" justifyContent="center" alignItems="center" onClick={() => {
                handleExportAll();
              }}>
                  Export All
              </Button> 
              <Button variant="contained" justifyContent="center" alignItems="center" onClick={() => {
                  showAddNewDishDrawer(); 
              }}>
                  Add new Dish
              </Button> 
            </Grid>
          </Stack>

          <Grid container spacing={3} > 
            {
                dataList && dataList.map((e) => <Grid key={e.product_id} item xs={12} sm={12} md={4} xl={3} lg={3} onClick={() => {
                    dispatch(setSelectedDish({data: e}));
                    navigate(`/coach/dish?dish_id=${e.product_id}`)
                }} sx={{cursor: 'pointer'}}>
                        <DishListCard dish={e} />
                    </Grid>)
            }
          </Grid>

          <Dialog open={open} onClose={handleClose}> 
              <DialogContent> 
                  <CreateDishForm handleClose={handleClose} />                    
              </DialogContent>
          </Dialog>

          <SwipeableDrawer 
            anchor="bottom" 
            open={bulkUploadDrawerOpen} 
            onClose={toggleBulkUploadModal('bottom', false)}
            onOpen={toggleBulkUploadModal( 'bottom', true)}>
              <DialogContent>
                  <BulkUploadDishForm handleClose={toggleBulkUploadModal('bottom', false)} />                    
              </DialogContent>
          </SwipeableDrawer>
        </Container>
        :  <DishDetail />
      }

    </Page>
  );
}
