import { LoadingButton } from "@mui/lab";
import { Container, Grid, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { getOnboardingStatus, resetOnboardingForm } from "../../../store/onboarding/reducer";
import {ReactComponent as CompleteIcon} from '../../../assets/img/tick.svg';
import { clearOTPState } from "../../../store/otp/reducer";
import { getActiveClient, getClientPaymentMode, resetClientState, getClientOrderType } from "../../../store/coach/reducer";
import { ONBOARDING_STATUS, PAYMENT_MODE } from "../../../constants/app_constants";
import FailedIcon from "../../../assets/img/fail.png";
import { confirmOrder, fetchClientList } from "../../../data/network/client/api";
import { getUserId } from "../../../store/user/reducer";


export default function OnboardingConfirmation({handleClose}){

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const onboardingStatus = useSelector(state => getOnboardingStatus(state));


    const activeClient = useSelector(state => getActiveClient(state));
    const paymentMode = useSelector(state => getClientPaymentMode(state));
    const orderType = useSelector(state => getClientOrderType(state));
    const partnerId = useSelector(state => getUserId(state));
    // const orderDetails = useSelector(state => getClientOrderDetails(state));
    // const addTransactionDetails = useSelector(state => getAddTransactionDetails(state));

    useEffect(() => {

    },[]);

    const handleAddNewClient = () => {
        clearOnboaringForm(); 
        handleClose();
        // setTimeout(() => {
        //     window.location.reload(); 
        // },1500);
        // navigate('/coach/client');
    };

    const clearOnboaringForm = () => {
        dispatch(clearOTPState());
        dispatch(resetClientState());
        dispatch(resetOnboardingForm());
        fetchClientList(dispatch, cookies);
    }

    const handleRedirectToClientPage = () => {
        clearOnboaringForm(); 
        handleClose();
        // setTimeout(() => {
        //     window.location.reload(); 
        // },1500);
        
        // navigate('/coach/client');
    };

    const handleRetry = () => {

        let paramPaymentMode = "POST_PAID";
        if(paymentMode === PAYMENT_MODE.ONLINE){
            paramPaymentMode = "PRE_PAID";
        }

        confirmOrder(dispatch, cookies, {       
            "payment_mode" : paramPaymentMode,
            "user_id" : activeClient.user_id,
            "partner_id" : partnerId, 
            "order_type": orderType
        });
    };

    return (
        <Box>

            {
                onboardingStatus === ONBOARDING_STATUS.SUCCESS && 
                <Grid container justifyContent="center" alignItems="center" direction="column">
                        <Box padding={4} />
                         <CompleteIcon height="100" width="100" />
                        <Box padding={2} />
                        <h4>Client has been onboarded successfully! </h4>
                        <div />
            
                        <Box padding={2} />

                        <Grid  justifyContent="center" alignItems="center" container>
                            <Box padding={2} style={{width:'40%'}}>
                                <LoadingButton fullWidth size="large" onClick={() => handleAddNewClient()}variant="contained" loading={isSubmitting}>
                                    Add New Client
                                </LoadingButton> 
                            </Box>
                            <Box padding={2} style={{width:'40%'}}>
                                <LoadingButton fullWidth size="large" onClick={() => handleRedirectToClientPage()}variant="contained" loading={isSubmitting}>
                                    Go to dashboard
                                </LoadingButton> 
                            </Box>
                        </Grid>

                 </Grid>    
            }
            {
                onboardingStatus === ONBOARDING_STATUS.FAIL && 
                <Grid container justifyContent="center" alignItems="center" direction="column">
                        <Box padding={4} />
                         <img src={ FailedIcon } height="100" width="100" alt="fail " />
                        <Box padding={2} />
                        <h4>Some error occured while confirming your subscription</h4>
                        <div />
            
                        <Box padding={2} />

                        <Grid  justifyContent="center" alignItems="center" container>
                            <Box padding={2} style={{width:'30%'}}>
                                <LoadingButton fullWidth size="large" onClick={() => handleRetry()}variant="contained" loading={isSubmitting}>
                                    Retry
                                </LoadingButton> 
                            </Box> 
                        </Grid>

                 </Grid>
        }
        </Box>    
       
    );

}