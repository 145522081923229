import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';

// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack, Container, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveRole, getUser, getUserName, setActiveRole } from '../../store/user/reducer';
// mock
import account from '../../_mock/account';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';

import {ROLES} from '../../constants/app_constants';
//
// import navConfig from './NavConfig';

import { getSideNavigationMenuConfig } from '../../utils/nav_helper';
import Iconify from '../../components/Iconify'; 
import { DRAWER_WIDTH } from '../../constants/layout_constants';

// ----------------------------------------------------------------------

// const DRAWER_WIDTH = 240;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('xl')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
    // backdropFilter: 'blur(10px)',
    // WebkitBackdropFilter: 'blur(10px)', // Fix on Mobile  
  },
}));


const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 1),
  // borderRadius: Number(theme.shape.borderRadius) * 1.5,
  // backgroundColor: theme.palette.grey[500_12],
  background: 'rgba(255, 255, 255, 0.2)',
	borderRadius: '5px',
	boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
	backdropFilter: 'blur(2px)',
  webkitBackdropFilter: 'blur(10px)',
	border: '1px solid rgba(255, 255, 255, 0.4)',
  marginTop: theme.spacing(1)
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isDesktop = useResponsive('up', 'xl');
  const userName = useSelector(state => getUserName(state));

  const userDetails = useSelector(state => getUser(state));
  const [roleOptions, setRoleOptions] = useState([ ]);
  const [isRoleListVisible, setIsRoleListVisible] = useState(false);
  
  const activeRole = useSelector(state => getActiveRole(state));

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if( userDetails && userDetails.roles && activeRole === null){
      dispatch(setActiveRole({role: userDetails.roles[0]}));
    }
  }, [userDetails, activeRole]);
 
 
  const handleRoleChange = (selectedOption) => {
    setIsRoleListVisible(false);
    dispatch(setActiveRole({role: selectedOption}));

    if(Object.keys(ROLES)){
      Object.keys(ROLES).forEach(element => {
        if(ROLES[element].name === selectedOption.name){
          navigate(ROLES[element].path);
        }
      });
    }
  };

  const RoleSelector = ( ) => {  
    if(userDetails && userDetails.roles && userDetails.roles.length > 1){
      return <Grid container className='w-100'>
          <Grid container justifyContent='space-between' style={{ width:'100%'}}> 
            <Grid item>
              <Typography style={{fontSize: '0.8rem'} }>{activeRole ? activeRole.display_name : ""}  </Typography>  
            </Grid>
            <Grid item>
              { isRoleListVisible 
                ? <Iconify icon="eva:arrow-ios-upward-outline" />
                : <Iconify icon="eva-arrow-ios-downward-outline" />
              } 
            </Grid>
          </Grid>          
        </Grid>  
    } 
    return  <Typography style={{fontSize: '0.8rem'} }>{activeRole && activeRole.display_name}</Typography> 
  } 
  
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <img src='/static/logo.png' alt="Bytes" width={100} />  
      </Box>

      {/* <Box sx={{ mb: 5, mx: 2.5, cursor:'pointer' }} onClick={() =>{
          setIsRoleListVisible(!isRoleListVisible);
        }} >
          <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
            Viewing as 
          </Typography>

          <AccountStyle>
             
            <Grid sx={{ mt: 1, width:'100%' }} justifyContent='space-between' >

              <RoleSelector />
              
            </Grid>
          </AccountStyle> 
        <ul key="favorites-list" className={ isRoleListVisible ? `role-list-container` : 'role-list-container hidden' } >
          {
            userDetails && userDetails.roles && userDetails.roles.map((role , idx) => 
              {
                return ROLES[role.name] !== undefined 
                ? <li className='role-list-item' key={`favorite-${idx}`}>
                  <Grid
                    // href='#'
                    key={`favorite-a-${idx}`}
                    // tabIndex={idx}
                    // role="button"
                    container
                    hover='true'
                    direction='row'
                    justifyContent='space-between'
                    onClick={() => handleRoleChange(role)}
                    style={{fontSize: '0.8rem', textDecoration: 'none',  cursor:'pointer', padding:16}}
                  >
                    <Grid item>
                      {role.display_name}
                    </Grid>

                    <Grid item>
                      { (role.name === activeRole.name ) 
                        ? <Typography style={{fontSize: '0.8rem', textDecoration:'underline' }}>
                            <Iconify icon="fa:check-circle-o" style={{color: 'green'}} />
                          </Typography> 
                        : <Typography style={{fontSize: '0.8rem', textDecoration:'underline' }}>Select</Typography> 
                      }
                    </Grid>
                  </Grid>
              </li>: null })
          }
        </ul>
      </Box>
       */}
      <NavSection />

      <Box sx={{ flexGrow: 1 }} />

      {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <Box
            component="img"
            src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>
          </Box>

        </Stack>
      </Box> */}
    </Scrollbar>
  );


  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          className="glass-box"
          PaperProps={{
            sx: { width: DRAWER_WIDTH 
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open 
          variant="persistent"
          PaperProps={{ 
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: '#f9fafbb8',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
