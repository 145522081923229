import { useSelector, useDispatch } from 'react-redux';  
import { Form, FormikProvider, useFormik } from 'formik';
import { useState,useEffect, useCallback  } from 'react';
import * as Yup from 'yup';
import { useCookies } from 'react-cookie';
import { compose, withProps, withStateHandlers } from "recompose";

import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
    getZipCode,
} from "use-places-autocomplete";

import useOnclickOutside from "react-cool-onclickoutside";

// import {  withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

// material
import { Grid, Container, Button, Stack, Link, SwipeableDrawer, Typography, TextField, Box, InputAdornment, DialogContent, Dialog } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components 
import { getActiveClient } from '../../../store/coach/reducer'; 
import { addClientAddress } from '../../../data/network/client/api';
import AddressMapContainer from './AddressMapContainer';

import '../../../assets/css/OnboardingForm.css';
import { ADD_ADDRESS_FORM } from '../../../constants/message_constants';
import { setSnackbarMessage, setSnackbarVisibilityStatus } from '../../../store/layout/reducer';
import { SNACKBAR_SEVERITY } from '../../../constants/app_constants';

export default function AddAddressForm( {type, handleClose} ) {
    const theme = useTheme();
    const clientDetails = useSelector(state => getActiveClient(state));
    
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const dispatch = useDispatch(); 

    const [isMapDrawerOpen, setIsMapDrawerOpen] = useState(false);
    
    const [startDateValue, setStartDateValue] =  useState(
        new Date() 
    );

    const [endDateValue, setEndDateValue] =  useState(
        new Date() 
    );

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        cache: 24 * 60 * 60,
        requestOptions: {
        
          /* Define search scope here */
          componentRestrictions: {country: ['in']},
        //   types: ['administrative_area_level_1','administrative_area_level_2']
        },
        debounce: 300,
    });

    const ref = useOnclickOutside(() => {
        // When user clicks outside of the component, we can dismiss
        // the searched suggestions by calling this method
        clearSuggestions();
    });
    
    const handleInput = (e) => {
        // Update the keyword of the input element
        setValue(e.target.value);
        formik.setFieldValue('landmark', e.target.value);
    };
    
    const handleSelect = ({ description, terms }) => () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);
        formik.setFieldValue('landmark',description);
        clearSuggestions();
        
        try{
            terms.reverse();
            console.log(terms);
            const state = terms[1].value;
            const city = terms[2].value;

            console.log(state);
            console.log(city);

            formik.setFieldValue('city', city);
            formik.setFieldValue('state', state);

        }catch(error){
            console.log("Error fetching city and state");
        }
    
        // Get latitude and longitude via utility functions
        getGeocode({ address: description })
        .then((results) => {
            const { lat, lng } = getLatLng(results[0]);
            console.log("📍 Coordinates: ", { lat, lng });
            formik.setFieldValue('latitude', lat);
            formik.setFieldValue('longitude', lng);

            const zipCode = getZipCode(results[0], false);
            console.log("ZIP Code: ", zipCode);
            formik.setFieldValue('pincode', zipCode);
  
            setIsMapDrawerOpen(true);
        })
        .catch((error) => {
            console.log("😱 Error: ", error);
        });
    };

    const renderSuggestions = () =>
        data.map((suggestion) => {
        //   const {
        //     place_id,
        //     structured_formatting: { main_text, secondary_text },
        //   } = suggestion;
          const placeId = suggestion.place_id;
          const description = suggestion.description;
          const terms = suggestion.terms;

          return (
            <li role='presentation' key={placeId} onClick={handleSelect(suggestion, terms)} onKeyDown={handleSelect(suggestion, terms)}>
              <small>{description}</small>
            </li>
          );
    });
    

    const handleAddAddress = () => {

        if(formik.values.landmark !== "" && formik.values.address1 !== '' && formik.values.mobile_no !== ''){      
            addClientAddress(dispatch, cookies, {
                'user_id' : clientDetails.user_id,
                'address_nickname': formik.values.address_nickname,
                'address1': formik.values.address1,
                'address2': formik.values.address2,
                'landmark': formik.values.landmark , 
                'city': formik.values.city ,
                'state': formik.values.state, 
                'pincode': formik.values.pincode ,
                'latitude': formik.values.latitude , 
                'longitude': formik.values.longitude,
                'mobile_no': formik.values.mobile_no
            }, type);
           handleClose();
        }else{
            console.log("show error");
            // handleClose();
            dispatch(setSnackbarMessage({'message': ADD_ADDRESS_FORM.INCOMPLETE_DETAILS , 'severity': SNACKBAR_SEVERITY.ERROR}));
            dispatch(setSnackbarVisibilityStatus({'status': true }));
        }
    };

    const AddressSchema = Yup.object().shape({
        address_nickname: Yup.string().required('Address Nickname is required')  ,
        address1: Yup.string().required('House no is required')  ,
        address2: Yup.string()  ,
        landmark:Yup.string().required('Landmark is required') ,
        city: Yup.string().required('City is required')   , 
        state: Yup.string().required('State is required')  ,
        pincode: Yup.string()  , 
        rest_duration: Yup.string()  , 
        latitude:Yup.string()   ,
        longitude:Yup.string()   ,
        mobile_no:Yup.string().required('Mobile number is required')    ,
    });

    const formik = useFormik({
        initialValues: {
            address1: '',
            address_nickname: 'Home',
            address2: '' , 
            landmark: '' ,
            city: 'Bengaluru', 
            state: 'Karnataka' ,
            pincode: '' , 
            latitude: '',
            longitude: '',
            mobile_no: clientDetails.mobile_no

      },
        validationSchema: AddressSchema,
        onSubmit: () => {
  
        },
    });

    const toggleMapModal = (anchor , open ) => (event ) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event ).key === 'Tab' ||
            (event ).key === 'Shift')
        ) {
            return;
        }
    
        setIsMapDrawerOpen(open); 
    };

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyCmEdnEHu5x5rkLK6pQpgba8ZFj5ei7BOg"
    })
    
    const containerStyle = {
        width: '100%',
        height: '500px'
    };

    const center = {
        lat: -3.745,
        lng: -38.523
    };

    const [map, setMap] = useState(null)

    const onLoad = useCallback((map) =>  {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = useCallback((map) => {
        setMap(null)
    }, [])

    const onMarkerDragEnd = (e, i) => {
        console.log(e);
        const {latLng} = e;
        const lat = latLng.lat();
        const lng = latLng.lng();
        console.log(lat)
        console.log(lng)
    }

    return (
            (!isMapDrawerOpen)
                ? <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

                        <Grid  sx={{padding:2, borderRadius: 2}}> 
                            <Grid container direction="column"  > 
                                <Grid item container direction="row" justifyContent="flex-start">
                                    <Typography variant="h6" gutterBottom>
                                        Add new address
                                    </Typography>
                                </Grid> 
                                <Grid item container direction="column" justifyContent="center">

                                    <Box margin={2} />

                                    <div ref={ref}>

                                        <TextField
                                            fullWidth
                                            required
                                            // disabled={!ready}
                                            label="Enter landmark"
                                            {...getFieldProps('landmark')}
                                            error={Boolean(touched.landmark && errors.landmark)}
                                            helperText={touched.landmark && errors.landmark}
                                            onChange={handleInput}
                                        />

                                        {/* We can use the "status" to decide whether we should display the dropdown or not */}
                                        {status === "OK" && <ul className="autocomplete-suggestion">{renderSuggestions()}</ul>}
                                    </div>

                                    
                                    <Box margin={2} />
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <TextField
                                            required
                                            fullWidth 
                                            label="House No and Building Name"
                                            {...getFieldProps('address1')}
                                            error={Boolean(touched.address1 && errors.address1)}
                                            helperText={touched.address1 && errors.address1}
                                        />
                                    </Stack>
                                    <Box margin={2} />
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <TextField
                                            fullWidth
                                            label="Address Line 2"
                                            {...getFieldProps('address2')}
                                            error={Boolean(touched.address2 && errors.address2)}
                                            helperText={touched.address2 && errors.address2}
                                        />
                                        
                                    </Stack>
                                    <Box margin={2} />
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        
                                        <TextField
                                            fullWidth
                                            label="City"
                                            {...getFieldProps('city')}
                                            error={Boolean(touched.city && errors.city)}
                                            helperText={touched.city && errors.city}
                                        />
                                        <TextField
                                            fullWidth
                                            label="State"
                                            {...getFieldProps('state')}
                                            error={Boolean(touched.state && errors.state)}
                                            helperText={touched.state && errors.state}
                                        />

                                        <TextField
                                            fullWidth
                                            type="number"
                                            label="Pincode"
                                            {...getFieldProps('pincode')}
                                            error={Boolean(touched.pincode && errors.pincode)}
                                            helperText={touched.pincode && errors.pincode}
                                        />
                                    </Stack> 
                                     
                                    <Box margin={2} />
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <TextField
                                            type="number"
                                            fullWidth
                                            label="Mobile Number"
                                            {...getFieldProps('mobile_no')}
                                            error={Boolean(touched.mobile_no && errors.mobile_no)}
                                            helperText={touched.mobile_no && errors.mobile_no} 
                                        />
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Address Nickname"
                                            {...getFieldProps('address_nickname')}
                                            error={Boolean(touched.address_nickname && errors.address_nickname)}
                                            helperText={touched.address_nickname && errors.address_nickname} 
                                        />
                                    </Stack>
                                    <Box margin={2} />

                                    <Grid item container direction="row" justifyContent="space-around">

                                        <Button variant='outlined' onClick={() => {
                                            handleClose();
                                        }}>
                                            Close
                                        </Button>  

                                        <Button type="submit"
                                            className="btn btn-default"
                                            disabled={isSubmitting} 
                                            variant='contained' onClick={() => {
                                              handleAddAddress();
                                            }}
                                        >
                                            Create
                                        </Button>                            
                                    </Grid>
                                </Grid>
                            </Grid>

                            <SwipeableDrawer
                                anchor={'bottom'}
                                open={isMapDrawerOpen}
                                onClose={toggleMapModal( 'bottom', false)}
                                onOpen={toggleMapModal( 'bottom', true)}
                                sx={{zIndex: 1001}}
                                style={{zIndex: 10011}}
                            >
                                <AddressMapContainer handleClose={toggleMapModal('bottom', false)} />
                            </SwipeableDrawer>
                        </Grid>
                    </Form>
                </FormikProvider>  
                : <Grid>
                    <Grid container direction="row" padding={2} justifyContent="flex-end">
                        <Button variant="contained" onClick={() => {
                            setIsMapDrawerOpen(false);
                        }}>Confirm Location</Button>
                    </Grid>
                    {/* <MyMapComponent key="map"/> */}
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        // center={center}
                        center={{ lat: formik.values.latitude, lng: formik.values.longitude }}
                        zoom={8}                       
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        onClick={(coord) => {

                            const {latLng} = coord;
                            const lat = latLng.lat();
                            const lng = latLng.lng();

                            formik.setFieldValue('latitude', lat);
                            formik.setFieldValue('longitude', lng);

                        }}
                        onDragEnd={() => {
                            // console.log(coord);
                        }}
                    >
                        <Marker
                            position={{ lat: formik.values.latitude, lng: formik.values.longitude }} 
                            draggable
                            onDragend={(t, map, coord) => onMarkerDragEnd(coord, map)}
                        />
                    </GoogleMap>
                </Grid>

    );
}
