import { createSlice } from '@reduxjs/toolkit';

const partnerStoreSlice = createSlice({
    name: 'partner_store',
    initialState: {
      order: null,
      stock: null,
      vendor: null,
      expense: null,
      staff: null,
      activeStore : null,
      roles: null,
      stockStats: null,
      vendorStats : null,
      expenseStats : null,
      orderStats : null,
      menu:null,
      dishList:null,
      salesStats: null,
      salesList: null,
      invoiceList: null,
      invoiceStats: null,
    },
    reducers: {
        setStoreOrder(state, action) {
          state.order = action.payload.data;
        },
        setStoreStaff(state, action) {
          state.staff = action.payload.data;
        },
        setStoreStock(state, action) {
          state.stock = action.payload.data;
        },
        setStoreVendor(state, action) {
          state.vendor = action.payload.data;
        },
        setStoreExpense(state, action) {
          state.expense = action.payload.data;
        },
        setActiveStore(state, action) {
          state.activeStore = action.payload.data;
        },
        setStoreRoles(state, action) {
          state.roles = action.payload.data;
        },
        setStoreOrderStats(state, action) {
          state.orderStats = action.payload.data;
        },
        setStoreStockStats(state, action) {
          state.stockStats = action.payload.data;
        },
        setStoreVendorStats(state, action) {
          state.vendorStats = action.payload.data;
        },
        setStoreExpenseStats(state, action) {
          state.expenseStats = action.payload.data;
        },
        setStoreMenu(state, action) {
          state.menu = action.payload.data;
        },
        setStoreDishList(state, action) {
          state.dishList = action.payload.data;
        },
        setStoreMenuStats(state, action) {
          state.menuStats = action.payload.data;
        },
        setStoreSalesStats(state, action) {
          state.salesStats = action.payload.data;
        },
        setStoreSalesList(state, action) {
          state.salesList = action.payload.data;
        },
        setStoreInvoiceStats(state, action) {
          state.invoiceStats = action.payload.data;
        },
        setStoreInvoiceList(state, action) {
          state.invoiceList = action.payload.data;
        },

    }
})

export const getActiveStore = (state) => {
  if(state.partner_store != null && state.partner_store !== undefined){
    return state.partner_store.activeStore;
  }
  return null;
}

export const getActiveStoreId = (state) => {
  if(state.partner_store != null && state.partner_store !== undefined && state.partner_store.activeStore !== null){
    return state.partner_store.activeStore.id;
  }
  return null;
}

export const getStoreOrder = (state) => {
    if(state.partner_store != null && state.partner_store !== undefined){
      return state.partner_store.order;
    }
    return null;
}

export const getStoreStaff = (state) => {
  if(state.partner_store != null && state.partner_store !== undefined){
    return state.partner_store.staff;
  }
  return null;
}

export const getStoreStock = (state) => {
  if(state.partner_store != null && state.partner_store !== undefined){
    return state.partner_store.stock;
  }
  return null;
}


export const getStoreVendor = (state) => {
  if(state.partner_store != null && state.partner_store !== undefined){
    return state.partner_store.vendor;
  }
  return null;
}

export const getStoreExpense = (state) => {
  if(state.partner_store != null && state.partner_store !== undefined){
    return state.partner_store.expense;
  }
  return null;
}

export const getStoreRoles = (state) => {
  if(state.partner_store != null && state.partner_store !== undefined){
    return state.partner_store.roles;
  }
  return null;
}

export const getStoreSales = (state) => {
  if(state.partner_store != null && state.partner_store !== undefined){
    return state.partner_store.salesList;
  }
  return null;
}

export const getStoreInvoice = (state) => {
  if(state.partner_store != null && state.partner_store !== undefined){
    return state.partner_store.invoiceList;
  }
  return null;
}

export const getStoreOrderStats = (state) => {
  if(state.partner_store != null && state.partner_store !== undefined){
    return state.partner_store.orderStats;
  }
  return null;
}


export const getStoreStockStats = (state) => {
  if(state.partner_store != null && state.partner_store !== undefined){
    return state.partner_store.stockStats;
  }
  return null;
}


export const getStoreVendorStats = (state) => {
  if(state.partner_store != null && state.partner_store !== undefined){
    return state.partner_store.vendorStats;
  }
  return null;
}


export const getStoreExpenseStats = (state) => {
  if(state.partner_store != null && state.partner_store !== undefined){
    return state.partner_store.expenseStats;
  }
  return null;
}

export const getStoreSalesStats = (state) => {
  if(state.partner_store != null && state.partner_store !== undefined){
    return state.partner_store.salesStats;
  }
  return null;
}

export const getStoreInvoiceStats = (state) => {
  if(state.partner_store != null && state.partner_store !== undefined){
    return state.partner_store.invoiceStats;
  }
  return null;
}

export const getStoreMenu = (state) => {
  if(state.partner_store != null && state.partner_store !== undefined){
    return state.partner_store.menu;
  }
  return null;
}

export const getStoreMenuStats = (state) => {
  if(state.partner_store != null && state.partner_store !== undefined){
    return state.partner_store.menuStats;
  }
  return null;
}

export const getStoreDishList = (state) => {
  if(state.partner_store != null && state.partner_store !== undefined){
    return state.partner_store.dishList;
  }
  return null;
}
export const { setStoreOrder, setStoreStock, setStoreVendor, setStoreExpense, setStoreStaff, setStoreDishList,
  setActiveStore, setStoreRoles, setStoreOrderStats, setStoreVendorStats, setStoreStockStats, setStoreExpenseStats, 
  setStoreMenu, setStoreMenuStats, setStoreSalesList, setStoreSalesStats, setStoreInvoiceList, setStoreInvoiceStats } = partnerStoreSlice.actions
  
export default partnerStoreSlice.reducer