import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../../store/layout/reducer';
import axios from '../../../utils/axios-configure';
import { getAccessTokenFromCookie } from "../session/api";
import { SNACKBAR_SEVERITY } from '../../../constants/app_constants';
import { setStoreStaff } from '../../../store/partner_store/reducer';
import { STORE_STAFF_DELETE, STORE_STAFF_FETCH, STORE_STAFF_UPDATE } from '../../../constants/message_constants';
import { API_DELETE_STORE_STAFF, API_GET_STORE_STAFF, API_UPDATE_STORE_STAFF } from '../endpoints';

export const fetchStoreStaff = (dispatch, cookies, storeId, page = 1, count=10, filterBy = { key: '', value: '' }) => {
    dispatch(setLoadingStatus({ status: true }));
    const accessToken = getAccessTokenFromCookie(dispatch, cookies);
    if (accessToken != null) {

      const url = `${API_GET_STORE_STAFF}?store_id=${storeId}&page=${page}&count=${count}&filter=${filterBy?.key}&term=${filterBy?.value}`
  
      axios({
        method: "get",
        url,
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
      })
        .then((response) => {
          // handle success
          if (response.status === 200) {
            dispatch(setStoreStaff({ data: response.data }));
          } else {
            //
          }
          dispatch(setLoadingStatus({ status: false }));
          return true;
        })
        .catch((err) => {
          dispatch(setLoadingStatus({ status: false }));
        });
    } else {
      dispatch(setLoadingStatus({ status: false }));
      //
    }
  }
  
  export const updateStoreStaff = (dispatch, cookies, storeId, data) => {
    const accessToken = getAccessTokenFromCookie(dispatch, cookies);
    dispatch(setLoadingStatus({ status: true }));
    if (accessToken != null) {

      data.store_id = storeId;

      axios({
        method: "post",
        url: `${API_UPDATE_STORE_STAFF}`,
        data,
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
      })
        .then((response) => {
          // handle success
          if (response.status === 200) {
  
            dispatch(setSnackbarMessage({ 'message': STORE_STAFF_UPDATE.SUCCESS, 'severity': SNACKBAR_SEVERITY.SUCCESS }));
            dispatch(setSnackbarVisibilityStatus({ 'status': true }));
            fetchStoreStaff(dispatch, cookies, storeId);
          } else {
            dispatch(setSnackbarMessage({ 'message': STORE_STAFF_UPDATE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
            dispatch(setSnackbarVisibilityStatus({ 'status': true }));
          }
          dispatch(setLoadingStatus({ status: false }));
          return true;
        })
        .catch((err) => {
          dispatch(setLoadingStatus({ status: false }));
          dispatch(setSnackbarMessage({ 'message': STORE_STAFF_UPDATE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
          dispatch(setSnackbarVisibilityStatus({ 'status': true }));
        });
    }
    dispatch(setLoadingStatus({ status: false }));
  }
  

  
  export const deleteStoreStaff = (dispatch, cookies, storeId, data) => {
    const accessToken = getAccessTokenFromCookie(dispatch, cookies);
    dispatch(setLoadingStatus({ status: true }));
    if (accessToken != null) {

      data.store_id = storeId;

      axios({
        method: "delete",
        url: `${API_DELETE_STORE_STAFF}`,
        data,
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
      })
        .then((response) => {
          // handle success
          if (response.status === 200) {
  
            dispatch(setSnackbarMessage({ 'message': STORE_STAFF_DELETE.SUCCESS, 'severity': SNACKBAR_SEVERITY.SUCCESS }));
            dispatch(setSnackbarVisibilityStatus({ 'status': true }));
            fetchStoreStaff(dispatch, cookies, storeId);
          } else {
            dispatch(setSnackbarMessage({ 'message': STORE_STAFF_DELETE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
            dispatch(setSnackbarVisibilityStatus({ 'status': true }));
          }
          dispatch(setLoadingStatus({ status: false }));
          return true;
        })
        .catch((err) => {
          dispatch(setLoadingStatus({ status: false }));
          dispatch(setSnackbarMessage({ 'message': STORE_STAFF_DELETE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
          dispatch(setSnackbarVisibilityStatus({ 'status': true }));
        });
    }
    dispatch(setLoadingStatus({ status: false }));
  }
  
  