import React from 'react';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { Box, Typography, Tab, Tabs } from '@mui/material';
import { fNumber } from '../../utils/formatNumber';
import { BaseOptionChart } from '../../components/chart';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CorporateReportBar = (props) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {props?.graph_data && props?.graph_data.map((ele, index) => <Tab key={ele?.title} label={ele?.title} {...a11yProps(index)} />)}
                </Tabs>
            </Box>
            {props?.graph_data?.length > 0 && props?.graph_data.map((ele, index) => {
                const chartLabels = ele.data?.map((i) => i.title);
                const chartSeries = ele.data.map((i) => i.value);
                const chartOptions = merge(BaseOptionChart(), {
                    tooltip: {
                        marker: { show: true },
                        y: {
                            formatter: (seriesName) => fNumber(seriesName),
                            title: {
                                formatter: () => '',
                            },
                        },
                    },
                    plotOptions: {
                        bar: { horizontal: false, barHeight: '28%', borderRadius: 2 },
                    },
                    chart: {
                        background: '#fff',
                    },
                    xaxis: {
                        categories: chartLabels,
                    },
                });
                return (
                    <CustomTabPanel value={value} index={index} key={ele?.title}>
                        <ReactApexChart type="bar" series={[{ data: chartSeries }]} options={chartOptions} height={364} />
                    </CustomTabPanel>
                )
            })}
        </>
    );
}

export default React.memo(CorporateReportBar);