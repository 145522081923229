import { useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
// @mui
 import { Card, CardHeader, Table, TableBody, Paper, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';

 import { getActiveClientReport } from '../../../store/coach/reducer';
 // ----------------------------------------------------------------------
 
 export default function UpcomingActivityList() {
 
    const clientReport = useSelector(state => getActiveClientReport(state));

    const [data, setData] = useState([]);

    useEffect(() => {
        const _data = [];
        if(clientReport && clientReport !== null && clientReport.upcoming_activities !== null){
            clientReport.upcoming_activities.forEach((element, i) => {
                console.log(element);
                if(i<5){
                    _data.push(element);
                }
            })
        }
        setData(_data);
    },[clientReport])
 
 
     return (
         <Card className="glass-box " style={{minHeight:'500px'}}>
             <CardHeader title="Upcoming Activities" />
 
             <TableContainer >
                 <Table aria-label="upcoming-activities">
                     <TableHead>
                         <TableRow>
                             <TableCell> </TableCell> 
                             <TableCell align="right">Activity</TableCell>
                         </TableRow>
                     </TableHead>
                     <TableBody>
                         {data.map((row, i) => (
                            <TableRow
                                key={row.schedule_id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.start}
                                </TableCell>
                                <TableCell align="right">{row.title}</TableCell> 
                            </TableRow>
                         ))}
                     </TableBody>
                 </Table>
             </TableContainer>
         </Card>
     );
 }
 