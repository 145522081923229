import { useSelector, useDispatch } from 'react-redux';  
import { Form, FormikProvider, useFormik } from 'formik';
import { useState  } from 'react';
import * as Yup from 'yup';
import { useCookies } from 'react-cookie';

// material
import { Grid, Button, Stack, Typography, TextField, Box, InputAdornment, DialogContent, Dialog, MenuItem, OutlinedInput, FormControl, InputLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components 
import { getActiveClient } from '../../../../../store/coach/reducer'; 
import { addActivity } from '../../../../../data/network/activity/api'; 
import { getActivityCategoryList, resetAddActivityStatus } from '../../../../../store/activity/reducer';

export default function CreateActivityForm( {handleClose} ) {
    const theme = useTheme();
    const clientDetails = useSelector(state => getActiveClient(state));
 
    const activityCategoryList = useSelector(state => getActivityCategoryList(state));

    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const dispatch = useDispatch(); 
    
    const [startDateValue, setStartDateValue] =  useState(
        new Date() 
    );

    const [endDateValue, setEndDateValue] =  useState(
        new Date() 
    );

    const activityType = [
        {
            "display_name" : "Public",
            "value" : "public"
        },
        {
            "display_name" : "Private",
            "value" : "private"
        }
    ];

    const handleCreateActivity = () => {
        addActivity(dispatch, cookies, {
            'unique_name': formik.values.title.replace(' ','_').toLowerCase(),
            'display_name': formik.values.title,
            'description': formik.values.description , 
            'category': formik.values.category , 
            'instructions': formik.values.instruction ,
            'video_link': formik.values.video_link, 
            'standard_duration': formik.values.activity_duration ,
            'standard_burned_calories': formik.values.calories_burnt , 
            'standard_rest_duration': formik.values.rest_duration,
            'type' : formik.values.type
        });
        dispatch(resetAddActivityStatus());
        handleClose();
//        handleClose();
    };

    const ActivitySchema = Yup.object().shape({
        title: Yup.string().required('Title is required')  ,
        description: Yup.string().required('Description is required')  ,
        category: Yup.string().required('Category is required')  ,
        instruction:Yup.string()   ,
        video_link: Yup.string()   , 
        activity_duration: Yup.string().required('Activity Duration is required')  ,
        calories_burnt: Yup.string().required('Calories Burnt is required')  , 
        rest_duration: Yup.string().required('Rest Duration is required')  , 
        type: Yup.string().required('Type is required')  , 
    });

    const formik = useFormik({
        initialValues: {
          title: '',
          description: '' , 
          category: '',
          instruction: '' ,
          video_link: '', 
          activity_duration: '' ,
          calories_burnt: '' , 
          rest_duration: '',
          type: 'public'
      },
        validationSchema: ActivitySchema,
        onSubmit: () => {
  
        },
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid  sx={{padding:2, borderRadius: 2}}>
                    <Grid container direction="column">
                        {/* <Grid item container direction="row" justifyContent="flex-start">
                            <Typography variant="h6" gutterBottom>
                                Add new activity
                            </Typography>
                        </Grid> */}
                        <Grid item container direction="column" justifyContent="center">
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                <TextField
                                    fullWidth 
                                    label="Title"
                                    {...getFieldProps('title')}
                                    error={Boolean(touched.title && errors.title)}
                                    helperText={touched.title && errors.title}
                                />
                            </Stack>
                            <Box margin={1} />
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                <TextField
                                    fullWidth
                                    label="Description"
                                    {...getFieldProps('description')}
                                    error={Boolean(touched.description && errors.description)}
                                    helperText={touched.description && errors.description}
                                />
                            </Stack>
                            <Box margin={1} />
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                <TextField
                                    select
                                    fullWidth
                                    label="Category"
                                    {...getFieldProps('category')}
                                    error={Boolean(touched.category && errors.category)}
                                    helperText={touched.category && errors.category}
                                >
                                    {activityCategoryList.map((option) => (
                                        <MenuItem key={option.display_name} value={option.display_name}>
                                            {option.display_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                
                            </Stack>
                            <Box margin={1} />
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                
                                <TextField
                                    fullWidth
                                    label="Instructions"
                                    {...getFieldProps('instruction')}
                                    error={Boolean(touched.instruction && errors.instruction)}
                                    helperText={touched.instruction && errors.instruction}
                                />
                            </Stack>
                            <Box margin={1} />
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                <TextField
                                    fullWidth
                                    label="Video Link"
                                    {...getFieldProps('video_link')}
                                    error={Boolean(touched.video_link && errors.video_link)}
                                    helperText={touched.video_link && errors.video_link}
                                />
                            </Stack>
                            <Box margin={1} />
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="activity_duration" variant="outlined">
                                        Activity Duration
                                    </InputLabel>
                                    <OutlinedInput
                                        type="number"
                                        fullWidth
                                        // label="Activity Duration"
                                        {...getFieldProps('activity_duration')}
                                        error={Boolean(touched.activity_duration && errors.activity_duration)}
                                        helperText={touched.activity_duration && errors.activity_duration}
                                        endAdornment={<InputAdornment position="end">mins</InputAdornment>}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="rest_duration" variant="outlined">
                                        Rest Duration
                                    </InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        type="number"
                                        // label="Rest Duration"
                                        {...getFieldProps('rest_duration')}
                                        error={Boolean(touched.rest_duration && errors.rest_duration)}
                                        helperText={touched.rest_duration && errors.rest_duration}
                                        endAdornment={<InputAdornment position="end">mins</InputAdornment>}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="calories_burnt" variant="outlined">
                                        Calories
                                    </InputLabel>
                                    <OutlinedInput
                                        type="number"
                                        fullWidth
                                        // label="Calorie Burnt"
                                        {...getFieldProps('calories_burnt')}
                                        error={Boolean(touched.calories_burnt && errors.calories_burnt)}
                                        helperText={touched.calories_burnt && errors.calories_burnt}
                                        endAdornment={<InputAdornment position="end">Kcal</InputAdornment>}
                                    />
                                </FormControl>
                            </Stack>
                            <Box margin={1} />
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                <TextField
                                    select
                                    fullWidth
                                    defaultValue="public"
                                    label="Type"
                                    {...getFieldProps('type')}
                                    error={Boolean(touched.type && errors.type)}
                                    helperText={touched.type && errors.type}
                                >
                                    {activityType.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.display_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Stack>
                            <Box margin={1} />
                            <Grid item container direction="row" justifyContent="center">
                                <Button variant='contained' onClick={() => {
                                    handleCreateActivity();
                                }}>
                                    Create
                                </Button>                            
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Form>
        </FormikProvider>  
    );
}
