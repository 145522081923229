import { Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Form, FormikProvider, useFormik } from 'formik';
import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useCookies } from 'react-cookie';

// material
import { Grid, Button, Container, Stack, Typography, Card, IconButton, Avatar, TextField, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import Iconify from '../../../../../components/Iconify';
import { getActiveClient } from '../../../../../store/coach/reducer';
import { formatDate, getAge } from '../../../../../utils/date';
// mocks_ 
// ----------------------------------------------------------------------

export default function ActivityInfo({activity, drawerHandler}) {
    const theme = useTheme();
    const clientDetails = useSelector(state => getActiveClient(state));

    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const dispatch = useDispatch(); 

    console.log(activity);
  
    return (  
         
                <Grid  sx={{padding:2, borderRadius: 2}}>
                    {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h6" gutterBottom>
                            User Details
                        </Typography>
                    </Stack> */}
                    <Grid container direction="column"  > 
                         
                        <Grid item container direction="row" justifyContent="flex-start">
                            <Typography variant="h6" gutterBottom>
                                {activity.title}
                            </Typography>
                        </Grid>
                        <Box margin={2} />
{
                        (activity.video_link)
                        ? <Grid item container direction="row" justifyContent="center">
                             
                                <iframe
                                    src={activity.video_link}
                                    frameBorder="0"
                                    allow="autoplay; encrypted-media"
                                    allowFullScreen
                                    title="video"
                                    width='500'
                                    height='300'
                                />
                                
                            
                        </Grid>
                        : null
}                         
                        <Box margin={2} />
                        <Grid item container direction="row" justifyContent="flex-start">
                            <Typography variant="body1" gutterBottom>
                                {activity.description}
                            </Typography>
                        </Grid>
                        <Box margin={2} />
                        <Grid item container direction="row" justifyContent="flex-start">
                            <Typography variant="body1" gutterBottom>
                                {activity.instructions}
                            </Typography>
                        </Grid>
                        <Box margin={2} />
                        <Grid item container direction="row" justifyContent="space-around">
                            <Typography variant="h7" gutterBottom>
                                Activity Duration : <b>{activity.standard_duration} mins</b>
                            </Typography>

                            <Typography variant="h7" gutterBottom>
                                Rest Duration : <b>{activity.standard_rest_duration} mins</b>
                            </Typography>

                            <Typography variant="h7" gutterBottom>
                                Calories Burnt : <b>{activity.standard_burned_calories} Kcal</b>
                            </Typography>
                        </Grid>

                        <Grid item container direction="row" justifyContent="center">
                            <Button variant='contained' onClick={() => {
                                // handleCreateJournal();
                                // handleClose();
                                drawerHandler();
                            }}>
                                Close
                            </Button>
                            
                        </Grid>
                        
                        
                    </Grid>
                </Grid> 
    );
}
