import React, {useState, useEffect, useRef} from 'react';
import { useTheme,Grid, Container, Typography,Box, IconButton, TableCell, FormControl, InputLabel, Select, OutlinedInput, Chip, MenuItem, Card, SwipeableDrawer, Stack, Dialog, DialogContent, AppBar, Toolbar, styled } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";

import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import Page from "../../components/Page";

import  '../../assets/css/ScheduleMeal.css';
import { fetchClientSubscription, fetchClientDetails, scheduleUserActivity, fetchMealPlanDishOptions, fetchMealPlanSchedule, addMealPlanSchedule, updateMealPlanSchedule } from '../../data/network/client/api';
import { getActiveClient, getClientActivitySchedule, getClientList, getClientMealOptions, getClientMealSchedule, getClientSubscriptionPlanDetails } from '../../store/coach/reducer';
import {ReactComponent as Loader} from '../../assets/img/loader_3.svg';
import { formatDate } from '../../utils/date'; 
import UserListCard from '../../sections/coach/journal/UserListCard';
import ScheduleMealForm from '../../sections/coach/meal_calendar/ScheduleMealForm';
import EditMealForm from '../../sections/coach/meal_calendar/EditMealForm';
import { fetchPartnerDish, fetchPartnerMeals } from '../../data/network/dish/api';
import Iconify from '../../components/Iconify';


export default function ScheduleMeal() {

  const StackStyled = styled(Stack)(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        '& .fc': {
            width: '100%',
        },
        '& .fc .fc-toolbar-title': {
            fontSize: '16px',
        }
    },
}))

const BoxStyled = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '25%',
    [theme.breakpoints.down('md')]: {
       width: '100%',
       marginLeft: '0px !important',
    },
    '& .event': {
        alignSelf: 'end',
        background: '#1a252f',
        [theme.breakpoints.down('md')]: {
            marginTop: '20px',
         },
    },
    '& .MuiCard-root': {
        padding: '15px',
        borderLeft: '6px solid #FDAA2E',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        marginTop: '30px',
        '&.breakfast': {
            borderLeft: '6px solid #FDAA2E',
            '& .MuiDivider-root': {
                borderColor: '#FDAA2E',
            }
        },
        '&.lunch': {
            borderLeft: '6px solid #2AC3A7',
            '& .MuiDivider-root': {
                borderColor: '#2AC3A7',
            }
        },
        '&.dinner': {
            borderLeft: '6px solid #447CFF',
            '& .MuiDivider-root': {
                borderColor: '#447CFF',
            }
        },
        '&.allday, &.snacks': {
            borderLeft: '6px solid #5C5C5C',
            '& .MuiDivider-root': {
                borderColor: '#5C5C5C',
            }
        },
        '& .time': {
            display: 'flex',
            alignItems: 'center',
            gap: '1px',
            background: '#F5F6F8',
            padding: '5px',
            borderRadius: '10px',
        },
        '& .MuiTable-root th, .MuiTable-root td': {
            padding: '5px',
            fontSize: '12px',
            textAlign: 'left',
        },
        '& .menu': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& .MuiTypography-root': {
                fontSize: '12px',
                fontWeight: 'bold',
            },
            '& .MuiDivider-root': {
                flex: 1,
            }

        }
    }
}));

    const theme = useTheme();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);

    const calendarComponentRef = useRef();

    const [showOverviewDashboard, setShowOverviewDashboard] = useState(true);
    const [loading, setLoading] = useState(true);
    const [isScheduleDrawerOpen, setIsScheduleDrawerOpen] = useState(false);
    const [selectedMealDate, setSelectedMealDate] = useState(new Date()); 
    const [selectedMealId, setSelectedMealId] = useState(null);
    const [selectedMealType, setSelectedMealType] = useState(null);
    const [selectedMealPreference, setSelectedMealPreference] = useState(null);
    const [drawerType, setDrawerType] = useState("CREATE");

    const [range, setRange] = useState({
      'start_date' : null,
      'end_date' : null,
    });
  
    const activeClient = useSelector(state => getActiveClient(state));
    const mealSchedule = useSelector(state => getClientMealSchedule(state)); 
    const clientList = useSelector(state => getClientList(state));
    const mealOptions = useSelector(state => getClientMealOptions(state));

    const appointments = []; 
    const [data, setData] = useState(appointments);
    const [open, setOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null); 
    const [searchParams, setSearchParams] = useSearchParams();
    const _startDate = searchParams.get("start_date");
    const _endDate = searchParams.get("end_date");
    const userId = searchParams.get("client_id");

    // const [userId, setUserId] = useState(null);

    const headerToolbar = {
      left:'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay'
    }

    const mobileHeaderToolbar = {
      left: 'prev,next today',
      right: 'title'
    }

    useEffect(() => { 
      if(userId){ 
        setShowOverviewDashboard(false);
        setLoading(true);
        fetchClientDetails(dispatch, cookies, userId);
        fetchClientSubscription(dispatch, cookies, userId); 
        fetchMealPlanSchedule(dispatch, cookies, {'user_id': userId});
        fetchMealPlanDishOptions(dispatch, cookies, userId); 
        // fetchPartnerDish(dispatch, cookies);
        fetchPartnerMeals(dispatch, cookies); 
      }else{
        setShowOverviewDashboard(true);
      }
    },[userId]);

    // useEffect(() => { 
    //   const _userId = searchParams.get("client_id");
      
    //   if (_userId){
    //     setUserId(_userId);
    //   }
    // },[searchParams]);


    useEffect(() => { 
      
  
      if(userId !== null && userId !== undefined && userId !== ''){ 
        // setUserId(userId);

        setShowOverviewDashboard(false);
        setLoading(true);

        fetchClientDetails(dispatch, cookies, userId);
        fetchClientSubscription(dispatch, cookies, userId); 
        fetchMealPlanDishOptions(dispatch, cookies, userId); 
        fetchMealPlanSchedule(dispatch, cookies, {'user_id': userId});
        // fetchPartnerDish(dispatch, cookies);
        fetchPartnerMeals(dispatch, cookies); 
      }else{
        setShowOverviewDashboard(true);
      }
    },[]);

    useEffect(() => { 
      if(activeClient != null && mealOptions != null ){
        setLoading(false);
      }
    },[activeClient, mealOptions]); 

    useEffect(() => { 
      if(mealSchedule != null){ 
        mealSchedule.forEach(element => { 
          appointments.push({
            id: element.id,
            extendedProps: {
              ...element
            },
            title: element.meal.display_name,
            date: new Date(element.date),
          })
        });
        setData(appointments);
      } 
    },[mealSchedule]);  
 
    useEffect(() =>{ 
        if(range.start_date != null && range.end_date != null){ 
          fetchMealPlanSchedule(dispatch, cookies, {
              user_id: userId,
              "start_date" : formatDate(range.start_date, "yyyy-MM-DD"),
              "end_date" : formatDate(range.end_date, "yyyy-MM-DD"), 
            });
        }
    },[range]) 
 
    const handleDateClick = (arg) => { // bind with an arrow function
      setSelectedMealDate(arg.date);
      setDrawerType("CREATE");
      setIsScheduleDrawerOpen(true);
      setOpen(true);
    }

    const handleEventClick = (arg) => {
      setDrawerType("EDIT");
      setSelectedMealId(arg.event._def.extendedProps.meal?.meal_id); 
      setSelectedMealType(arg.event._def.extendedProps.meal_type);
      setSelectedMealPreference(arg.event._def.extendedProps.preference);
      setSelectedMealDate(arg.event._instance.range.start);
      setSelectedEvent(arg.event._def.publicId);
      setIsScheduleDrawerOpen(true);
      setOpen(true); 
    }

    const handleDrop = (arg) => { // bind with an arrow function

      try{
        updateMealPlanSchedule(dispatch, cookies, {
          "schedule_id" : arg.event._def.extendedProps.id,
          'date': formatDate(arg.event._instance.range.start, "yyyy-MM-DD"), 
          'user_id': userId,
          'dishes': [arg.event._def.extendedProps.dish_id],
          'meal_type': arg.event._def.extendedProps.meal_type,
          'preference':  arg.event._def.extendedProps.preference,
        }, false);   
      }catch (e){
        console.log(e);
      }

      fetchMealPlanSchedule(dispatch, cookies, {
        user_id: userId,
        "start_date" : formatDate(range.start_date, "yyyy-MM-DD"),
        "end_date" : formatDate(range.end_date, "yyyy-MM-DD"), 
      });
    }

    const handleEventReceive = (arg) => { // bind with an arrow function
      console.log(arg)
    }

    const handleDateRangeChange  = (arg) => { // bind with an arrow function
      setRange({
        'start_date' : arg.start,
        'end_date' :  arg.end
      });  
    }

    const renderEventContent = (eventInfo) => (
        <Card className="glass-box w-100 event-card">
          <i>{eventInfo.event.extendedProps.meal_type_name}</i>
          <br />
          <i>{eventInfo.event.title}</i>
        </Card>
    )


    const toggleScheduleModal = (anchor , open ) => (event ) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event ).key === 'Tab' ||
            (event ).key === 'Shift')
        ) {
            return;
        }    
        setIsScheduleDrawerOpen(open); 
    };

    const handleClose = () => {
      setOpen(false);
      setIsScheduleDrawerOpen(false); 
    }

    return (
      <Page title="Schedule Meal">
          {
            showOverviewDashboard && 
            <Container  maxWidth="xl" style={{marginTop:10, padding:1 }}>
              <Grid container spacing={3}> 

                <Stack direction="row" alignItems="center" justifyContent="space-between" margin={2}>
                    <Typography variant="h7" gutterBottom>
                        Select an user to view their meal calendar : 
                    </Typography>
                </Stack>

                <Grid container spacing={3} > 
                    {
                        clientList && clientList.map((e) => <Grid key={e.id} item   xs={12} sm={12} md={4} xl={3} lg={3} onClick={() => {
                            navigate(`/coach/schedule_meal?client_id=${e.id}`)
                        }} sx={{cursor: 'pointer'}}>
                            <UserListCard clientDetails={e} />
                        </Grid>)
                    }
                </Grid>
              </Grid>
            </Container>          
          }

          {
            !showOverviewDashboard && loading &&
            <Container  maxWidth="xl" style={{marginTop:10 }}>
              <Loader height={50} />
            </Container>
          }

          {
            !showOverviewDashboard && !loading &&
              <Container maxWidth="xl" className="meal-calendar" sx={{marginTop:4 }}>

                  <StackStyled direction="row" spacing={2}>

                      <FullCalendar
                        // defaultView="dayGridMonth"
                        rerenderDelay={1}
                        eventDurationEditable={false}
                        editable 
                        droppable 
                        selectable 
                        headerToolbar = {isMobile ? mobileHeaderToolbar : headerToolbar}
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        initialView= {isMobile ? 'timeGridDay' : 'dayGridMonth'}
                        // ref={calendarComponentRef}
                        events={data}
                        eventDrop={handleDrop}
                        drop={handleDrop}
                        eventReceive={handleEventReceive}
                        eventClick={handleEventClick}
                        dateClick={handleDateClick}
                        eventContent={renderEventContent}
                        // datesSet= {handleDateRangeChange}
                        // height={'60vh'}
                        
                      />

                      <BoxStyled>
                        <Card className={'glass-box'} sx={{padding:1}}>
                          <Typography variant="h5">Schedule a meal</Typography>
                          
                          <Grid padding={2} sx={{minHeight:'75vh'}}>
                            <ScheduleMealForm 
                              activeClient={activeClient} 
                              userId={activeClient?.user_id} 
                              date={formatDate(selectedMealDate,"yyyy-MM-DD" )}
                              handleClose={handleClose} 
                              visibleRange={range}                              
                            />
                          </Grid>
                          
                        </Card>
                      </BoxStyled>
                  </StackStyled>
                      
                    <Container>
                      <Dialog
                        fullWidth="lg" 
                        maxWidth="lg"
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                        sx: {
                            minHeight: '90vh',
                            maxHeight: '90vh'
                        }
                        }}
                        // sx={{height:'90vh'}}
                        // sx={{height:'80vh', width:'80%', margin:'10%', overflowY:'auto' }}
                        >

                          <AppBar sx={{ position: 'relative' }}>
                            <Toolbar>
                                <Grid container flexDirection={'column'} justifyContent={'flex-start'} padding={1}>
                                    <Grid container item flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Grid item>
                                            <Typography variant="h3">
                                              {
                                                "Meal Schedule"
                                                // formatDate(selectedMealDate,"DD MMM yyyy" )
                                                // `${selectedMealDate.getDate()} ${selectedMealDate.getMonth()} ${selectedMealDate.getFullYear()}`
                                              
                                                    // selectedMeal 
                                                    // ? selectedMeal.display_name
                                                    // : 'Add new meal'
                                                }
                                            </Typography>
                                        </Grid>
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={() => setOpen(false)}
                                            aria-label="close"
                                        >
                                            <Iconify icon="eva:close-circle-outline" sx={{height:40, width:40, cursor:'pointer'}}/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                          </AppBar>
                          <DialogContent>

                            <Grid padding={2} sx={{minHeight: 400}}>

                              {
                                drawerType === "CREATE"
                                ? <ScheduleMealForm 
                                    activeClient={activeClient} 
                                    userId={activeClient?.user_id} 
                                    date={formatDate(selectedMealDate,"yyyy-MM-DD" )} 
                                    handleClose={handleClose} 
                                    visibleRange={range}                              
                                  />
                                : null
                              }
                              {
                                drawerType === "EDIT"
                                ? <EditMealForm 
                                    activeClient={activeClient} 
                                    userId={activeClient?.user_id} 
                                    date={formatDate(selectedMealDate,"yyyy-MM-DD" )} 
                                    handleClose={handleClose} 
                                    visibleRange={range} 
                                    mealScheduleId={selectedEvent}
                                    selectedMealId={selectedMealId}
                                    selectedMealType={selectedMealType}
                                    selectedMealPreference={selectedMealPreference}
                                  />
                                : null                          
                              }
                            </Grid>
                          </DialogContent>
                        </Dialog>
                    </Container>

              </Container>
          }
        
      </Page>
    );
}