// @mui
import { Card, Box, Divider, Grid, Typography, Modal} from '@mui/material';
import { useEffect, useState, useRef } from 'react';

import { useSelector } from 'react-redux';
import AliceCarousel from 'react-alice-carousel';
// import Swiper , { Navigation, Pagination } from 'swiper';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper-bundle.css";

// import { SwiperSlide } from 'swiper/react';
import 'react-alice-carousel/lib/alice-carousel.css'; 
import { getActiveClientReport } from '../../../store/coach/reducer';

import '../../../assets/css/UserProfile.css';
import { LifestyleOptions } from '../../../constants/lifestyle_constants';
import Iconify from '../../../components/Iconify';
import UserProfileForm from './UserProfileForm';

// import 'swiper/swiper.scss';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// ----------------------------------------------------------------------
 
export default function MedicalHistory() {

  const handleDragStart = (e) => e.preventDefault();

//   const clientReport = useSelector(state => getActiveClientReport(state));

  const [items, setItems] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedKey, setSelectedKey] = useState(0);
  const [selectedFormCategory, setSelectedFormCategory] = useState(null);

  SwiperCore.use([Navigation, Pagination]);

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  useEffect(() => {

    const cards = [];

    if(LifestyleOptions && LifestyleOptions.data && LifestyleOptions.data.length > 0){
        LifestyleOptions.data.forEach((element, i) => {
            cards.push(
            <Card className='glass-box' key={element.key} onDragStart={handleDragStart} sx={{padding: 1, minHeight:'150px', background: element.bg_color}} onClick={() => {
                setIsModalVisible(true);
                setSelectedFormCategory(LifestyleOptions);
                setSelectedKey(element);
            }}>
                <Grid container flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} columnGap={2} flexWrap={'nowrap'}>
                    <Grid item backgroundColor={element.bg_color}>
                        {
                            element.img 
                            ? <img src={element.img} alt={element.label} width={48} height={48} />
                            : <img src={'https://png.pngtree.com/png-vector/20190328/ourmid/pngtree-vector-health-icon-png-image_872129.jpg'}
                                 alt={element.label} width={48} height={48} />
                        }
                    </Grid>
                    <Grid item>
                        <Typography variant="h5">
                            {element.label}
                        </Typography>
                    </Grid>
                </Grid>
                
                <Box margin={2} />
                {
                    element.severity
                    && <Grid backgroundColor={element.bg_color} width={'60px'} justifyContent={'center'} alignItems={'center'} textAlign={'center'} borderRadius={1}>
                        <Typography variant="body1">
                            {element.severity}
                        </Typography>
                    </Grid>
                }

                <Box margin={2} />

                <Typography variant="body2">
                    Last updated on : {element.last_updated}
                </Typography>
            </Card>
            );
      });
    }
    setItems(cards);
  },[LifestyleOptions]);

  
  const handleModalClose = () => {
    setIsModalVisible(false);
  }

  return (
    <Grid item container>
        <Grid container item sx={{width:'100%'}} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant="h4" sx={{ mb: 2, width:'80%' }}>
                Lifestyle
            </Typography>

            <Grid container item width={'10%'} flexDirection={'row'} justifyContent={'center'} alignItems={'flex-start'}>
                <Card ref={navigationPrevRef} sx={{padding:'8px', margin: '4px',cursor: 'pointer', color: '#000'}}>
                    <Iconify icon={'fa:chevron-left'} sx={{fontSize: '8px'}} />
                </Card>

                <Card ref={navigationNextRef} sx={{padding:'8px', margin: '4px', cursor: 'pointer', color: '#000'}}>
                    <Iconify icon={'fa:chevron-right'} sx={{fontSize: '8px'}} />
                </Card>
            </Grid>
        </Grid>

        <Grid container item sx={{width:'100%'}}>

            <Modal
                open={isModalVisible}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{zIndex:99}}
                // sx={{height:'100%', width:'100%', background:'#fff', borderRadius:2, margin:'0'}}
            >
                <Box>
                    <UserProfileForm profileCategory={selectedFormCategory} element={selectedKey} handleClose={handleModalClose}/>
                </Box>
            </Modal>
            <Swiper
                breakpoints={{
                    576: {
                    // width: 576,
                    slidesPerView: 1,
                    // slidesPerGroup: 1
                },
                    768: {
                    // width: 768,
                    slidesPerView: 2,
                    // slidesPerGroup: 2
                },
                    1024: {
                        // width: 768,
                        slidesPerView: 3,
                        // slidesPerGroup: 3
                    },
                    }}
                // slidesPerView={3}
                spaceBetween={0.5}
                slidesPerGroup={3}
                // centeredSlides
                effect={"coverflow"}
                grabCursor
                loop={false}
                // loopFillGroupWithBlank
                pagination={{
                clickable: true
                }}
                // navigation
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
                
             onBeforeInit={(swiper) => {
                  swiper.params.navigation.prevEl = navigationPrevRef.current;
                  swiper.params.navigation.nextEl = navigationNextRef.current;
             }}
                modules={[Pagination, Navigation]}
                className="medical-history-swiper">
                {
                    items.map((e) => {
                    return <SwiperSlide>
                        {e}
                    </SwiperSlide>
                    })
                }
                {/* <div ref={navigationPrevRef} />
                <div ref={navigationNextRef} /> */}
            </Swiper>
        </Grid>
    </Grid>
  );
}