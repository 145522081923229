import axios from '../../../utils/axios-configure';
import { API_GET_KITCHEN_STATS} from "../endpoints";

import {getAccessTokenFromCookie} from '../session/api'
import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../../store/layout/reducer';
import { setKitchenStats } from '../../../store/kitchen/reducer';
import { SNACKBAR_SEVERITY } from '../../../constants/app_constants';
import { DISH_UPDATE, DISH_ADD, DISH_RECIPE_UPDATE } from '../../../constants/message_constants';

export const fetchKitchenStats = (dispatch, cookies) => {
    dispatch(setLoadingStatus({status: true}));  

    const url = `${API_GET_KITCHEN_STATS}`;
 
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    if(accessToken != null){
        axios({
            method: "get",
            url,
            headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
          })
          .then((response) => {
            // handle success
            if(response.status === 200){
                dispatch(setKitchenStats({data: response.data.data}));
            }else{
              dispatch(setKitchenStats({data: {}}));
            } 
            dispatch(setLoadingStatus({status: false}));  

            // return true;
          })
          .catch((err) => {
            dispatch(setKitchenStats({data: {}})); 
            dispatch(setLoadingStatus({status: false}));  
          });
    }else{
      dispatch(setKitchenStats({data: {}}));
      dispatch(setLoadingStatus({status: false}));  
    }
}

