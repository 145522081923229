import React, { useState, useEffect } from 'react';
import merge from 'lodash/merge';
import {
    Box,
    Card,
    Stack,
    Grid,
    Container,
    Typography,
    styled,
    TextField,
    Button
} from '@mui/material';
import { useCookies } from 'react-cookie';
import ReactApexChart from 'react-apexcharts';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { useDispatch, useSelector } from 'react-redux';
import { addDays, subDays } from 'date-fns';
import { getUserId, getActiveCorporateClient } from '../../store/user/reducer';
import { fetchCorporateStatsReport, fetchCorporateVarietyReport, fetchCorporateFeedback } from '../../data/network/corporate/api';
import { getCorporateReport, getCorporateVarietyReport, getCorporateFeedback, getCorporateFeedbackStats } from '../../store/corporate/reducer';
import { formatDate } from '../../utils/date';
import Page from '../../components/Page';
import CorporateReportBar from './CorporateReportBar';
import Iconify from '../../components/Iconify';
import { BaseOptionChart } from '../../components/chart';


export default function CorporateReport() {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const dispatch = useDispatch();
    const partnerId = useSelector(state => getUserId(state));
    const report = useSelector(state => getCorporateReport(state));
    const varietyReport = useSelector(state => getCorporateVarietyReport(state));
    const feedbackReport = useSelector(state => getCorporateFeedback(state));
    const feedbackStats = useSelector(state => getCorporateFeedbackStats(state));
    const activeClient = useSelector(state => getActiveCorporateClient(state));
    const [cookies] = useCookies(['mobile', 'session', 'access_token']);
    const remaingSatisfactionPercentage = 100 - feedbackStats?.satisfaction_percentage;
    const remaingFoodIq = 100 - feedbackStats?.average_food_iq;

    useEffect(() => {
        if (partnerId != null) {
            const _todayDate = Date();
            const _endDate = formatDate(addDays(new Date(), 7)); 
            setEndDate(_endDate);
            const _startDate = formatDate(subDays(new Date(), 7));
            setStartDate(_startDate);

            fetchCorporateStatsReport(dispatch, cookies, activeClient,_startDate,_endDate);
            fetchCorporateVarietyReport(dispatch, cookies, activeClient,_startDate,_endDate);
            fetchCorporateFeedback(dispatch, cookies, activeClient,_startDate,_endDate);
        }
    }, [partnerId, activeClient])

    const handleStartDate = (newValue) => {
        setStartDate(formatDate(newValue));
    };
    const handleEndDate = (newValue) => {
        setEndDate(formatDate(newValue));
    };

    const handleSearch = () => {
        // const filterBy = { startDate, endDate }
        // if (partnerId != null) {
        //     fetchCorporateStatsReport(dispatch, cookies, partnerId, activeClient, filterBy);
        // }

        fetchCorporateStatsReport(dispatch, cookies, activeClient, startDate, endDate);
        fetchCorporateVarietyReport(dispatch, cookies, activeClient, startDate, endDate);
        fetchCorporateFeedback(dispatch, cookies, activeClient, startDate, endDate);
    }

    const StackStyled = styled(Stack)(({ theme }) => ({
        borderRadius:'10px',
        // background: '#fff',
        padding: '10px',
        [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',
            gap: 20,
            flexDirection: 'column'
        },
        '& .MuiBox-root': {
            [theme.breakpoints.down('md')]: {
                marginLeft: 0,
                border: 'none',
                flexWrap: 'wrap',
            },
            display: 'flex',
            alignItems: 'center',
            flexBasis: '25%',
            '&:not(:last-child)': {
                borderRight: '1px solid #ccc',
                [theme.breakpoints.down('md')]: {
                    borderRight: 'none',
                },
            },
            '& .icon': {
                fontSize: '3rem',
                marginRight: '20px',
                '& .MuiBox-root': {
                    border: 'none',
                }
            },
            '& .details': {
                display: 'flex',
                flexDirection: 'column',
                '& .MuiTypography-caption': {
                    textTransform: 'uppercase',
                }
            }
        },
        '& .MuiOutlinedInput-input': {
            padding: '10px 14px',
        }
    }))

    const VarietyStyled = styled(Box)(({theme}) => ({
        borderRadius:'10px',
        padding:'10px',
        '& .MuiStack-root': {
            display: 'flex',
            borderRadius: 10,
            flexDirection: 'row',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
            },
        },

        '& .chart-container': {
            width: '70%',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
        '& .detail-container': {
            width: '30%',
            [theme.breakpoints.down('md')]: {
                width: '100%',
                marginLeft: 0,
                marginTop: '20px',
            },
            marginTop: '72px',
            '& .variety-details': {
                display: 'flex',
                padding: '28px',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                gap: '10px',
                marginBottom: '10px',
                borderRadius: 0,
                '& .MuiTypography-caption': {
                    textTransform: 'uppercase',
                }
            }
        }
    }))

    const RatingStyled = styled(Box)(({theme}) => ({

        borderRadius:'10px',
        padding:'10px',

        '& .MuiStack-root': {
            justifyContent: 'space-around',
            marginTop: 50,
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
            },
        }
    }))

    const FeedbackStyled = styled(Box)(({theme}) => ({
        borderRadius: '10px',
        padding:'10px',
        '& .MuiStack-root': {
            display: 'flex',
            flexWrap: 'wrap',
            gap: 20,
            marginTop: 50,
            '& .feedback-container': {
                // background: '#fff',
                borderRadius: '10px',
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 10,
                flexBasis: '32%',
                margin: 0,
                [theme.breakpoints.down('md')]: {
                    flexBasis: '30%',
                },
                '& .left': {
                    img: {
                        height: '120px',
                        width: '120px',
                        objectFit: 'cover',
                    }
                },
                '& .right': {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10,
                    '& .MuiTypography-h6': {
                        fontSize: 16,
                    },
                    '& .MuiTypography-caption': {
                        fontSize: 14,
                    },
                    '& .dish-name': {
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 5,
                    },
                    '& .user': {
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: 10,
                    }
                },
            }
        }
    }))

    const getIcon = (name) => {
        switch (name) {
            case 'total_orders':
                return <Iconify icon="game-icons:hot-meal" />
            case 'total_items':
                return <Iconify icon="mdi:food" />
            case 'total_employees':
                return <Iconify icon="clarity:employee-group-solid" />
            default:
                return <Iconify icon="game-icons:hot-meal" />
        }
    }

    const chartDonutOptions = merge(BaseOptionChart(), {
        labels: ['Overall Satisfaction', 'Remaining'],
        title: {
            text: 'Overall Satisfaction',
            align: 'center',
            style: {
                fontSize: 14,
                fontFamily: 'Public Sans,sans-serif',
                color: '#000',
            }
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (y) => {
                    if (typeof y !== 'undefined') {
                        return `${y.toFixed(0)} %`;
                    }
                    return y;
                },
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        name: {
                            show: true,
                        },
                    }
                }
            }
        },
        stroke: {
            width: 0,
        },
        colors: [
            '#4eb2fc',
            '#c1d5e4'
        ],
        legend: {
            show: false,
        }
    });

    return (
        <Page title="Report Summary">
            <Container maxWidth="xl">
                <Box margin={1} />
                <Grid container justifyContent="space-between" sx={{ pb: 2 }}>
                    <Typography variant="h4" gutterBottom>
                        {/* Report Summary */}
                    </Typography>
                </Grid>
                <Box margin={2} />
                <StackStyled className='glass-box' direction='row' spacing={2}>
                    {report && report.map((ele) => (
                        <Box key={ele?.title}>
                            <div className='icon'>
                                {getIcon(ele?.name)}
                            </div>
                            <div className='details'>
                                <Typography variant='h6'>{ele?.value}</Typography>
                                <Typography variant='caption'>{ele?.title}</Typography>
                            </div>
                        </Box>
                    )
                    )}
                    <Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                inputFormat="YYYY/MM/DD"
                                value={startDate}
                                onChange={handleStartDate}
                                renderInput={(params) => <TextField {...params} sx={{ mr:1, width: 170 }} />}
                            />
                            <DesktopDatePicker
                                inputFormat="YYYY/MM/DD"
                                value={endDate}
                                onChange={handleEndDate}
                                renderInput={(params) => <TextField {...params} sx={{ mr: 1, width: 170 }} />}
                            />
                            <Button variant="contained" sx={{ ml: 1 }} onClick={handleSearch}>Go</Button>
                        </LocalizationProvider>
                    </Box>
                </StackStyled>
                <Box margin={2} />
                <VarietyStyled  className='glass-box'>
                    <Typography variant='h6' sx={{ my: 2 }}>Variety</Typography>

                    <Grid container flexDirection={'row'}>
                        <Box className='chart-container'>
                            <CorporateReportBar {...varietyReport} />
                        </Box>
                        <Box className='detail-container'>
                            {varietyReport?.number_data?.length > 0 && varietyReport?.number_data?.map((ele) => (
                                <Card className='variety-details' key={ele?.title}>
                                    <Typography variant='caption'>{ele?.title}</Typography>
                                    <Typography variant='h6'>{ele?.data}</Typography>
                                </Card>
                            ))}
                        </Box>
                    </Grid>
                </VarietyStyled>
                <Box margin={2} />
                <RatingStyled  className='glass-box'>
                    <Typography variant='h6' sx={{ my: 2 }}>Rating</Typography>
                    <Stack direction='row' spacing={2}>
                        {feedbackStats?.satisfaction_percentage &&
                            <Box className='chart-container'>
                                <ReactApexChart type="donut" series={[feedbackStats?.satisfaction_percentage, remaingSatisfactionPercentage]} options={chartDonutOptions} height={200} />
                            </Box>
                        }
                        {feedbackStats?.average_food_iq &&
                            <Box className='chart-container'>
                                <ReactApexChart type="donut" series={[feedbackStats?.average_food_iq, remaingFoodIq]}
                                    options={{
                                        ...chartDonutOptions,
                                        colors: ['#22ab7d', '#c1d5e4'],
                                        labels: ['Food IQ', 'Remaining'],
                                        title: {
                                            text: 'Food IQ Score',
                                            align: 'center',
                                            style: {
                                                fontSize: 14,
                                                fontFamily: 'Public Sans,sans-serif',
                                                color: '#000',
                                            }
                                        },
                                    }}
                                    height={200}
                                />
                            </Box>
                        }
                    </Stack>

                </RatingStyled>
                <Box margin={2} />
                <FeedbackStyled  className='glass-box'>
                    <Typography variant='h6' sx={{ my: 2 }}>Feedback</Typography>
                    {feedbackReport.length > 0 ?
                        <Stack direction='row' spacing={2}>
                            {feedbackReport?.map((feedback) => (
                                <Box className='feedback-container' key={feedback?.user?.id}>
                                    <div className='left'>
                                        <img src={feedback?.dish?.image_url} alt={feedback?.dish?.display_name} />
                                    </div>
                                    <div className='right'>
                                        <div className='dish-name'>
                                            <Typography variant='h6'>{feedback?.dish?.display_name}</Typography>
                                            <Typography variant='caption'>{feedback?.dish?.description}</Typography>
                                        </div>
                                        <Typography variant='h6'>Review</Typography>
                                        <div className='user-feedback'>
                                            <Typography>{feedback?.feedback?.feedback_title}</Typography>
                                            <Typography variant='caption'>{feedback?.feedback?.feedback_description}</Typography>
                                        </div>
                                        <div className='user'>
                                            <Iconify icon="ph:user-fill" />
                                            <Typography>{feedback?.user?.first_name} {feedback?.user?.last_name}</Typography>
                                        </div>
                                    </div>
                                </Box>
                            ))}

                        </Stack>
                        : <Typography variant='h4' sx={{ mt: 4, textAlign: 'center' }}>No feedback available</Typography>}
                </FeedbackStyled>
            </Container>
        </Page>
    )
}