/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { filter } from 'lodash';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import * as Yup from 'yup';

import { Form, FormikProvider, useFormik } from 'formik';
import { Box, Checkbox, Stack, Table, TableBody, TableCell,Typography, TableContainer, TablePagination, TableRow, Button, Grid, 
    Dialog, DialogActions, DialogContent,DialogContentText, DialogTitle, TextField, InputAdornment, Tabs, Tab, SwipeableDrawer, AppBar, Toolbar, IconButton  
} from "@mui/material";
import { TabContext, TabPanel } from '@mui/lab';
import Scrollbar from "../../../../components/Scrollbar";
import Iconify from '../../../../components/Iconify';
import ActivityListToolbar from './activity_list/ActivityListToolbar';
import ActivityListHead from './activity_list/ActivityListHead';
import SearchNotFound from '../../../../components/SearchNotFound';
import { getUserId } from '../../../../store/user/reducer';
import { fetchCorporateFeedback } from '../../../../data/network/corporate/api';
import { getCorporateFeedback } from '../../../../store/corporate/reducer';
import ExportCSV from '../../../../components/ExportCSV';
import { addActivity, fetchActivity, fetchActivityCategories } from '../../../../data/network/activity/api';
import { getActivityList } from '../../../../store/activity/reducer';
import CreateActivityForm from './activity_list/CreateActivityForm';
import ActivityMoreMenu from './activity_list/ActivityMoreMenu';
import BulkUploadActivityForm from '../../activity/BulkUploadActivityForm';

export default function ActivityList(){

    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    
    const dataList = useSelector(state => getActivityList(state));

    const dispatch = useDispatch(); 
    const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(0);
    const [bulkUploadDrawerOpen, setBulkUploadDrawerOpen] = useState(false);

    useEffect(() => {
        fetchActivity(dispatch, cookies);
        fetchActivityCategories(dispatch, cookies);
        console.log(dataList);
    },[]); 

    function a11yProps(index ) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }
      

    const TABLE_HEAD = [
        { id: 'id', label: '#', alignRight: false },
        { id: 'display_name', label: 'Name', alignRight: false },
        { id: 'category', label: 'Category', alignRight: false },
        { id: 'standard_duration', label: 'Activity Duration', alignRight: false },
        { id: 'standard_rest_duration', label: 'Rest Duration', alignRight: false },
        { id: 'standard_burned_calories', label: 'Calories Burned', alignRight: false },
        { id: 'menu', label: '', alignRight: false },
    ];

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
    }
      
    function getComparator(order, orderBy) {
        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
    }
      
    function applySortFilter(array, comparator, query) {
        if(array !== undefined && array != null &&  array.length>0){
          const stabilizedThis = array.map((el, index) => [el, index]);
          stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
          });
          if (query) {
            return filter(array, (_user) => _user.feedback.toLowerCase().indexOf(query.toLowerCase()) !== -1);
          }
          return stabilizedThis.map((el) => el[0]);  
        }
      
        return [];
}  

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
          const newSelecteds = dataList.map((n) => n.name);
          setSelected(newSelecteds);
          return;
        }
        setSelected([]);
    };
    
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleTabChange = (e, i) => {
        setValue(i);
    }
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };
    
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataList.length) : 0;

    const filteredData = applySortFilter(dataList, getComparator(order, orderBy), filterName);
    const isDataNotFound = filteredData.length === 0;

    const showAddNewActivityDrawer = () => {
        setOpen(true);
    };
 
    const handleClose = () => {
        setOpen(false);
    }

    const toggleBulkUploadModal = (anchor , open ) => (event ) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event ).key === 'Tab' ||
            (event ).key === 'Shift')
        ) {
            return;
        }    
        setBulkUploadDrawerOpen(open); 
    };

    return (
        <Box>
            <Grid container justifyContent="space-between">
                <Typography variant="h4" gutterBottom>
                    Activity
                </Typography> 
                <Grid item>
                    <Button variant="contained" justifyContent="center" alignItems="center" margin={1} onClick={() => {
                        setBulkUploadDrawerOpen(true);
                    }}>
                        Import new
                    </Button>  
                    <Button variant="contained" justifyContent="center" alignItems="center" margin={1} onClick={() => {

                    }}>
                        Export All
                    </Button>  
                    <Button variant="contained" justifyContent="center" alignItems="center" margin={1} onClick={() => {
                       showAddNewActivityDrawer(); 
                    }}>
                        Add new Activity
                    </Button> 
                </Grid>
            </Grid> 

            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Public" value={0} {...a11yProps(0)} />
                        <Tab label="Private" value={1} {...a11yProps(1)} />
                    </Tabs>
                </Box>

                <TabPanel value={0}> 
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            {
                            dataList && 
                            dataList.length>0 
                            && 
                            <Table>
                                <ActivityListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={dataList.length}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                                    console.log(row);
                                    const { activity_id, unique_name, display_name, category, video_link, standard_duration, standard_rest_duration, standard_burned_calories} = row;

                                    const isItemSelected = selected.indexOf(activity_id) !== -1;

                                    return (
                                    <TableRow
                                        hover
                                        key={activity_id}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={isItemSelected}
                                        aria-checked={isItemSelected}
                                    > 
                                        <TableCell component="th" scope="row" padding="none">
                                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}> 
                                            <Typography variant="subtitle2" noWrap>
                                            {activity_id}
                                            </Typography>
                                        </Stack>
                                        </TableCell>
                                        <TableCell align="center">{ display_name}</TableCell>
                                        <TableCell align="center">{ category}</TableCell> 
                                        <TableCell align="center">{ `${standard_duration} mins` }</TableCell>
                                        <TableCell align="center">{ `${standard_rest_duration} mins` }</TableCell>
                                        <TableCell align="center">{ `${standard_burned_calories} Kcal`}</TableCell>  
                                        <TableCell align="right">
                                            <ActivityMoreMenu activity={row} />
                                        </TableCell> 
                                    </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                                </TableBody>

                                {isDataNotFound && (
                                <TableBody>
                                    <TableRow>
                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                        <SearchNotFound searchQuery={filterName} />
                                    </TableCell>
                                    </TableRow>
                                </TableBody>
                                )}
                            </Table>
                            }
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={dataList ? dataList.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}/>
                </TabPanel>
                
                <TabPanel value={1}> 
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            {
                            dataList && 
                            dataList.length>0 
                            && 
                            <Table>
                                <ActivityListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={dataList.length}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                                    console.log(row);
                                    const { activity_id, unique_name, display_name, category, video_link, standard_duration, standard_rest_duration, standard_burned_calories} = row;

                                    const isItemSelected = selected.indexOf(activity_id) !== -1;

                                    return (
                                    <TableRow
                                        hover
                                        key={activity_id}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={isItemSelected}
                                        aria-checked={isItemSelected}
                                    > 
                                        <TableCell component="th" scope="row" padding="none">
                                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}> 
                                            <Typography variant="subtitle2" noWrap>
                                            {activity_id}
                                            </Typography>
                                        </Stack>
                                        </TableCell>
                                        <TableCell align="center">{ display_name}</TableCell>
                                        <TableCell align="center">{ category}</TableCell> 
                                        <TableCell align="center">{ `${standard_duration} mins` }</TableCell>
                                        <TableCell align="center">{ `${standard_rest_duration} mins` }</TableCell>
                                        <TableCell align="center">{ `${standard_burned_calories} Kcal`}</TableCell>  
                                        <TableCell align="right">
                                            <ActivityMoreMenu activity={row} />
                                        </TableCell> 
                                    </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                                </TableBody>

                                {isDataNotFound && (
                                <TableBody>
                                    <TableRow>
                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                        <SearchNotFound searchQuery={filterName} />
                                    </TableCell>
                                    </TableRow>
                                </TableBody>
                                )}
                            </Table>
                            }
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={dataList ? dataList.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}/>
                
                </TabPanel>
                
            </TabContext>
            
            <Dialog fullWidth minWidth="xl" maxWidth="xl" open={open} onClose={handleClose}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Grid container direction="column">
                            <Typography sx={{ padding:2, flex: 1 }} variant="h6" component="div">
                                Add new Activity
                            </Typography>
                        </Grid>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <Iconify  icon="eva:close-circle-outline" sx={{height:40, width:40, cursor:'pointer'}}/>
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <DialogContent>
                    <CreateActivityForm handleClose={handleClose} />
                </DialogContent>
            </Dialog>

            <SwipeableDrawer
                anchor="bottom"
                open={bulkUploadDrawerOpen} 
                onClose={toggleBulkUploadModal('bottom', false)}
                onOpen={toggleBulkUploadModal( 'bottom', true)}>
                <DialogContent>
                    <BulkUploadActivityForm handleClose={toggleBulkUploadModal('bottom', false)} />                    
                </DialogContent>
            </SwipeableDrawer>
        </Box>
    );
}