
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Stack, Typography, Box, TextField, Select, MenuItem, Button, styled, Table, TableHead, TableCell, Paper, TableRow, Dialog, DialogTitle } from '@mui/material';
import { useCookies } from 'react-cookie';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { formatDate } from '../../../utils/date';
import { getActiveStoreId, getStoreStaff } from '../../../store/partner_store/reducer';
import { ORDER_STATUS } from '../../../constants/app_constants';
import { updateStoreOrders } from '../../../data/network/store/order';

const TextFieldStyle = styled(TextField)(() => ({
    '& .MuiInputBase-input': {
        padding: '8px 14px',
    }
}));

const SelectStyle = styled(Select)(() => ({
    '& .MuiSelect-select': {
        padding: '8px 14px',
    }
}));


export const CoachClientDetail = ({ data, visible, setOpen }) => {
    const [editEnable, setEditEnable] = useState(true);
    const [orderData, setOrderData] = useState({});
    const [editableData, setEditableData] = useState({});
    const [date, setDate] = useState(null);

    const dispatch = useDispatch();
    // const activeClient = useSelector(state => getActiveCorporateClient(state));
    const [cookies] = useCookies(['mobile', 'session', 'access_token']);

    const storeId = useSelector((state) => getActiveStoreId(state));

    const STAFFLIST = useSelector((state) => getStoreStaff(state));

    useEffect(() => {
        setOrderData(data);
        console.log(data);

        const mealScheduleIds = []
        let status = ''
        let poc = '';
        let chef = '';

        if (data && data.menu_options && data.menu_options.length > 0){
            data.menu_options.forEach(element => {
                mealScheduleIds.push(element.meal_schedule_id); 
            }); 

            status = data.menu_options[0].current_status.status;

            if (data.menu_options[0].poc_user) {
                poc = data.menu_options[0].poc_user.id;
            }

            if (data.menu_options[0].poc_chef) {
                chef = data.menu_options[0].poc_chef.id;
            }

            // poc = data.menu_options[0].poc_user.id;

            // chef = data.menu_options[0].poc_chef.id;
            
        }

        setEditableData({
            'meal_schedule_id':mealScheduleIds,
            'status' : status,
            'poc' : poc,
            'chef' : chef
        })

    }, [data])

    const handleChange = (event) => {
        // if (event.target.name === 'status') {
        //     setOrderData({ ...orderData, 'current_status': {'status' : event.target.value}, [event.target.name]: event.target.value })
        // }else{
        //     setOrderData({ ...orderData, [event.target.name]: event.target.value })
        // }

        setEditableData({ ...editableData, [event.target.name]: event.target.value })
    }

    const handleDate = (newValue) => {
        setDate(formatDate(newValue));
        setOrderData({ ...orderData, dob: formatDate(newValue) })
    }

    const handleUpdate = () => {

        const payload = {
            'schedule_id' : editableData.meal_schedule_id,
            'status' : editableData.status,
            'poc_user_id' : editableData.poc ?? '',
            'chef_user_id' : editableData.chef ?? ''
        }

        updateStoreOrders(dispatch, cookies, storeId, payload);
        setOpen(false);
    }
    return (
        <Dialog  
            // anchor='right'
            open={visible}
            onClose={() => setOpen(false)}
            maxWidth={'md'}
            fullWidth
        >
            <DialogTitle>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='h6'>Detail View</Typography>
                    <Button size='small' color='info' onClick={() => setEditEnable(false)}>Edit</Button>
                </Box>
            </DialogTitle>
            <Stack sx={{ width: 'md' }} style={{ padding: '20px', gap: '10px' }}>
                {/* <Box sx={{ pb: 2 }} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='h6'>Detail View</Typography>
                    <Button size='small' color='info' onClick={() => setEditEnable(false)}>Edit</Button>
                </Box> */}
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Organization</Typography>
                    <Typography variant="body1">{orderData?.client_details?.display_name}</Typography>
                    {/* <TextFieldStyle disabled={editEnable} name='org' value={orderData?.org} onChange={handleChange} /> */}
                </Box>

                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Date</Typography>
                    <Typography variant="body1">{formatDate(orderData?.date, "DD MMM YYYY")}</Typography>
                    {/* <TextFieldStyle disabled={editEnable} name='org' value={orderData?.org} onChange={handleChange} /> */}
                </Box>

                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Service</Typography>
                    <Typography variant="body1">{`${orderData?.service_details?.display_name} `}
                    </Typography>
                    {/* <Typography variant="body1">{`${orderData?.service_details?.display_name} 
                        (
                            ${orderData?.service_details.service_start_time} - ${orderData?.service_details.service_end_time}
                        )`}
                    </Typography> */}

                    
                </Box>

                <Table component={Paper}>
                    <TableHead>
                        <TableCell>{''}</TableCell>
                        <TableCell>{'Meal Name'}</TableCell>
                        <TableCell>{'Quantity'}</TableCell>
                    </TableHead>

                    {
                        
                        orderData && orderData.menu_options && orderData.menu_options.map((e,i) => {
                            return <TableRow key={i}>
                                <TableCell> {i+1} </TableCell>
                                <TableCell> {e.display_name} </TableCell>
                                <TableCell> {e.total_quantity} </TableCell>
                            </TableRow>
                        })
                    
                    }
                </Table> 
                   
                
                {/* <Box margin={1} />
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Delivered at</Typography>
                    <Typography variant="body1">{orderData?.delivery_dt}</Typography>
                </Box> */}
                
                {/* <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Delivered at</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            inputFormat="YYYY/MM/DD"
                            value={date}
                            onChange={handleDate}
                            disabled={editEnable}
                            renderInput={(params) => <TextFieldStyle {...params} sx={{ width: 220 }} />}
                        />
                    </LocalizationProvider>
                </Box>
                 */}

                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Assign POC</Typography>
                    <SelectStyle
                        value={editableData?.poc}
                        onChange={handleChange}
                        displayEmpty
                        // disabled={editEnable}
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{ width: 220 }}
                        name="poc"
                    >
                        {/* <MenuItem value="Breakfast" >Breakfast</MenuItem> */}
                        {
                            STAFFLIST && STAFFLIST.data && STAFFLIST.data.map((e, i) => {
                                return <MenuItem key={i} value={e.id}>{e.name}</MenuItem>
                            })
                        }
                    </SelectStyle>
                </Box>

                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Assign Chef</Typography>
                    <SelectStyle
                        value={editableData?.chef}
                        onChange={handleChange}
                        displayEmpty
                        // disabled={editEnable}
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{ width: 220 }}
                        name="chef"
                    >
                        {/* <MenuItem value="Breakfast" >Breakfast</MenuItem> */}
                        {
                            STAFFLIST && STAFFLIST.data && STAFFLIST.data.map((e,i) => {
                                return <MenuItem key={i} value={e.id}>{e.name}</MenuItem>
                            })
                        }
                    </SelectStyle>
                </Box>


                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Status</Typography>
                    <SelectStyle
                        value={editableData?.status}
                        onChange={handleChange}
                        displayEmpty
                        // disabled={editEnable}
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{ width: 220 }}
                        name="status"
                    >
                        {/* <MenuItem value="Breakfast" >Breakfast</MenuItem> */}
                        {
                            Object.keys(ORDER_STATUS).map((e, i) => {
                                return <MenuItem key={i} value={e}>{e}</MenuItem>
                            })
                        }
                    </SelectStyle>
                </Box>


                {<Button sx={{ mt: 2, pt: 1, pb: 1 }} size='small' variant='contained' onClick={handleUpdate}>Update</Button>}
           
                {/* <Button sx={{ mt: 2, pt: 1, pb: 1 }} size='small' variant='contained'>Print Label</Button> */}

            </Stack>
        </Dialog>
    )

}