import { useState, useEffect } from "react";
import { Box, Button, Card, Container, Grid, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import Iconify from "../../../../components/Iconify";
import { addDishToSelectedMeal, getSelectedMeal, getSelectedMealNutritionInfo, removeDishFromSelectedMeal, setMealCreationCurrentStep, updateDishPortionWeight } from "../../../../store/meal/reducer";
import { MEAL_CREATION_FORM_STEPS } from "../../../../constants/meal_constants";
import '../../../../assets/css/Meal.css';
import { fetchStoreDishList, updateStoreMenu } from "../../../../data/network/store/menu";
import { getActiveStoreId, getStoreDishList } from "../../../../store/partner_store/reducer";
import { getPartnerDishFiltered } from "../../../../store/user/reducer";

export default function CreateMealDishInfo() {

    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

    const selectedMeal = useSelector((state) => getSelectedMeal(state));

    const selectedMealNutritionInfo = useSelector((state) => getSelectedMealNutritionInfo(state));

    const [isNutritionCardVisible, setIsNutritionCardVisible] = useState(true);
    const [isDishListVisible, setIsDishListVisible] = useState(false);
    const [isEditPortionWeightVisible, setEditPortionWeightVisibility] = useState(false);

    const filteredDishList = useSelector((state) => getStoreDishList(state));
    // const filteredDishList = useSelector((state) => getPartnerDishFiltered(state));

    const storeId = useSelector((state) => getActiveStoreId(state));

    const showDishList = () => {
        setIsNutritionCardVisible(false);
        setIsDishListVisible(true);
    }

    const hideDishList = () => {
        setIsNutritionCardVisible(true);
        setIsDishListVisible(false);
    }
    
    const showEditPortionField = () => {
        setEditPortionWeightVisibility(true);
    }

    const hideEditPortionField = () => {
        setEditPortionWeightVisibility(false);
    }

    const handleUpdateMealData = () => {

        const _dishes = [];
        selectedMeal.dishes.forEach((dish) => {
            _dishes.push({
                'dish_id' : dish.id,
                'portion_weight': dish.portion_size,
                'weight_unit' : dish.weight_unit ?? "GM"
            });
        });

        const mealBody = {
            'display_name' : selectedMeal.display_name,
            'name' : selectedMeal.name,
            'description' : selectedMeal.description,
            'dishes' : _dishes
        }
        
        if (selectedMeal && selectedMeal.meal_id) {
            mealBody.meal_id = selectedMeal.meal_id
        }

        updateStoreMenu(dispatch, cookies, storeId, {'data' : [mealBody]});

        // dispatch(setMealCreationCurrentStep({'step': MEAL_CREATION_FORM_STEPS.ACK}));
    }

    const handleDishInfoUpdate = () => {
        
        // const mealBody = {
        //     'display_name' : formik.values.name,
        //     'name' : formik.values.name.replace(' ','_').toLowerCase(),
        //     'description' : formik.values.description,
        // }

        // updateStoreMenu(dispatch, cookies, storeId, {'data' : [mealBody]});



        hideEditPortionField();
    }

    const handleRemoveDish = (dish) => {
        dispatch(removeDishFromSelectedMeal({data: dish}));
    }

    const handlePortionWeightChange = (event, dish) => {
        const _dish = {...dish, 'portion_size': event.target.value}
        dispatch(updateDishPortionWeight({dishId : dish.id, data: _dish}));
    }

    return (
        <Container style={{ maxHeight:'100%'}}>
            <Box margin={4} />

                <Grid container>
                    <Grid container xl={8} lg={8} md={8} xs={12} sm={12} justifyContent={'flex-start'} alignItems={'flex-start'} style={{maxHeight:'70vh'}}>
                   
                        <Grid container rowGap={2} columnGap={1} flexDirection={'row'} justifyContent={'flex-start'} padding={1} flexWrap={'wrap'}>
                        {
                            selectedMeal && selectedMeal.dishes.map((dish) => {
                                return <Grid sx={{border: '1px solid black', borderStyle: "dotted", borderRadius: 2, padding: 1 }}>
                                    {/* <Grid sx={{position: 'relative', top: 0, right: 0, width:'18px', backgroundColor: 'red', borderRadius: '50px'}}>
                                        <Iconify icon={'eva:plus-outline'} sx={{fontSize: '16px', color: '#fff'}} />
                                    </Grid> */}
                                    <Card xl={3} lg={3} md={6} xs={6} sm={6} className='glass-box' sx={{borderRadius: 2, minHeight: '180px', width: '160px'}}>
                                        <Grid container direction="column" justifyContent={'space-between'} alignItems={'flex-start'}>
                
                                            <Grid item container direction="row" justifyContent="center">
                                                <img width={'160px'} height={'60px'} src={ `${window.location.origin}/static/img/meal_default.jpeg`} alt={''} />
                                            </Grid>
                                            <Grid padding={1} item container direction="row" justifyContent="flex-start">
                                                <Typography  variant="body1">
                                                    {dish && dish.display_name && `${dish.display_name}`}
                                                    {dish && dish.name && `${dish.name}`}
                                                </Typography>
                                            </Grid>

                                            <Grid paddingX={1} container item direction={'column'} justifyContent={'flex-start'} alignItems={'center'}>
                                                        <Grid container flexDirection={'column'}>
                                                            <Grid container flexDirection={'row'}>
                                                                <Typography variant="caption">
                                                                    Portion size
                                                                </Typography>
                                                            </Grid>
                                                            <Grid container flexDirection={'row'} alignItems={'flex-start'}>
                                                                {isEditPortionWeightVisible
                                                                    ? <>
                                                                        <TextField 
                                                                            variant="standard"
                                                                            key="portion_weight"
                                                                            label=""
                                                                            // defaultValue={dish.portion_weight && dish.portion_weight}
                                                                            value={dish.portion_size}
                                                                            sx={{width:'40px', height:'10px'}}
                                                                            onChange={(event) => handlePortionWeightChange(event, dish)}
                                                                            
                                                                        />
                                                                        <Typography variant="body2">
                                                                            {dish && dish.weight_unit}
                                                                        </Typography>

                                                                        <Grid item flexDirection={'row'} alignItems={'center'} sx={{padding:1, cursor:'pointer'}}>
                                                                            <Iconify icon={'eva:checkmark-outline'} sx={{color: '#000'}} onClick={() => handleDishInfoUpdate()} />
                                                                        </Grid>
                                                                    </>
                                                                    : <>
                                                                        <Typography variant="body2">
                                                                            {dish && dish.portion_size} {dish && dish.weight_unit}
                                                                        </Typography>
                                                                        <Grid item flexDirection={'row'} alignItems={'center'} paddingLeft={1} sx={{cursor:'pointer'}}>
                                                                            <Iconify icon={'eva:edit-outline'} sx={{color: '#000'}} onClick={() => showEditPortionField()} />
                                                                        </Grid>
                                                                    </>
                                                                }
                                                                
                                                            </Grid>
                                                        </Grid>
                                                        
                                               
                                            </Grid>
                                            {/* <Grid>
                                                <Typography variant="caption">Remove</Typography>
                                            </Grid> */}
                                            <Grid paddingTop={2} item container direction="row" justifyContent="flex-start">
                                                <Grid item xl={4} lg={4} md={4} sm={4} xs={4} margin={2}>
                                                    <Button variant="outlined" fullWidth onClick={() => {
                                                        handleRemoveDish(dish);
                                                    }}>
                                                        <Grid container justifyContent={'center'} alignItems={'center'} columnGap={1}>
                                                            <Typography variant="caption">Remove</Typography>
                                                            {/* <Iconify icon={'eva:checkmark-outline'} style={{color: '#fff', fontSize:'16px'}} /> */}
                                                        </Grid>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            })
                        }

                            <Grid sx={{border: '1px solid black', borderStyle: "dotted", borderRadius: 2, padding: 1 }}>
                                <Grid container flexDirection={'column'} justifyContent={'center'} alignItems={'center'} sx={{ minHeight: '180px', width: '160px', cursor:'pointer' }} 
                                    onClick={(e) => {
                                        showDishList();
                                    }}>
                                    <Iconify icon={'eva:plus-outline'} style={{fontSize:'64px'}} />
                                    <Box margin={1} />
                                    <Typography variant="h6" >Add a dish</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container padding={2} xl={4} lg={4} md={4} xs={12} sm={12} style={{background: '#fff'}} justifyContent={'center'} alignItems={'flex-start'}>  
                        {
                            isNutritionCardVisible &&
                            <Card style={{width:'100%', margin:2}}>
                                <Grid container flexDirection={'column'} padding={2}>
                                    
                                    <Typography variant="h4">Nutrition Info</Typography>

                                    {
                                        selectedMealNutritionInfo && selectedMealNutritionInfo.map((e) => {
                                            return <Grid container flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} margin={2}>
                                                <Typography variant="body1">{e.label} : </Typography>
                                                <Typography style={{paddingLeft: 4}} variant="body1">{`${e.value} ${e.unit}`}</Typography>
                                            </Grid>
                                        })
                                    }

                                </Grid>                                                    
                            </Card>
                        }

                        {
                            isDishListVisible &&
                            <Card className="create-meal-dish-list">
                                <Grid className="glass-box" container flexDirection={'column'} justifyContent={'flex-start'}>
                                    <Grid className="create-meal-dish-list-header" sx={{position:'sticky', top: 0, background: '#fff', zIndex:9, boxShadow: '0px 0px 8px 1px #bebebe'}}  padding={1}>
                                        <Typography variant="h4">Dish List</Typography>
                                        <Grid container flexDirection={'column'} rowGap={1} padding={1}>
                                            {/* <Typography variant="body1" >Search</Typography> */}
                                            <TextField
                                                fullWidth
                                                // label="Name"
                                                // defaultValue={selectedMeal && selectedMeal.display_name}
                                                placeholder='Search here for dish'
                                                // {...getFieldProps('name')}
                                                // error={Boolean(touched.name && errors.name)}
                                                // helperText={touched.name && errors.name}                               
                                                onKeyUp={(e) => {
                                                    console.log(e.target.value);
                                                    if (e.target.value.length > 3){
                                                        fetchStoreDishList(dispatch, cookies, storeId, 1, 19, {'key':'term', 'value' : e.target.value});
                                                    }
                                                    // TODO : live search

                                                }} 
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container flexDirection={'column'} paddingY={1} paddingX={2}>
                                        {
                                            filteredDishList && filteredDishList.data && filteredDishList.data.map((e, i) => {
                                                return (i < 20) &&
                                                <Grid 
                                                    container
                                                    className="glass-box" 
                                                    padding={1}  
                                                    flexDirection={'row'} 
                                                    justifyContent={'space-between'} 
                                                    alignItems={'center'} 
                                                    marginY={1}
                                                    onClick={() => {
                                                        dispatch(addDishToSelectedMeal({'data': {...e, 'id': e.product_id}}));
                                                        hideDishList();
                                                    }}
                                                    sx={{cursor:'pointer'}}
                                                >
                                                    <Typography xl={8} lg={8} md={8} xs={8} sm={8} variant="body1">{e.display_name}</Typography>
                                                    <Iconify xl={4} lg={4} md={4} xs={4} sm={4} icon={'eva:plus-outline'} />
                                                    {/* <Typography style={{paddingLeft: 4}} variant="body1">{`${e.value} ${e.unit}`}</Typography> */}
                                                </Grid>
                                            })
                                        }
                                    </Grid>

                                </Grid>                                                    
                            </Card>
                        }
                    </Grid>
                </Grid>


                <Box margin={4} />


                <Grid direction="row" justifyContent="space-evenly" alignItems="center" container style={{position:'absolute', left:0, bottom: '40px'}}>
                    <Box style={{width:'15%'}}>
                        {  
                            <LoadingButton  size="large" onClick={() => 
                                dispatch(setMealCreationCurrentStep({'step': MEAL_CREATION_FORM_STEPS.BASIC_DETAILS}))
                            } variant="contained" loading={false}>
                                <Grid container justifyContent={'center'} alignItems={'center'} columnGap={1}>
                                    <Iconify icon={'eva:arrow-back-outline'} style={{color: '#fff', fontSize:'16px'}} />
                                    <Typography variant="body1">
                                        Back
                                    </Typography>
                                </Grid>
                            </LoadingButton>
                        }

                    </Box>
                    <Box style={{width:'15%'}}>
                        {  
                            <LoadingButton size="large" onClick={() => handleUpdateMealData()
                            } variant="contained" loading={false}>
                                <Grid container justifyContent={'center'} alignItems={'center'} columnGap={1}>
                                    <Typography variant="body1">
                                        Next
                                    </Typography>
                                    <Iconify icon={'eva:arrow-forward-outline'} style={{color: '#fff', fontSize:'16px'}} />
                                </Grid>
                            </LoadingButton>
                        }

                    </Box>
                </Grid>
        </Container>
    );
}