import { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Chip, Grid, RadioGroup } from '@mui/material';
import SubscriptionPlanCard from '../../../components/cards/subscription_plan_card';
import { getSubscriptionPlanList, getSubscriptionPlanListStatus } from '../../../store/subscription/reducer';
import '../../../assets/css/PlanList.css';
import Iconify from '../../../components/Iconify';
import { setClientPlanSelection, getClientSelectedPlan, setClientOrderType } from '../../../store/coach/reducer';
import { ONBOARDING_FORM_STEPS, ORDER_TYPE, PLAN_SELECTION_STATUS, STATUS } from '../../../constants/app_constants';
import { setOnboardingCurrentStep } from '../../../store/onboarding/reducer';
import { fetchSubscriptionPlanList } from '../../../data/network/subscription/api';
import { ReactComponent as Loading  } from '../../../assets/img/loader.svg'; 

export default function SubscriptionPlanList(){

    const subscriptionPlanList = useSelector((state) => getSubscriptionPlanList(state));
    const subscriptionPlanListStatus = useSelector((state) => getSubscriptionPlanListStatus(state));
    const [count, setCount] = useState(0); // For detecting start scroll postion
    const [scrollX, setscrollX] = useState(0); // For detecting start scroll postion
    const [scrolEnd, setscrolEnd] = useState(false); // For detecting end of scrolling
    const  scrl   = useRef();
    const clientSelectedPlan = useSelector((state) => getClientSelectedPlan(state));

    const dispatch = useDispatch();
    useEffect(() => {
        if (
            scrl.current &&
            scrl?.current?.scrollWidth === scrl?.current?.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
        return () => {};
    },[scrl?.current?.scrollWidth, scrl?.current?.offsetWidth]);
 
    const scrollCheck = () => {
        setscrollX(scrl.current.scrollLeft);
        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    const slide = (shift) => {
        scrl.current.scrollLeft += shift;
        setscrollX(scrollX + shift); // Updates the latest scrolled postion
     
        if (
          Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
          scrl.current.offsetWidth
        ) {
          setscrolEnd(true);
        } else {
          setscrolEnd(false);
        }
    };

    const ChipControl = function ChipControl({
        item,
        onChange,
        ...props
    }) {
        return (
          <SubscriptionPlanCard key={item.id} subscriptionPlan={item} selected={clientSelectedPlan !== null ? item.id === clientSelectedPlan.id : false} />
        );
    };

    return (
        <div className="">

            {  
                subscriptionPlanListStatus === STATUS.LOADING && 
                <Loading />
            }

            {
                subscriptionPlanListStatus === STATUS.ERROR && 
                <Grid>
                    <p>Unable to fetch subscription plan list at the moment. Please try again after a few moments.</p>

                    <Button onClick={ ()=>{
                        fetchSubscriptionPlanList();
                    } } >
                        Retry 
                    </Button>
                </Grid>
            }

            {
                subscriptionPlanListStatus === STATUS.SUCCESS && 
                <Grid container className=' plan_list' direction="row" justifyContent="start" alignItems="center">
                    <RadioGroup
                        row
                        value={clientSelectedPlan}
                        onChange={e => console.log(e)} 
                    >
                        {
                            subscriptionPlanList != null && subscriptionPlanList.length > 0 &&
                            subscriptionPlanList.map((item) => 
                                <ChipControl
                                    key={item.id}
                                    item={item}
                                />
                            )
                        }
                        
                    </RadioGroup>
                </Grid>
            }

      </div>
    );  
};