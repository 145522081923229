// Possible values : staging , production
export const ENV='production'


// export const RAZORPAY_KEY = "rzp_test_HBt61MOFT8L6ZB";
// export const RAZORPAY_KEY = "rzp_test_LFH0TySRmo8gc4";
export const RAZORPAY_KEY = "rzp_live_n3Zm83U5PMJTVN ";

export const APP_VERSION = '1.1.0';
export const DEVICE_TYPE = 'WEB';

export const ENCRYPTION_KEY = "WCBoaDQIQAgceGg8dFAkMDBEOECEZCxg";
export const ENCRYPTION_IV = "bytes@password20";

export const STATUS = {
    LOADING : 'loading',
    SUCCESS: 'success',
    ERROR : 'error',
};

export const PLAN_SELECTION_STATUS = {
    INITIAL : 'status_initial',
    SELECTED : 'status_selected',
    CONFIRMED: 'status_confirmed',
    SKIPPED : 'status_skipped',
};

export const CLIENT_MEAL_SCHEDULE_EDIT_STATUS = {
    INITIAL : 'status_initial',
    EDITING : 'status_editing',
};

export const CLIENT_ACTIVITY_SCHEDULE_EDIT_STATUS = {
    INITIAL : 'status_initial',
    EDITING : 'status_editing',
};

export const PREFERENCE_STATUS = {
    INITIAL : 'initial',
    SELECTED : 'selected',
};

export const CART_STATUS = {
    INITIAL : 'initial',
    CONFIRMED : 'comfirmed',
};

export const PAYMENT_STATUS = {
    INITIAL : 'initial',
    PENDING: 'pending',
    CONFIRMED : 'comfirmed',
    CANCELLED: 'cancelled',
    FAILED: 'failed' 
};

export const MEAL_PREFERENCE = {
    VEGETARIAN : 'veg',
    NONVEGETARIAN: 'non-veg',
    EGGETARIAN : 'egg'
};

export const ORDER_TYPE = {
    SUBSCRIPTION : 'SUBSCRIPTION',
    ZERO_DELIVERY: 'ZERO_DELIVERY' 
};

export const MEAL_TYPE = {
    BREAKFAST : 'Breakfast',
    LUNCH: 'Lunch',
    SNACKS : 'Snacks',
    DINNER: 'Dinner'
};

export const ONBOARDING_STATUS = {
    INITIAL: 'initial',
    SUCCESS: 'success',
    FAIL : 'fail',
};

export const ONBOARDING_FORM_STEPS = {
    OTP_VERIFICATION : {
        'step' : 'otp_verification',
    },
    PERSONAL_INFO: {
        'step' : 'personal_info',
    },
    PLAN_LIST: {
        'step' : 'plan_list',
    },
    PREFERENCE_SELECTION: {
        'step' : 'preference_selection',
    },
    SUBSCRIPTION_DETAILS: {
        'step' : 'subscription_details',
    },
    PAYMENT_DETAILS: {
        'step' : 'payment_details',
    },
    ONBOARDING_CONFIRMATION: {
        'step' : 'onboarding_confirmation',
    }
}


export const PAYMENT_MODE = {
    OFFLINE : 'offline',
    ONLINE: 'online'
}

export const calendarMealType = [
    {
      text: 'Breakfast',
      id: 1,
      color: '#FF7043',
    }, {
      text: 'Lunch',
      id: 2,
      color: '#7E57C2',
    }, {
      text: 'Snacks',
      id: 3,
      color: '#FFA726',
    }, {
      text: 'Dinner',
      id: 4,
      color: '#E91E63',
    } 
];

export const mealPreferences = [
    {
      text: 'veg',
      display_name: 'Vegetarian',
      id: 1,
      color: '#FF7043',
    }, {
      text: 'non-veg',
      display_name: 'Non - Vegetarian',
      id: 2,
      color: '#7E57C2',
    },  
];


export const ROLES = {
    NUTRITIONIST : {
        name: 'NUTRITIONIST',
        path: '/coach/client'
    },
    CORPORATE_MANAGER : {
        name: 'CORPORATE_MANAGER',
        path: '/corporate'
    },
    STORE_MANAGER : {
        name : 'STORE_MANAGER',
        path : '/store/orders',
    }
};

export const SNACKBAR_SEVERITY = {
    ERROR : 'error',
    INFO: 'info',
    WARNING: 'warning', 
    SUCCESS: 'success', 
};

export const FOOTER_LINKS = {
    ABOUT_US : 'https://www.bytes.express/about-us/',
    FAQS: 'https://www.bytes.express/#faq/',
    PRIVACY_POLICY : 'https://www.bytes.express/terms-and-conditions/',
    FACEBOOK_URL : 'https://www.facebook.com/bytesthedeliciousproject/',
    INSTAGRAM_URL : 'https://www.instagram.com/bytes_thedeliciousproject/',
    TWITTER_URL : 'https://twitter.com/bytes_foodstory',
    LINKEDIN_URL : 'https://www.linkedin.com/company/the-bytes/',
    YOUTUBE_URL : 'https://www.youtube.com/channel/UCnEMaOim8duaTOUYvcUF4CQ',
}

export const DAILY_LOG_TYPE = {
    WATER : "WATER",
    SLEEP : "SLEEP",
    EXTERNAL_MEAL_ITEMS : "EXTERNAL_MEAL_ITEMS",
    ACTIVITY : "EXTERNAL_ACTIVITY",
    WEIGHT : "WEIGHT"
}


export const dishCategoryList = [
    {
        "display_name" : "Vegetarian",
        "value" : 'veg'
    },
    {
        "display_name" : "Non-Vegetarian",
        "value" : 'nonveg'
    },
]


export const STOCK_UNITS = {
    "MG" : "MG",
    "GM" : "GM",
    "LITRE" : "LITRE",
    "ML" : "ML",
    "KG" : "KG",
    "NOS" : "NOS"    
}

export const VENDOR_PAYMENT_TYPE = {
    "CREDIT" : "CREDIT",
    "PRE_PAID" : "PRE_PAID",
    "POST_PAID" : "POST_PAID"
}

export const EXPENSE_STATUS = {
    "REQUESTED" : "REQUESTED",
    "IN_TRANSIT" : "IN_TRANSIT",
    "RECEIVED" : "RECEIVED",
    "QA_PASSED" : "QA_PASSED",
    "STORED" : "STORED",
}

export const ORDER_STATUS = {
    "CONFIRMED" : "CONFIRMED",
    "ASSIGNED" : "ASSIGNED",
    "REASSIGNED" : "REASSIGNED",
    "COOKING" : "COOKING",
    "COOKED" : "COOKED",
    "PACKAGED" : "PACKAGED",
    "DISPATCHED" : "DISPATCHED",
    "DELIVERED" : "DELIVERED"
}

