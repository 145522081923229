import { Container } from "@mui/material";

import Page from "../../../components/Page";
import { CoachClientList } from "./CoachClientList";

export const CoachClient = () => {
    return (
        <Page title="Clients">
            <Container size="xl">
                <CoachClientList />
            </Container>
        </Page>
    );
}