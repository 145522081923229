import { useState, useEffect } from "react";
import { Box, Button, Card, Grid, Modal, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { filter } from 'lodash';
import { useSelector,useDispatch } from "react-redux";
import { useCookies } from "react-cookie";

import ExportCSV from "../../../components/ExportCSV";
import SearchNotFound from "../../../components/SearchNotFound";
import Scrollbar from "../../../components/Scrollbar";
import FilterContainer from "./FilterContainer";
import { getComparator } from "../../../utils/sort_helper";
import { StaffDetail } from "./StaffDetail";
import StaffListHead from "./StaffListHead";
import { AddStoreStaff } from "./AddStoreStaff";
import { deleteStoreStaff, fetchStoreStaff } from "../../../data/network/store/staff";
import { getActiveStoreId, getStoreStaff } from "../../../store/partner_store/reducer";
import { fetchStoreRoles } from "../../../data/network/store/role";


export const StoreStaffList = () => {

    const [openModal, setModalOpen] = useState(false);
    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [open, setOpen] = useState(false);  
    const [orderBy, setOrderBy] = useState('id');
    const [selectedRow, setSelectedRow] = useState(null);
    const [filterName, setFilterName] = useState('');
    const [filter, setFilter] = useState('');
    const [count, setCount] = useState(10);

    const [addStaffOpen, setAddStaffOpen] = useState(false);
    const [addStaffDetails, setAddStaffDetails] = useState(null);

    
    const dispatch = useDispatch();
    const [cookies] = useCookies(['mobile', 'access_token', 'session']);
    
    const STAFFLIST = useSelector((state) => getStoreStaff(state));
    const storeId = useSelector((state) => getActiveStoreId(state));

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const STAFFLIST1 = {
        'data' : [
            {
                'id' : 1,
                'name' : 'User 1',
                'mobile' : '8768768764',
                'role' : 'Chef',
                'is_active' : true
            },
            {
                'id' : 4,
                'name' : 'User 4',
                'mobile' : '9000000011',
                'role' : 'Chef',
                'is_active' : true
            },
            {
                'id' : 3,
                'name' : 'User 3',
                'mobile' : '7788778877',
                'role' : 'Chef',
                'is_active' : true
            },
        ],
        'pagination' : {
            'total_pages' : 5,
            'per_page': 5,
        },
        'filter_params' : [
            {
                'key' : 'name',
                'label' : 'Name',
                'search_type': 'text'
            }
        ]
    }

    const TABLE_HEAD = [
        { id: 'id', label: '#', alignRight: false },
        { id: 'name', label: 'Name', alignRight: false },
        { id: 'mobile', label: 'Mobile', alignRight: false },
        { id: 'email', label: 'Email', alignRight: false },
        { id: 'role', label: 'Role', alignRight: false },
        { id: 'action', label: 'Action', alignRight: false },
    ];

    const headers = [
        { label: "Id", key: "id" },
        { label: "Name", key: "name" },
        { label: "Mobile", key: "mobile" },
        { label: "Email", key: "email" },
        { label: "Status", key: "status" },
        { label: "Role", key: "role" }
    ];
    

    function staffData() {
        const data = [];
        if (STAFFLIST && STAFFLIST.data) {
          for (let i = 0; i < STAFFLIST.data.length; i += 1) {

            const roles = [];
            for (let j = 0; j < STAFFLIST.data[i].roles.length; j+=1) {
                roles.push(STAFFLIST.data[i].roles[j].display_name);
            }

            data[i] = {
              'id': `${STAFFLIST.data[i].id}`,
              'name': `${STAFFLIST.data[i].name}`,
              'mobile': `${STAFFLIST.data[i].mobile_no}`,
              'email': `${STAFFLIST.data[i].email}`,
              'status': STAFFLIST.data[i].is_active ? 'active' : 'inactive',
              'role': `${roles.join(', ')}`,
            };
          }
        }
        return data;
    }
    

    function applySortFilter(array, comparator, query) {
        if (array !== undefined && array != null && array.length > 0) {
          const stabilizedThis = array.map((el, index) => [el, index]);
          stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
          });
          if (query) {
            return filter(array, (_data) => _data.name && _data.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
          }
          return stabilizedThis.map((el) => el[0]);
        }
      
        return [];
    }      

    const handleRequestSort = (_event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
          const newSelecteds = STAFFLIST.data.map((n) => n.id);
          setSelected(newSelecteds);
          return;
        }
        setSelected([]);
    };
    
    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };

    const handleFilterApiCall = (filterBy) => {
        setFilter(filterBy);
        setPage(1);
        // console.log(filterBy);
        fetchStoreStaff(dispatch, cookies, storeId, 1, count, filterBy);
    }


    const filteredStaffList = STAFFLIST ? applySortFilter(STAFFLIST.data, getComparator(order, orderBy), filterName) : [];
    const isStaffNotFound = filteredStaffList.length === 0;  

    const handleAddStaffClick = () => {
        
        setAddStaffDetails(null);
        setAddStaffOpen(true);

    }

    const handleDelete = (id) => {
        const payload = {
          store_id: storeId,
          users: [
            {
              id
            },
          ]
        }
        deleteStoreStaff(dispatch, cookies, storeId, payload);
        handleClose();
    };
    
    useEffect(() => {
        fetchStoreStaff(dispatch, cookies,storeId, 1, count, filter);
        fetchStoreRoles(dispatch, cookies,storeId);

    },[]);


    return (
        <>
            <Grid container justifyContent="space-between" sx={{ pb: 2 }}>
                <Typography variant="h4" gutterBottom>
                    Staff Management
                </Typography>
                <Grid>
                    <Button variant="contained" onClick={handleAddStaffClick}>
                        <Typography variant="body1">Add Staff</Typography>
                    </Button>
                    <StaffDetail data={addStaffDetails} visible={addStaffOpen} setOpen={setAddStaffOpen} editable create />

                    <ExportCSV csvHeaders={headers}
                        csvData={staffData()}
                        fileName="staff.csv" />
                </Grid>
            </Grid>
            {STAFFLIST && <FilterContainer filterKey={STAFFLIST.filter_params} filterMethod={handleFilterApiCall} />}

            <Card className="glass-box">
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                    {
                        STAFFLIST && 
                        STAFFLIST.data &&
                        STAFFLIST.data.length > 0
                        ?
                        <Table size='small'>
                            <StaffListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={STAFFLIST.data.length}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                            />
                            <TableBody>
                            {STAFFLIST && STAFFLIST.data.map((row) => {
                                // const { id, date, org, meal_name, meal_qty, scheduled_for, delivery_dt } = row;

                                const id = row.id;
                                const name = row.name;
                                const mobile = row.mobile_no;
                                const email = row.email;
                                const status = row.is_active;

                                const roles = [];
                                for (let j = 0; j < row.roles.length; j+=1) {
                                    roles.push(row.roles[j].display_name);
                                }
                                const isItemSelected = selected.indexOf(id) !== -1;

                                return (
                                <TableRow
                                    hover
                                    key={id}
                                    tabIndex={-1}
                                    role="checkbox"
                                    selected={isItemSelected}
                                    aria-checked={isItemSelected}
                                >
                                    <TableCell component="th" scope="row" padding="none">
                                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                        <Typography variant="subtitle2" noWrap>
                                        {id}
                                        </Typography>
                                    </Stack>
                                    </TableCell>
                                    <TableCell align="center">{name} </TableCell>
                                    <TableCell align="center">{mobile}</TableCell>
                                    <TableCell align="center">{email}</TableCell>
                                    <TableCell align="center">{roles.join(', ')}</TableCell>
                                    {/* <TableCell align="center">{status ? 'Active' : 'Inactive'}</TableCell> */}

                                    <TableCell align="center">
                                        <Button style={{ padding: '2px 10px', minWidth: 'auto', fontSize: '12px', marginRight: '5px' }} size='small' color='info' variant='contained'
                                            onClick={() => {
                                            setOpen(true);
                                            setSelectedRow(row);
                                        }}
                                        >View</Button>

                                        <StaffDetail data={selectedRow} visible={open} setOpen={setOpen} editable={false} create={false} />
                                        
                                        <Button style={{ padding: '2px 10px', minWidth: 'auto', fontSize: '12px', marginRight: '5px' }} size='small' color='error' variant='contained'
                                            onClick={() => {
                                            handleOpen();
                                        }}
                                        >Delete</Button>
                                        

                                        <Modal
                                            open={openModal}
                                            onClose={handleClose}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                            >
                                            <Box sx={style}>
                                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                                Are you sure you want to delete ?
                                                </Typography>
                                                <Box id="modal-modal-description" sx={{ mt: 2 }}>
                                                <Button sx={{ mr: 1 }} color='error' variant='contained'
                                                    onClick={() => handleDelete(id)}
                                                >
                                                    Delete</Button>
                                                <Button variant='contained' onClick={handleClose}>Cancel</Button>
                                                </Box>

                                            </Box>
                                        </Modal>
                                    </TableCell>
                                </TableRow>
                                );
                            })}
                            </TableBody>

                            {isStaffNotFound && (
                            <TableBody>
                                <TableRow>
                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                    <SearchNotFound searchQuery={filterName} />
                                </TableCell>
                                </TableRow>
                            </TableBody>
                            )}
                        </Table>
                        : <Typography variant='h6' sx={{display: 'flex', justifyContent: 'center'}}>No Data Available</Typography>
                    }
                    </TableContainer>
                </Scrollbar>
            </Card>
            {STAFFLIST && <Pagination count={STAFFLIST?.pagination?.total_pages} variant="outlined" shape="rounded" onChange={handleChangePage} />}

        </>

    );
}