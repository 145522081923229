import {Buffer} from 'buffer';
import { useNavigate } from 'react-router-dom';

import { API_GET_SESSION,API_CREATE_SESSION, API_GENERATE_ACCESS_TOKEN } from "../endpoints";
import axios from '../../../utils/axios-configure';

import {
    setAccessToken,
    setSessionDetails,
    setLoginStatus,
    setUserName
} from '../../../store/user/reducer';

import {
  clearOTPState
} from '../../../store/otp/reducer';
import { fetchUserDetails } from '../user/api';
import { setLoadingStatus } from '../../../store/layout/reducer';

export const getSession = (dispatch, cookies) => {
  dispatch(setLoadingStatus({status: true}));  
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    if(accessToken != null){
        axios({
            method: "get",
            url: API_GET_SESSION,
            headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
          })
          .then((response) => {
            // handle success
            if(response.status === 200){
                dispatch(setSessionDetails({data: response.data.data}));
                if(response.data.data != null){
                  dispatch(setLoginStatus({data: true}));
                  dispatch(setUserName({data: response.data.data.user_first_name}));
                }
            }else{
              dispatch(setSessionDetails({data: {}}));
              dispatch(setLoginStatus({data: false}));
            } 
            dispatch(setLoadingStatus({status: false}));  
            return true;
          })
          .catch((err) => {
            dispatch(setSessionDetails({data: {}}));
            dispatch(setLoginStatus({data: false}));
            dispatch(setLoadingStatus({status: false}));  
            // console.log(response);
          });
    }else{
        dispatch(setSessionDetails({data: {}}));
        dispatch(setLoginStatus({data: false})); 
        dispatch(setLoadingStatus({status: false}));
      }
}

export const createSession = (dispatch, cookies, setCookie, accessToken, remember) => {
  dispatch(setLoadingStatus({status: true}));  

  if(accessToken != null){
    const data = {};
    if(remember){
      data.remember_me = true
    }

    axios({
      method: "post",
      url: API_CREATE_SESSION,
      data,
      headers: { "Content-Type": "multipart/form-data",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => {

      if(response.status === 200){
        dispatch(setSessionDetails({data: response.data.data}));
        setCookie('session', response.data.data.session_id, { path: '/' });
        dispatch(setLoginStatus({data: true}));
        dispatch(clearOTPState({data: {}}));

      }else{
        dispatch(setLoginStatus({data: false}));
        dispatch(clearOTPState({data: {}}));

      }
      dispatch(setLoadingStatus({status: false}));  
    })
    .catch((response) => {

      dispatch(setLoginStatus({data: false}));
      dispatch(clearOTPState({data: {}}));
      dispatch(setLoadingStatus({status: false}));  

    });
  }else{
    dispatch(setLoginStatus({data: false}));
    dispatch(clearOTPState({data: {}}));
    dispatch(setLoadingStatus({status: false}));  

  }

}

export const generateAccessToken = (dispatch, cookies, setCookie, userId, clientId, clientSecret, remember) => {
  dispatch(setLoadingStatus({status: true}));  
  const accessTokenUrl = API_GENERATE_ACCESS_TOKEN;

  const bodyFormData = new FormData();
  bodyFormData.append('grant_type', 'password');
  bodyFormData.append('scope', 'partner');
  bodyFormData.append('username', userId);
  bodyFormData.append('password', 'valid');

  const username = clientId;
  const password = clientSecret;

  const token = `${username}:${password}`;
  const encodedToken = Buffer.from(token).toString('base64');

  axios({
    method: "post",
    url: accessTokenUrl,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data",'Authorization': `Basic ${ encodedToken}` },
  })
  .then((accessTokenResponse)  => {
    if(accessTokenResponse.status === 200){
      setCookie("access_token",accessTokenResponse.data.access_token, { path: '/' });
      createSession(dispatch, cookies, setCookie, accessTokenResponse.data.access_token, remember);
      fetchUserDetails(dispatch, cookies);
    }else{
      setCookie("access_token","", { path: '/' });
    }
    dispatch(setLoadingStatus({status: false}));  
  })
  .catch((response) => {
    setCookie("access_token","", { path: '/' });
    dispatch(setLoadingStatus({status: false}));  
  });        
}

export const getAccessTokenFromCookie = (dispatch, cookies) => {

    let accessToken = null; 
    if(cookies.access_token != null && cookies.access_token !== 'null' && cookies.access_token !== ''){
      // if(cookies.session != null && cookies.session !== 'null' && cookies.session !== ''){
        accessToken = cookies.access_token;
        dispatch(setAccessToken({data: accessToken}));
    }

    return accessToken;
};