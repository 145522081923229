import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel, Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from "react-redux";

import { getPartnerOTPSentStatus, getPartnerOTPVerificationStatus,getPartnerId, getClientId, getClientSecret,clearPartnerOTPState } from '../../../store/otp/reducer'; 
import { sendPartnerOTP, verifyPartnerOTP } from '../../../data/network/otp/api';
import { generateAccessToken, getSession } from '../../../data/network/session/api';
import { getLoginStatus, getUser, setActiveRole } from '../../../store/user/reducer';

// component
import Iconify from '../../../components/Iconify';
import { ROLES, SNACKBAR_SEVERITY } from '../../../constants/app_constants';
import { fetchUserDetails } from '../../../data/network/user/api';
import { OTP_ERROR } from '../../../constants/message_constants';
import { setSnackbarMessage, setSnackbarVisibilityStatus } from '../../../store/layout/reducer';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showOTPContainer, setShowOTPContainer] = useState(false);
  const [otpValue, setOTPValue] = useState(null);
  const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
  const dispatch = useDispatch();
    
 
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
 
  const LoginSchema = Yup.object().shape({
    mobile: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
    .min(10, "Phone number is too short")
    .max(10, "Phone number is too long"),
    // email('Please enter valid mobile number').required('Mobile number is required'),
    otp: Yup.string()
      .matches(/^[0-9]{4}$/gi, "Enter valid OTP")
      // .length(4, 'Enter valid OTP')
      .required('OTP is required') 
  });

  const formik = useFormik({
    initialValues: {
      mobile: cookies.mobile,
      otp: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {

      // call verify OTP Api here

      navigate('/dashboard', { replace: true });
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  const otpSent = useSelector((state) => getPartnerOTPSentStatus(state));
  const otpVerified = useSelector((state) => getPartnerOTPVerificationStatus(state));

  const partnerId = useSelector((state) => getPartnerId(state));
  const clientId = useSelector((state) => getClientId(state));
  const clientSecret = useSelector((state) => getClientSecret(state));
  const isLoggedIn = useSelector((state) => getLoginStatus(state));
  const userDetails = useSelector((state) => getUser(state));

  useEffect(() => {
    dispatch(clearPartnerOTPState());
    getSession(dispatch, cookies);
  },[]);

  useEffect(() => {
     
    if(otpSent){
      setShowOTPContainer(true);
    }else{
      setShowOTPContainer(false);
    }
  },[otpSent]);

  useEffect(() => {
    if(otpVerified){
      generateAccessToken(dispatch, cookies, setCookie, partnerId, clientId, clientSecret, formik.values.remember);
    }
  },[otpVerified]);

  useEffect(() => {
    if(isLoggedIn){
      fetchUserDetails(dispatch, cookies);
      dispatch(clearPartnerOTPState())
    }else{ 
      navigate('/login');
    }
  },[isLoggedIn]);

  useEffect(() => {
    if(isLoggedIn){

      if(userDetails && Object.keys(userDetails).length !== 0 && userDetails.roles && userDetails.roles[0] && userDetails.roles !== null){
         
        dispatch(setActiveRole({role: userDetails.roles[0]}));

        Object.entries(ROLES).map(([key, value]) => {
          console.log(value);
          console.log(userDetails.roles[0]);
          if(value.name === userDetails.roles[0].name){
            navigate(value.path);
          } 
 
          return true;
        });
 
      }else{ 
        navigate('/contact-admin');
      }
    }

  },[userDetails]);

  const handleSendOTPClick = () => {
    if(cookies.mobile && cookies.mobile.length === 10){
      sendPartnerOTP(dispatch, cookies, cookies.mobile);
    }else{
      dispatch(setSnackbarMessage({'message': OTP_ERROR.INVALID_MOBILE , 'severity': SNACKBAR_SEVERITY.ERROR}));
      dispatch(setSnackbarVisibilityStatus({'status': true }));
    }
      // call send OTP Api here
  }

  const handleVerifyOTPClick = () => {
    // call verify OTP Api here
    if(otpValue && otpValue.length === 4){
      verifyPartnerOTP(dispatch, cookies, cookies.mobile, otpValue);
    }else{
      dispatch(setSnackbarMessage({'message': OTP_ERROR.INVALID_OTP , 'severity': SNACKBAR_SEVERITY.ERROR}));
      dispatch(setSnackbarVisibilityStatus({'status': true }));
    }
}

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="number"
            label="Mobile Number"
            onKeyUp={e => {
              setCookie("mobile",e.target.value, { path: '/' })
            }}
            {...getFieldProps('mobile')}
            error={Boolean(touched.mobile && errors.mobile)}
            helperText={touched.mobile && errors.mobile}
          />
        { 
          showOTPContainer  
          ? <TextField
            fullWidth
            autoComplete="current-otp"
            type="text"
            // type={showPassword ? 'number' : 'password'}
            label="OTP"
            onKeyUp={e => {
              setOTPValue(e.target.value);
            }}
            {...getFieldProps('otp')}
            InputProps={{
               
            }}
            error={Boolean(touched.otp && errors.otp)}
            helperText={touched.otp && errors.otp}
          />
          : <Container />  }
        </Stack>

        { showOTPContainer  ? 
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="#" underline="hover" onClick={() => handleSendOTPClick()}>
            Resend OTP?
          </Link>
        </Stack>
        : <Container />  }

        { !showOTPContainer   ? 
        <LoadingButton fullWidth size="large" onClick={() => handleSendOTPClick()} variant="contained" loading={isSubmitting}>
          Send OTP
        </LoadingButton>
          : <Container />  }

        { showOTPContainer   ? 
        <LoadingButton fullWidth size="large" onClick={() => handleVerifyOTPClick()}variant="contained" loading={isSubmitting}>
          Verify OTP
        </LoadingButton> 
          : <Container />  }   

      </Form>
    </FormikProvider>
  );
}
