import { useState, useEffect,useRef } from "react";
import { Box, Button, Paper, Card, Grid, Modal, Stack, TextField, styled, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Collapse, Stepper, Step, StepLabel, TablePagination, TableFooter } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useSelector,useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { subDays } from "date-fns";
import { getIcon } from "@iconify/react";

import CreateMealForm from './CreateMealForm';
import ExportCSV from "../../../components/ExportCSV";
import Scrollbar from "../../../components/Scrollbar";
import FilterContainer from "./FilterContainer";
import { StoreMenuDetail } from "./StoreMenuDetail";
import { formatDate } from "../../../utils/date";
import { setMealDetailModalVisibility, setSelectedMeal, getMealDetailModalVisibility, resetSelectedMeal } from "../../../store/meal/reducer";
import { fetchStoreMenu, deleteStoreMenu, fetchStoreMenuStats, fetchStoreDishList } from "../../../data/network/store/menu";
import { getActiveStoreId, getStoreMenu, getStoreMenuStats } from "../../../store/partner_store/reducer";

export const StoreMenuList = () => {

    const StackStyled = styled(Stack)(({ theme }) => ({
        background: '#fff',
        padding: '20px',
        borderRadius: '10px',
        [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',
            gap: 20,
            flexDirection: 'column'
        },
        '& .MuiBox-root': {
            [theme.breakpoints.down('md')]: {
                marginLeft: 0,
                border: 'none',
                flexWrap: 'wrap',
            },
            display: 'flex',
            alignItems: 'center',
            flexBasis: '25%',
            '&:not(:last-child)': {
                borderRight: '1px solid #ccc',
                [theme.breakpoints.down('md')]: {
                    borderRight: 'none',
                },
            },
            '& .icon': {
                fontSize: '3rem',
                marginRight: '20px',
                '& .MuiBox-root': {
                    border: 'none',
                }
            },
            '& .details': {
                display: 'flex',
                flexDirection: 'column',
                '& .MuiTypography-caption': {
                    textTransform: 'uppercase',
                }
            }
        },
        '& .MuiOutlinedInput-input': {
            padding: '10px 14px',
        }
    }))

    const headers = [
        { label: "Id", key: "id" },
        { label: "Name", key: "name" },
        { label: "Display Name", key: "display_name" },
        { label: "SKU", key: "sku" },
        { label: "In Stock", key: "in_stock" },
        { label: "Primary Unit", key: "primary_unit" },
        {label: "Min Threshold Stock", key: "min_threshold_stock" },
        // { label: "Status", key: "status" },
    ];
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [openModal, setModalOpen] = useState(false);
    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(10);
    const [open, setOpen] = useState(false);  
    const [selectedRow, setSelectedRow] = useState(null);
    const [filter, setFilter] = useState('');

    const [addMenuOpen, setAddMenuOpen] = useState(false);
    const [addMenuDetails, setAddMenuDetails] = useState(null);

    const STOCKLIST = useSelector((state) => getStoreMenu(state));
    const STOCKSTATS = useSelector((state) => getStoreMenuStats(state));
    
    const storeId = useSelector((state) => getActiveStoreId(state));
    const isMealDetailModalVisible = useSelector((state) => getMealDetailModalVisibility(state));

    const tableRef = useRef();
    const _paginationRef = useRef();

    const dispatch = useDispatch();
    const [cookies] = useCookies(['mobile', 'access_token', 'session']);

    const handleSearch = () => {
        setPage(1);
        fetchStoreMenu(dispatch, cookies, storeId, 1, count, filter);
        fetchStoreMenuStats(dispatch, cookies, storeId, filter);
    }

    const handleAddMenuClick = () => {
        setAddMenuDetails(null);
        setAddMenuOpen(true);
    }

    const handleDelete = () => {
        const id = selectedRow.id;
        const payload = {
          "item_id" : id
        }
        deleteStoreMenu(dispatch, cookies, storeId, payload);
        handleClose();
    };

    const showMealDetailModal = () => {
        dispatch(setMealDetailModalVisibility({data: true}));
    };

    const handleMealDetailsClose = () => {
        dispatch(setMealDetailModalVisibility({data: false}));
      }

    function Row(props) {
        const { row } = props;
        const [rowExpanded, setRowExpanded] = useState(false);
      
        const id = row.id;
        const displayName = row.display_name;
        const name = row.name;
        const owner = row.owner;
        const description = row.description;
        const primaryUnit = row.primary_unit;
        const status = row.status;
        const minimumThresholdStock = row.minimum_threshold_stock;

        return (
          <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
              
                <TableCell component="th" scope="row">
                    {id}
                </TableCell>
                <TableCell align="center">
                    {displayName}
                    {/* <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setRowExpanded(!rowExpanded)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton> */}
                </TableCell>
                <TableCell align="center">
                    <Button style={{ padding: '2px 10px', minWidth: 'auto', fontSize: '12px', marginRight: '5px' }} size='small' color='info' variant='contained'
                        onClick={() => {
                        setOpen(true);
                        setSelectedRow(row);
                    }}
                    >Edit</Button>                    
                    <StoreMenuDetail data={selectedRow} visible={open} setOpen={setOpen} editable create={false} />
                    <Button style={{ padding: '2px 10px', minWidth: 'auto', fontSize: '12px', marginRight: '5px' }} size='small' color='info' variant='contained'
                        onClick={() => {
                            dispatch(setSelectedMeal({data: row}));
                            showMealDetailModal();
                        // setOpen(true);
                        // setSelectedInvoice(row)
                        }}
                    >View</Button>
                </TableCell>
                <Modal
                    open={openModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Are you sure you want to delete ?
                        </Typography>
                        <Box id="modal-modal-description" sx={{ mt: 2 }}>
                            <Button sx={{ mr: 1 }} color='error' variant='contained'
                            onClick={() => handleDelete()}
                            >
                            Delete</Button>
                            <Button variant='contained' onClick={handleClose}>Cancel</Button>
                        </Box>

                    </Box>
                </Modal>
            </TableRow>
            {/* <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                <Collapse in={rowExpanded} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1, backgroundColor: '#ffffffcc', padding:1 }}>
                    <Typography variant="body1">{description}</Typography>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow> */}
          </>
        );
    }

    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
        fetchStoreMenu(dispatch, cookies,storeId, newPage, count, filter);
    };

    const handleFilterApiCall = (filterBy) => {
        setFilter(filterBy);
        setPage(1);
        fetchStoreMenu(dispatch, cookies,storeId,1, count, filterBy);
        fetchStoreMenuStats(dispatch, cookies, storeId, filterBy);
    }

    const stockData = () => {
        const data = [];
        if (STOCKLIST && STOCKLIST.data) {
          for (let i = 0; i < STOCKLIST.data.length; i += 1) {
            data[i] = {
              'id': `${STOCKLIST.data[i].id}`,
              'name': `${STOCKLIST.data[i].name}`,
              'display_name': STOCKLIST.data[i].display_name,
            //   'description': STOCKLIST.data[i].description,
              'sku': STOCKLIST.data[i].sku,
              'in_stock': STOCKLIST.data[i].in_stock,
              'primary_unit': STOCKLIST.data[i].primary_unit,
              'min_threshold_stock': STOCKLIST.data[i].minimum_threshold_stock,
            };
          }
        }
        return data;
    }

    useEffect(() => {
        fetchStoreMenu(dispatch, cookies,storeId, page, count, filter);
        fetchStoreMenuStats(dispatch, cookies, storeId, filter);
        fetchStoreDishList(dispatch, cookies, storeId, 1, 19, filter);
    },[]);

    return (
        <>
            <Box sx={{ pb: 1, pt:2 }} />
            <StackStyled className="glass-box" direction='row' spacing={2}>
                {STOCKSTATS && STOCKSTATS.data && STOCKSTATS.data.map((ele) => (
                    <Box key={ele?.label}>
                        <div className='icon'>
                            {getIcon(ele?.label)}
                        </div>
                        <div className='details'>
                            <Typography variant='h6'>{ele?.value}</Typography>
                            <Typography variant='caption'>{ele?.label}</Typography>
                        </div>
                    </Box>
                )
                )}
            </StackStyled>
            <Box sx={{ pb: 1, pt:2 }} />
            <Grid container justifyContent="space-between" sx={{ pb: 2 }}>
                <Typography variant="h4" gutterBottom>
                    Menu
                </Typography>
                {/* <ImportCSV csvHeaders={headers}
                    csvData={orderData()}
                    fileName="orders.csv" /> */}
                <Grid>
                    <Button variant="contained" onClick={() => {
                            dispatch(resetSelectedMeal);
                            showMealDetailModal();}}>
                        <Typography variant="body1">Add Menu</Typography>
                    </Button>
                    <StoreMenuDetail data={addMenuDetails} visible={addMenuOpen} setOpen={setAddMenuOpen} editable create />
                    <ExportCSV csvHeaders={headers}
                        csvData={stockData()}
                        fileName="stock.csv" />
                </Grid>
            </Grid>
            {STOCKLIST && <FilterContainer filterKey={STOCKLIST.filter_params} filterMethod={handleFilterApiCall} />}

            <Card className="glass-box">
                <Scrollbar>

                <TableContainer className="glass-box" >
                    {
                        STOCKLIST &&
                        STOCKLIST.data &&
                        STOCKLIST.data.length > 0
                        ?
                            <Table className="glass-box" ref={tableRef} size="small" aria-label="collapsible table">
                                <TableHead className="glass-box">
                                    <TableRow>
                                        <TableCell />
                                        <TableCell align="center">Name</TableCell>
                                        <TableCell align="center">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="glass-box">
                                {STOCKLIST && STOCKLIST.data &&
                                STOCKLIST.data.length > 0 && STOCKLIST.data.map((row) => (
                                    <Row key={row.id} row={row} />
                                ))
                                }
                                </TableBody>
                            </Table>
                        : <Typography variant='h6' sx={{display: 'flex', justifyContent: 'center'}}>No Data Available</Typography>
                    }   
                    </TableContainer>

                </Scrollbar>
            </Card>
            {STOCKLIST && <Pagination ref={_paginationRef} page={page} count={STOCKLIST?.pagination?.total_pages} variant="outlined" shape="rounded" onChange={handleChangePage} />}

            <Modal
                open={isMealDetailModalVisible}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{zIndex:999}}
                // sx={{zIndex: 999, top:'100px', left:'100px', height:'100%', width:'100%', background:'#fff', borderRadius:2, margin:'0'}}
            >
            <Box>
                <CreateMealForm handleClose={handleMealDetailsClose} />  
            </Box>
          </Modal>
        </>

    );
}