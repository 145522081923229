import { CLIENT_MEAL_SCHEDULE_EDIT_STATUS, ONBOARDING_FORM_STEPS, ONBOARDING_STATUS, SNACKBAR_SEVERITY, STATUS } from '../../../constants/app_constants';
import { setOnboardingCurrentStep, setOnboardingStatus } from '../../../store/onboarding/reducer';
import { setActiveClient, setClientDetailSubmitted, setClientList, setClientMealSchedule, 
  setClientMealScheduleEditStatus, setClientSubscriptionDetails, setClientMealOptions, setClientActivitySchedule, setActiveClientAddress, setClientReport, setActiveClientLunchAddress, setActiveClientDinnerAddress
} from '../../../store/coach/reducer';
import axios from '../../../utils/axios-configure';
import { API_CLIENT_SUBSCRIBE_PLAN, API_FETCH_CLIENT_DETAILS, API_GET_CLIENT_LIST, API_FETCH_CLIENT_ADDRESS, API_ADD_CLIENT_ADDRESS,
   API_GET_CLIENT_SUBSCRIPTION, API_UPDATE_CLIENT_DETAILS, API_CONFIRM_CLIENT_SUBSCRIPTION, 
   API_GET_CLIENT_MEAL_SCHEDULE, API_GET_CLIENT_MEAL_OPTIONS, API_POST_CLIENT_MEAL_SCHEDULE, API_GET_CLIENT_ACTIVITY_SCHEDULE, API_POST_CLIENT_ACTIVITY_SCHEDULE, API_DELETE_CLIENT_ACTIVITY_SCHEDULE, API_DELETE_CLIENT_MEAL_SCHEDULE, API_UPDATE_CLIENT_MEAL_SCHEDULE, API_GET_CLIENT_REPORT, API_PAUSE_CLIENT_SUBSCRIPTION, API_RESUME_CLIENT_SUBSCRIPTION, API_GET_CLIENT_SUBSCRIPTION_CART, API_POST_CHECK_CLIENT_SUBSCRIPTION
} from "../endpoints";

import {getAccessTokenFromCookie} from '../session/api'
import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../../store/layout/reducer';
import { ACTIVITY_SCHEDULE, ACTIVITY_SCHEDULE_DELETE, CLIENT_SUBSCRIPTION_STATUS, MEAL_SCHEDULE_ADD, MEAL_SCHEDULE_DELETE, MEAL_SCHEDULE_UPDATE, PAUSE_CLIENT_SUBSCRIPTION, RESUME_CLIENT_SUBSCRIPTION } from '../../../constants/message_constants';

export const fetchClientList = (dispatch, cookies, page=1, count=10, filterBy = { key: '', value: '' }) => {
  // partnerId = 3;
    dispatch(setLoadingStatus({status: true}));  

    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    if(accessToken != null){
      const url = `${API_GET_CLIENT_LIST}?page=${page}&count=${count}`
 
      axios({
        method: "get",
        url,
        headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
      })
      .then((response) => {
        // handle success
        if(response.status === 200){
            dispatch(setClientList({data: response.data}));
        }else{
          dispatch(setClientList({data: null}));
        }
        dispatch(setLoadingStatus({status: false}));
        return true;
      })
      .catch((err) => {
        dispatch(setClientList({data: null})); 
        dispatch(setLoadingStatus({status: false}));  
      });
    }else{
      dispatch(setClientList({data: null}));
      dispatch(setLoadingStatus({status: false}));  
    }
}

export const fetchClientSubscription = (dispatch, cookies, clientId) => {
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    dispatch(setLoadingStatus({status: true}));  
    if(accessToken != null){
        axios({
            method: "get",
            url: `${API_GET_CLIENT_SUBSCRIPTION}?user_id=${clientId}`,
            headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
          })
          .then((response) => {
            // handle success
            if(response.status === 200){
                dispatch(setClientSubscriptionDetails({data: response.data.data}));
            }else{
              dispatch(setClientSubscriptionDetails({data: {}}));
            }
            dispatch(setLoadingStatus({status: false}));  
            return true;
          })
          .catch((err) => {
            dispatch(setClientSubscriptionDetails({data: {}}));
            // console.log(response);
            dispatch(setLoadingStatus({status: false}));  
          });
    }else{
        dispatch(setClientList({data: null}));
        dispatch(setLoadingStatus({status: false}));  
      }
}

export const fetchClientSubscriptionCart = (dispatch, cookies, data) => {
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  dispatch(setLoadingStatus({status: true}));  
  if(accessToken != null){
      axios({
          method: "post",
          url: `${API_GET_CLIENT_SUBSCRIPTION_CART}`,
          data,
          headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
        })
        .then((response) => {
          // handle success
          if(response.status === 200){
            dispatch(setClientSubscriptionDetails({data: response.data.data}));
          }else{
           dispatch(setClientSubscriptionDetails({data: {}}));
          }
          dispatch(setLoadingStatus({status: false}));  
          return true;
        })
        .catch((err) => {
         dispatch(setClientSubscriptionDetails({data: {}}));
          // console.log(response);
          dispatch(setLoadingStatus({status: false}));  
        });
  }else{
      dispatch(setClientList({data: null}));
      dispatch(setLoadingStatus({status: false}));  
    }
}

export const updateClientDetails = (dispatch, cookies, data) => {
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  dispatch(setLoadingStatus({status: true}));  
  if(accessToken != null){
      axios({
          method: "post",
          url: `${API_UPDATE_CLIENT_DETAILS}`,
          data,
          headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
        })
        .then((response) => {
          // handle success
          if(response.status === 200){
              dispatch(setClientDetailSubmitted({status: true}));

              dispatch(setOnboardingCurrentStep({step: ONBOARDING_FORM_STEPS.PLAN_LIST}));

          }else{
            dispatch(setClientDetailSubmitted({status: false}));
          }
          dispatch(setLoadingStatus({status: false}));  
          return true;
        })
        .catch((err) => {
          dispatch(setClientDetailSubmitted({status: false}));
          dispatch(setLoadingStatus({status: false}));  
          // console.log(response);
        });
  }else{
    dispatch(setLoadingStatus({status: false}));  
    dispatch(setClientDetailSubmitted({status: false}));
  }
}

export const fetchClientDetails = (dispatch, cookies, userId) => {
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  dispatch(setLoadingStatus({status: true}));  
  if(accessToken != null){
    axios({
      method: "get",
      url: `${API_FETCH_CLIENT_DETAILS}?user_id=${userId}`,
      headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => {
      // handle success
      if(response.status === 200){ 
        dispatch(setActiveClient({data:response.data.data}));
      } 
      dispatch(setLoadingStatus({status: false}));  
      return true;
    })
    .catch((err) => { 
      dispatch(setLoadingStatus({status: false}));  

    });
  }
    dispatch(setLoadingStatus({status: false}));  
  }

export const fetchClientAddress = (dispatch, cookies, userId) => {
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  dispatch(setLoadingStatus({status: true}));  
  if(accessToken != null){
      axios({
          method: "get",
          url: `${API_FETCH_CLIENT_ADDRESS}?user_id=${userId}`,
          headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
        })
        .then((response) => {
          // handle success
          if(response.status === 200){ 
            dispatch(setActiveClientAddress({data:response.data.data}));
            dispatch(setLoadingStatus({status: false}));  
          }else {
            dispatch(setLoadingStatus({status: false}));  
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus({status: false}));  
        });
  }
  dispatch(setLoadingStatus({status: false}));  
}

export const addClientAddress = (dispatch, cookies, data, type) => {
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  dispatch(setLoadingStatus({status: true}));   
  if(accessToken != null){
      axios({
          method: "post",
          url: `${API_ADD_CLIENT_ADDRESS}`,
          data,
          headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
        })
        .then((response) => {
          // handle success
          if(response.status === 200){ 

            if(type === 0){
              dispatch(setActiveClientLunchAddress({address: response.data.data.address_id}));
              dispatch(setActiveClientDinnerAddress({address: response.data.data.address_id}));
            }else{
              dispatch(setActiveClientLunchAddress({address: response.data.data.address_id}));
              dispatch(setActiveClientDinnerAddress({address: response.data.data.address_id}));
            }

            fetchClientAddress(dispatch, cookies, data.user_id);

            fetchClientSubscriptionCart(dispatch, cookies, {
              'user_id' : data.user_id,
              'lunch_address_id' : response.data.data.address_id,
              'dinner_address_id' : response.data.data.address_id,
            });
          }else{
            dispatch(setLoadingStatus({status: false}));  
          } 
        })
        .catch((err) => { 
          dispatch(setLoadingStatus({status: false}));  

        });
  }
  dispatch(setLoadingStatus({status: false}));  
}

export const checkClientSubscription = (dispatch, cookies, data) => {
  dispatch(setLoadingStatus({status: true}));

  const accessToken = getAccessTokenFromCookie(dispatch, cookies); 
 
  if(accessToken != null){
    axios({
      method: "post",
      url : API_POST_CHECK_CLIENT_SUBSCRIPTION,
      data,
      headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => { 
      // handle success
      if(response.status === 200){

        console.log(response.data);

        if(response.data.is_subscription_exists){
          dispatch(setSnackbarMessage({'message': CLIENT_SUBSCRIPTION_STATUS.ALREADY_EXISTS , 'severity': SNACKBAR_SEVERITY.ERROR}));
          dispatch(setSnackbarVisibilityStatus({'status': true }));  
        }else{
          dispatch(setOnboardingCurrentStep({step: ONBOARDING_FORM_STEPS.PREFERENCE_SELECTION}));
        }


      }else{
        dispatch(setSnackbarMessage({'message': CLIENT_SUBSCRIPTION_STATUS.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));    
      }
      dispatch(setLoadingStatus({status: false}));  
      return true;
    })
    .catch((err) => {
      dispatch(setLoadingStatus({status: false}));  
      dispatch(setSnackbarMessage({'message': CLIENT_SUBSCRIPTION_STATUS.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
      dispatch(setSnackbarVisibilityStatus({'status': true }));    
    });
  }else{
    dispatch(setLoadingStatus({status: false}));  
    dispatch(setSnackbarMessage({'message': CLIENT_SUBSCRIPTION_STATUS.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
    dispatch(setSnackbarVisibilityStatus({'status': true }));      }
}

export const subscribeClientToPlan = (dispatch, cookies, data, updateFormStep=true) => {
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  dispatch(setLoadingStatus({status: true}));  
  if(accessToken != null){
    axios({
      method: "post",
      url: `${API_CLIENT_SUBSCRIBE_PLAN}`,
      data,
      headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => { 
      // handle success
      if(response.status === 200){
        dispatch(setClientSubscriptionDetails({data: response.data.data}));

        if(updateFormStep){
          dispatch(setOnboardingCurrentStep({step: ONBOARDING_FORM_STEPS.SUBSCRIPTION_DETAILS}));
        }

        dispatch(setLoadingStatus({status: false}));  
      }else{
        //
        dispatch(setLoadingStatus({status: false}));  
      } 
      return true;
    })
    .catch((err) => { 
      dispatch(setLoadingStatus({status: false}));  
    });
  }else{
     //
     dispatch(setLoadingStatus({status: false}));  
    }
}

export const confirmOrder = (dispatch, cookies, data) => {
  dispatch(setLoadingStatus({status: true}));  

  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  if(accessToken != null){
    axios({
      method: "post",
      url: `${API_CONFIRM_CLIENT_SUBSCRIPTION}`,
      data,
      headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => {
      console.log(response);
      // handle success
      if(response.status === 200){
        dispatch(setOnboardingStatus({status: ONBOARDING_STATUS.SUCCESS}));
        dispatch(setOnboardingCurrentStep({step: ONBOARDING_FORM_STEPS.ONBOARDING_CONFIRMATION}));
          // dispatch(setClientDetailSubmitted({status: true}));
      }else{
        // dispatch(setClientDetailSubmitted({status: false}));
        dispatch(setOnboardingStatus({status: ONBOARDING_STATUS.FAIL}));
        dispatch(setOnboardingCurrentStep({step: ONBOARDING_FORM_STEPS.ONBOARDING_CONFIRMATION}));
      }
      dispatch(setLoadingStatus({status: false}));  
      return true;
    })
    .catch((err) => {
      dispatch(setOnboardingStatus({status: ONBOARDING_STATUS.FAIL}));
      dispatch(setOnboardingCurrentStep({step: ONBOARDING_FORM_STEPS.ONBOARDING_CONFIRMATION}));
      // dispatch(setClientDetailSubmitted({status: false}));
      dispatch(setLoadingStatus({status: false}));  
       
    });
  }else{
    dispatch(setOnboardingStatus({status: ONBOARDING_STATUS.FAIL}));
    dispatch(setOnboardingCurrentStep({step: ONBOARDING_FORM_STEPS.ONBOARDING_CONFIRMATION}));
    dispatch(setLoadingStatus({status: false}));  
    // dispatch(setClientDetailSubmitted({status: false}));
  }
}

export const pauseClientSubscription = (dispatch, cookies, data) => {
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  dispatch(setLoadingStatus({status: true}));  
  if(accessToken != null){
    axios({
      method: "post",
      url: `${API_PAUSE_CLIENT_SUBSCRIPTION}`,
      data,
      headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => { 
      // handle success
      if(response.status === 200){
        // dispatch(setClientSubscriptionDetails({data: response.data.data}));
        dispatch(setSnackbarMessage({'message': PAUSE_CLIENT_SUBSCRIPTION.SUCCESS , 'severity': SNACKBAR_SEVERITY.SUCCESS}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));    

        fetchClientList(dispatch, cookies);
      }else{
        dispatch(setSnackbarMessage({'message': PAUSE_CLIENT_SUBSCRIPTION.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));    
            //
        dispatch(setLoadingStatus({status: false}));  
      } 
      return true;
    })
    .catch((err) => { 
    dispatch(setSnackbarMessage({'message': PAUSE_CLIENT_SUBSCRIPTION.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
    dispatch(setSnackbarVisibilityStatus({'status': true }));    
      dispatch(setLoadingStatus({status: false}));  
    });
  }else{
    dispatch(setSnackbarMessage({'message': PAUSE_CLIENT_SUBSCRIPTION.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
    dispatch(setSnackbarVisibilityStatus({'status': true }));    

     dispatch(setLoadingStatus({status: false}));  
    }
}

export const resumeClientSubscription = (dispatch, cookies, data) => {
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  dispatch(setLoadingStatus({status: true}));  
  if(accessToken != null){
    axios({
      method: "post",
      url: `${API_RESUME_CLIENT_SUBSCRIPTION}`,
      data,
      headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => { 
      // handle success
      if(response.status === 200){
        // dispatch(setClientSubscriptionDetails({data: response.data.data}));
        dispatch(setSnackbarMessage({'message': RESUME_CLIENT_SUBSCRIPTION.SUCCESS , 'severity': SNACKBAR_SEVERITY.SUCCESS}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));    

        fetchClientList(dispatch, cookies);
      }else{
        dispatch(setSnackbarMessage({'message': RESUME_CLIENT_SUBSCRIPTION.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));    
            //
        dispatch(setLoadingStatus({status: false}));  
      } 
      return true;
    })
    .catch((err) => { 
    dispatch(setSnackbarMessage({'message': RESUME_CLIENT_SUBSCRIPTION.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
    dispatch(setSnackbarVisibilityStatus({'status': true }));    
      dispatch(setLoadingStatus({status: false}));  
    });
  }else{
    dispatch(setSnackbarMessage({'message': RESUME_CLIENT_SUBSCRIPTION.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
    dispatch(setSnackbarVisibilityStatus({'status': true }));    

     dispatch(setLoadingStatus({status: false}));  
    }
}


export const fetchMealPlanSchedule = (dispatch, cookies, data) => {
  dispatch(setLoadingStatus({status: true}));  

  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  if(accessToken != null){
    axios({
      method: "get",
      url: `${API_GET_CLIENT_MEAL_SCHEDULE}?user_id=${data.user_id}`,
      headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => { 
      // handle success
      if(response.status === 200){
        dispatch(setClientMealSchedule({status: STATUS.SUCCESS, data: response.data.data}));
          // dispatch(setClientDetailSubmitted({status: true}));
      }else{
        dispatch(setClientMealSchedule({status: STATUS.ERROR, data: {}}));
      }
      dispatch(setLoadingStatus({status: false}));  
      return true;
    })
    .catch((err) => {
      dispatch(setClientMealSchedule({status: STATUS.ERROR, data: {}}));
      dispatch(setLoadingStatus({status: false}));  
    });
  }else{
    dispatch(setClientMealSchedule({status: STATUS.ERROR, data: {}}));
    dispatch(setLoadingStatus({status: false}));  
  }
}

export const addMealPlanSchedule = (dispatch, cookies, data) => {
  dispatch(setLoadingStatus({status: true}));  

  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  if(accessToken != null){
    axios({
      method: "post",
      url: `${API_POST_CLIENT_MEAL_SCHEDULE}`,
      data,
      headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => {
      // handle success
      if(response.status === 200){
        dispatch(setSnackbarMessage({'message': MEAL_SCHEDULE_ADD.SUCCESS , 'severity': SNACKBAR_SEVERITY.SUCCESS}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));    
        dispatch(setClientMealScheduleEditStatus({status: CLIENT_MEAL_SCHEDULE_EDIT_STATUS.INITIAL}));
        fetchMealPlanSchedule(dispatch, cookies, {user_id: data.user_id});
      }else{
        dispatch(setSnackbarMessage({'message': MEAL_SCHEDULE_ADD.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));    
        dispatch(setClientMealScheduleEditStatus({status: CLIENT_MEAL_SCHEDULE_EDIT_STATUS.INITIAL}));
      }
      dispatch(setLoadingStatus({status: false}));  
      return true;
    })
    .catch((err) => {
      dispatch(setSnackbarMessage({'message': MEAL_SCHEDULE_ADD.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
      dispatch(setSnackbarVisibilityStatus({'status': true }));    
      dispatch(setClientMealScheduleEditStatus({status: CLIENT_MEAL_SCHEDULE_EDIT_STATUS.INITIAL}));
      dispatch(setLoadingStatus({status: false}));  
    });
  }else{
    dispatch(setSnackbarMessage({'message': MEAL_SCHEDULE_ADD.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
    dispatch(setSnackbarVisibilityStatus({'status': true }));    
    dispatch(setClientMealScheduleEditStatus({status: CLIENT_MEAL_SCHEDULE_EDIT_STATUS.INITIAL}));
    dispatch(setLoadingStatus({status: false}));  
  }
}

export const removeScheduledUserMeal = (dispatch, cookies, scheduleId ) => {
  dispatch(setLoadingStatus({status: true}));  

  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
 
  if(accessToken != null){
    axios({
      method: "delete",
      url : `${API_DELETE_CLIENT_MEAL_SCHEDULE}?meal_schedule_id=${scheduleId}`,
      headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => { 
      // handle success
      if(response.status === 200){
         
        dispatch(setSnackbarMessage({'message': MEAL_SCHEDULE_DELETE.SUCCESS , 'severity': SNACKBAR_SEVERITY.SUCCESS}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));    
          // dispatch(setClientDetailSubmitted({status: true}));
      }else{
        dispatch(setSnackbarMessage({'message': MEAL_SCHEDULE_DELETE.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));    
      }
      dispatch(setLoadingStatus({status: false}));  
      return true;
    })
    .catch((err) => {
      dispatch(setLoadingStatus({status: false}));  
      dispatch(setSnackbarMessage({'message': MEAL_SCHEDULE_DELETE.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
      dispatch(setSnackbarVisibilityStatus({'status': true }));    
    });
  }else{
    dispatch(setLoadingStatus({status: false}));  
    dispatch(setSnackbarMessage({'message': MEAL_SCHEDULE_DELETE.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
    dispatch(setSnackbarVisibilityStatus({'status': true }));      }
}

export const updateMealPlanSchedule = (dispatch, cookies, data) => {
  dispatch(setLoadingStatus({status: true}));  

  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  if(accessToken != null){
    axios({
      method: "post",
      url: `${API_UPDATE_CLIENT_MEAL_SCHEDULE}`,
      data,
      headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => {
      // handle success
      if(response.status === 200){
        dispatch(setSnackbarMessage({'message': MEAL_SCHEDULE_UPDATE.SUCCESS , 'severity': SNACKBAR_SEVERITY.SUCCESS}));
    dispatch(setSnackbarVisibilityStatus({'status': true })); 
        dispatch(setClientMealScheduleEditStatus({status: CLIENT_MEAL_SCHEDULE_EDIT_STATUS.INITIAL}));
        fetchMealPlanSchedule(dispatch, cookies, {user_id: data.user_id});
      }else{
        dispatch(setSnackbarMessage({'message': MEAL_SCHEDULE_UPDATE.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
    dispatch(setSnackbarVisibilityStatus({'status': true })); 
        dispatch(setClientMealScheduleEditStatus({status: CLIENT_MEAL_SCHEDULE_EDIT_STATUS.INITIAL}));
      }
      dispatch(setLoadingStatus({status: false}));  
      return true;
    })
    .catch((err) => {
      dispatch(setSnackbarMessage({'message': MEAL_SCHEDULE_UPDATE.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
    dispatch(setSnackbarVisibilityStatus({'status': true })); 
      dispatch(setClientMealScheduleEditStatus({status: CLIENT_MEAL_SCHEDULE_EDIT_STATUS.INITIAL}));
      dispatch(setLoadingStatus({status: false}));  
    });
  }else{
    dispatch(setSnackbarMessage({'message': MEAL_SCHEDULE_UPDATE.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
    dispatch(setSnackbarVisibilityStatus({'status': true })); 
    dispatch(setClientMealScheduleEditStatus({status: CLIENT_MEAL_SCHEDULE_EDIT_STATUS.INITIAL}));
    dispatch(setLoadingStatus({status: false}));  
  }
}

export const fetchMealPlanDishOptions = (dispatch, cookies, userId) => {
  dispatch(setLoadingStatus({status: true}));  

  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  if(accessToken != null){
    axios({
      method: "get",
      url: `${API_GET_CLIENT_MEAL_OPTIONS}?user_id=${userId}`,
      headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => { 
      // handle success
      if(response.status === 200){
        dispatch(setClientMealOptions({status: STATUS.SUCCESS, data: response.data.data}));
          // dispatch(setClientDetailSubmitted({status: true}));
      }else{
        dispatch(setClientMealOptions({status: STATUS.ERROR, data: {}}));
      }
      dispatch(setLoadingStatus({status: false}));  
      return true;
    })
    .catch((err) => {
      dispatch(setClientMealOptions({status: STATUS.ERROR, data: {}}));
      dispatch(setLoadingStatus({status: false}));  
    });
  }else{
    dispatch(setClientMealOptions({status: STATUS.ERROR, data: {}}));
    dispatch(setLoadingStatus({status: false}));  
  }
}

export const fetchActivitySchedule = (dispatch, cookies, data) => {
  dispatch(setLoadingStatus({status: true}));  

  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 

  let url = `${API_GET_CLIENT_ACTIVITY_SCHEDULE}?user_id=${data.user_id}`;

  if(data.start_date){
    url += `&start_date=${data.start_date}`;
  }

  if(data.end_date){
    url += `&end_date=${data.end_date}`;
  }

  if(accessToken != null){
    axios({
      method: "get",
      url,
      headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => { 
      // handle success
      if(response.status === 200){
        dispatch(setClientActivitySchedule({status: STATUS.SUCCESS, data: response.data.data}));
          // dispatch(setClientDetailSubmitted({status: true}));
      }else{
        dispatch(setClientActivitySchedule({status: STATUS.ERROR, data: {}}));
      }
      dispatch(setLoadingStatus({status: false}));  
      return true;
    })
    .catch((err) => {
      dispatch(setClientActivitySchedule({status: STATUS.ERROR, data: {}}));
      dispatch(setLoadingStatus({status: false}));  
    });
  }else{
    dispatch(setClientActivitySchedule({status: STATUS.ERROR, data: {}}));
    dispatch(setLoadingStatus({status: false}));  
  }
}

export const scheduleUserActivity = (dispatch, cookies, data, doRefresh = true) => {
  dispatch(setLoadingStatus({status: true}));  

  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
 
  if(accessToken != null){
    axios({
      method: "post",
      url : API_POST_CLIENT_ACTIVITY_SCHEDULE,
      data,
      headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => { 
      // handle success
      if(response.status === 200){
        if(doRefresh){
          fetchActivitySchedule(dispatch, cookies, {
            user_id : data.user_id,
            // start_date: data.date,
            // end_date: data.date
          });
        }
      dispatch(setSnackbarMessage({'message': ACTIVITY_SCHEDULE.SUCCESS , 'severity': SNACKBAR_SEVERITY.SUCCESS}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));    
          // dispatch(setClientDetailSubmitted({status: true}));
      }else{
        dispatch(setSnackbarMessage({'message': ACTIVITY_SCHEDULE.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));    
      }
      dispatch(setLoadingStatus({status: false}));  
      return true;
    })
    .catch((err) => {
      dispatch(setLoadingStatus({status: false}));  
      dispatch(setSnackbarMessage({'message': ACTIVITY_SCHEDULE.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
      dispatch(setSnackbarVisibilityStatus({'status': true }));    
    });
  }else{
    dispatch(setLoadingStatus({status: false}));  
    dispatch(setSnackbarMessage({'message': ACTIVITY_SCHEDULE.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
    dispatch(setSnackbarVisibilityStatus({'status': true }));      }
}
 
export const removeScheduledUserActivity = (dispatch, cookies, activityScheduleId ) => {
  dispatch(setLoadingStatus({status: true}));  

  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
 
  if(accessToken != null){
    axios({
      method: "delete",
      url : `${API_DELETE_CLIENT_ACTIVITY_SCHEDULE}?activity_schedule_id=${activityScheduleId}`,
      headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => { 
      // handle success
      if(response.status === 200){
         
        dispatch(setSnackbarMessage({'message': ACTIVITY_SCHEDULE_DELETE.SUCCESS , 'severity': SNACKBAR_SEVERITY.SUCCESS}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));    
          // dispatch(setClientDetailSubmitted({status: true}));
      }else{
        dispatch(setSnackbarMessage({'message': ACTIVITY_SCHEDULE_DELETE.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));    
      }
      dispatch(setLoadingStatus({status: false}));  
      return true;
    })
    .catch((err) => {
      dispatch(setLoadingStatus({status: false}));  
      dispatch(setSnackbarMessage({'message': ACTIVITY_SCHEDULE_DELETE.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
      dispatch(setSnackbarVisibilityStatus({'status': true }));    
    });
  }else{
    dispatch(setLoadingStatus({status: false}));  
    dispatch(setSnackbarMessage({'message': ACTIVITY_SCHEDULE.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
    dispatch(setSnackbarVisibilityStatus({'status': true }));      }
}


export const fetchClientReport = (dispatch, cookies, data) => {
  dispatch(setLoadingStatus({status: true}));  

  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 

  const url = `${API_GET_CLIENT_REPORT}?user_id=${data.user_id}&report_type=${data.report_type}`;

  if(accessToken != null){
    axios({
      method: "get",
      url,
      headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => { 
      // handle success
      if(response.status === 200){
        dispatch(setClientReport({data: response.data.data}));
          // dispatch(setClientDetailSubmitted({status: true}));
      }else{
        dispatch(setClientReport({data: null}));
      }
      dispatch(setLoadingStatus({status: false}));  
      return true;
    })
    .catch((err) => {
      dispatch(setClientReport({data: null}));
      dispatch(setLoadingStatus({status: false}));  
    });
  }else{
    dispatch(setClientReport({data: null}));
    dispatch(setLoadingStatus({status: false}));  
  }
}
