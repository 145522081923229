

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui

import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Button, Card, CardHeader, Grid, Stack, TextField, Typography, SwipeableDrawer } from '@mui/material';

import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// components
import { WaterChart } from './chart';

import { formatDate } from '../../../../utils/date';
import Iconify from '../../../../components/Iconify';
import { getActiveClient, getClientJournal, getClientJournalEditDetail, getClientJournalEditStatus } from '../../../../store/coach/reducer';
import CreateJournalButton from '../CreateJournalButton';
import { DAILY_LOG_TYPE } from '../../../../constants/app_constants';
import { getJournalEntry } from '../../../../utils/journal';
import { getPartnerJournal } from '../../../../store/user/reducer';
import { updateUserDailyLog } from '../../../../data/network/journal/api'; 
import CreateJournalForm from '../CreateJournalForm';
import { setLoadingStatus} from '../../../../store/layout/reducer';

// ----------------------------------------------------------------------

WaterTab.propTypes  = {
    journalDate : PropTypes.string,
}

export default function WaterTab( {journalDate} ) {

    const [value, setValue] = useState(
        new Date(journalDate),
    );
    const [glassCount, setGlassCount] = useState(0);

    const [logData, setLogData] = useState([]);

    const [forceSave, setForceSave] = useState(false);

    const dispatch = useDispatch();
    
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);

    const activeClient = useSelector(state => getActiveClient(state)); 
    const partnerJournalList = useSelector(state => getPartnerJournal(state));
    const clientJournal = useSelector(state => getClientJournal(state)); 

    const clientJournalEditDetail = useSelector(state => getClientJournalEditDetail(state));
    const clientJournalEditStatus = useSelector(state => getClientJournalEditStatus(state));

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const toggleDrawer = (anchor , open ) => (event ) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event ).key === 'Tab' ||
            (event ).key === 'Shift')
        ) {
            return;
        }
        setIsDrawerOpen(open); 
    };

    useEffect(() => { 

        if(clientJournalEditDetail !== null && forceSave){
            handleSave();
        }

    },[clientJournalEditDetail, forceSave])

    const journalCreateCallback = () => {
        dispatch(setLoadingStatus({status: true}));
        setTimeout(() => {
            setForceSave(true);
        }, 3000);
    }

    function handleSave(){  
        if(clientJournalEditDetail && clientJournalEditDetail !== null && clientJournalEditDetail.id !== null){
 
            updateUserDailyLog(dispatch, cookies, {
                user_id : activeClient.user_id,
                log_type : DAILY_LOG_TYPE.WATER,
                follow_up_id : clientJournalEditDetail.id,
                data: logData
                // value : glassCount,
                // date : formatDate(journalDate),
            },
            formatDate(new Date(clientJournalEditDetail.from_date)),
            formatDate(new Date(clientJournalEditDetail.to_date)));
        }else{
            setIsDrawerOpen(true);            
        }
    }

    const decreaseGlassCount = (date) => {
        logData.forEach((element, i) => {
            if(new Date(element.date).setHours(0,0,0,0) === new Date(date).setHours(0,0,0,0)){
                if(element.val > 0){
                    element.val-=1;
                }
            }
        });

        handleSave();
    }

    const increaseGlassCount = (date) => {
        logData.forEach((element, i) => {
            if(new Date(element.date).setHours(0,0,0,0) === new Date(date).setHours(0,0,0,0)){
                element.val+=1;
            }
        });      
        
        handleSave();
    }

    useEffect(() => {
        const _logData = getJournalEntry(clientJournal, DAILY_LOG_TYPE.WATER);
        console.log(_logData);
        setLogData(_logData);
    },[clientJournal])

    return ( 
        <Grid container spacing={3} direction="column">
        {
            <Grid item container>
                <Card className='glass-box w-100'>
                    {clientJournalEditStatus ? <CardHeader title="Log Data" /> : null }

                    {
                        logData  && logData.map((e, i) => (
                            <Grid key={i} container direction="row" px={2} justifyContent="space-between" alignItems="center"> 
                                <Grid>
                                    <Typography>{formatDate(e.date,"DD MMM YYYY")}</Typography>
                                </Grid>

                                <Grid item container px={2} justifyContent="center" alignItems="center" lg={8} xl={8}  md={8} xs={12} sm={12}> 
                                    {
                                        (clientJournalEditStatus)
                                        ? <Grid margin={1} >
                                                <Iconify  icon="eva:minus-circle-outline" sx={{height:40, width:40, cursor:'pointer'}} onClick={() => {
                                                    decreaseGlassCount(e.date);
                                                    setGlassCount(glassCount > 0 ? glassCount-1 : 0);
                                                }}/>
                                            </Grid>
                                        : null
                                    }
                                    <Grid item margin={1} justifyContent="center" alignItems="center" alignContent="center" sx={{width:80}}>
                                    {
                                        (clientJournalEditStatus)
                                        ?<center><img width="40" height="40" src="../../../../static/img/water_glass.svg" alt='water' /></center>
                                        : null
                                    }
                                        <Typography variant="body1" sx={{textAlign: 'center'}}> 
                                        
                                            {/* {`${glassCount} ${ glassCount > 1 ? 'glasses' : 'glass'}`} */}

                                            {`${e.val} ${ e.val > 1 ? 'glasses' : 'glass'}`}                                         
                                        
                                        </Typography>
                                    </Grid>
                                    {
                                        (clientJournalEditStatus)
                                        ? <Grid margin={1}>
                                            <Iconify  icon="eva:plus-circle-outline"  sx={{height:40, width:40, cursor:'pointer'}} onClick={() => {
                                                increaseGlassCount(e.date);
                                                setGlassCount(glassCount+1);
                                            }}/>
                                        </Grid>
                                        : null
                                    }
                                </Grid>
                            
                            </Grid>

                        )) 
                    }
                    {/* {
                        (clientJournalEditStatus)
                        ? <Grid item container px={2} mt={4} mb={4} justifyContent="center" alignItems="center" lg={4} xl={4}  md={4} xs={12} sm={12} >                                  
                            <Grid item justifyContent="center"  margin={1}>
                                <Button variant="contained" startIcon={<Iconify icon="eva:save-outline" />} onClick={() => {
                                    handleSave();
                                }}>Save</Button>
                            </Grid>
                        </Grid>
                        : null
                    } */}
                </Card>
            </Grid> 
        } 
 
        <SwipeableDrawer
            anchor={'bottom'}
            open={isDrawerOpen}
            onClose={toggleDrawer( 'bottom', false)}
            onOpen={toggleDrawer( 'bottom', true)}>
            <CreateJournalForm drawerHandler={toggleDrawer('bottom', false)} createCallback={journalCreateCallback} />
        </SwipeableDrawer>
    </Grid>
  );
}




