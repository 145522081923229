import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import {combineReducers} from "redux"; 
import { persistReducer } from 'redux-persist'

import userReducer from './user/reducer'
import coachReducer from './coach/reducer'
import corporateReducer from './corporate/reducer'
import otpReducer from './otp/reducer'
import subscriptionReducer from './subscription/reducer'
import onboardingReducer from './onboarding/reducer'
import layoutReducer from './layout/reducer'
import activityReducer from './activity/reducer'
import dishReducer from './dish/reducer'
import mealReducer from './meal/reducer'
import partnerStoreReducer from './partner_store/reducer'

const reducers = combineReducers({
    user: userReducer,
    coach: coachReducer,
    corporate: corporateReducer,
    otp: otpReducer,
    subscription: subscriptionReducer,
    onboarding: onboardingReducer,
    layout: layoutReducer,
    activity: activityReducer,
    dish: dishReducer,
    meal: mealReducer,
    partner_store: partnerStoreReducer
});
   
const persistConfig = {
    key: 'bytes-partner-root',
    storage
};

const persistedReducer = persistReducer(persistConfig, reducers);
 
const store = configureStore({
    reducer: persistedReducer, 
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
    // reducer : {
    //     user: userReducer,
    //     client: clientReducer,
    //     corporate: corporateReducer,
    //     otp: otpReducer,
    //     subscription: subscriptionReducer,
    //     onboarding: onboardingReducer,
    // }
})

export default store