import { Link as RouterLink } from 'react-router-dom';
// material
import { Grid, Button, Container, Stack, Typography, Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../sections/@dashboard/blog';
// import {PartnerProfile} from '../sections/profile/PartnerProfile'; 
// ----------------------------------------------------------------------

export default function Profile() {
    const theme = useTheme();

    return (
        <Page title="My Profile">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                    My Profile
                </Typography>
                <Button variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:pencil-fill" />}>
                    Edit
                </Button>
                </Stack>

                <Grid container spacing={3}>
                {/* <Card
                    sx={{
                        py: 5,
                        boxShadow: 0,
                        textAlign: 'center',
                        color: (theme) => theme.palette[color].darker,
                        bgcolor: (theme) => theme.palette[color].lighter,
                        ...sx,
                    }}
                    {...other}
                >
                    <PartnerProfile />
                </Card> */}
                </Grid>

                {/* <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
                <BlogPostsSearch posts={POSTS} />
                <BlogPostsSort options={SORT_OPTIONS} />
                </Stack>

                <Grid container spacing={3}>
                {POSTS.map((post, index) => (
                    <BlogPostCard key={post.id} post={post} index={index} />
                ))}
                </Grid> */}
            </Container>
        </Page>
    );
}
