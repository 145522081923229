import { useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
//
import DashboardNavbar from '../dashboard/DashboardNavbar';
import { getSession } from '../../data/network/session/api';
import { getLoginStatus } from '../../store/user/reducer';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function ClientLayout() {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
  const navigate = useNavigate();
  const isLoggedIn = useSelector(state => getLoginStatus(state));

  useEffect(() =>{
    getSession(dispatch, cookies);
  },[])

  useEffect(() =>{
  },[isLoggedIn])

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      {/* <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} /> */}
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
