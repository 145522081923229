/* eslint-disable camelcase */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  Box, Tabs, Tab
} from '@mui/material';
import { useCookies } from 'react-cookie';
import { LoadingButton, TabPanel, TabContext } from "@mui/lab";

import { useDispatch, useSelector } from 'react-redux';
import { getClientList } from '../store/coach/reducer';
import { getUserId } from '../store/user/reducer';
import { fetchClientList } from '../data/network/client/api';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { ClientOnboardingForm, UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
// mock
import { formatDate, getAge } from '../utils/date';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: '#', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'mobile', label: 'Mobile', alignRight: false },
  { id: 'age', label: 'Age', alignRight: false },
  { id: 'gender', label: 'Gender', alignRight: false },
  { id: 'orderType', label: 'Order Type', alignRight: false },
  { id: 'mealPlan', label: 'Meal Plan', alignRight: false },
  { id: 'subscription', label: 'Subscription', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

function a11yProps(index ) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  if(array !== undefined && array != null &&  array.length>0){
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(array, (_user) => _user.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);  
  }

  return [];
}

export default function User() {
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('first_name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isModalVisible, setModalVisible] = useState(false);

  const dispatch = useDispatch();
  const USERLIST = useSelector(state => getClientList(state,value));
  const partnerId = useSelector(state => getUserId(state));
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

  useEffect(() => {
    if(partnerId != null){
      fetchClientList(dispatch, cookies, partnerId);
    }
  },[partnerId])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleTabChange = (e, i) => {
    setValue(i);
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);
  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="User">
      <Container sx={{paddingTop:4}}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            All Clients
          </Typography>
          <Button variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => setModalVisible(true)}>
            Subscribe New User
          </Button>
        </Stack>

        <Modal
          open={isModalVisible}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{zIndex:99}}
          // sx={{height:'100%', width:'100%', background:'#fff', borderRadius:2, margin:'0'}}
          >
          <Box>

            <ClientOnboardingForm handleClose={handleModalClose}/>

          </Box>
        </Modal>

        <Card className='glass-box'>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

           

                        <TabContext value={value}>
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
                              <Tab label="Active" value={0} {...a11yProps(0)} />
                              <Tab label="InActive" value={1} {...a11yProps(1)} />
                            </Tabs>
                          </Box>

                          <TabPanel value={0}> 

                            <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                            {
                              USERLIST.length>0 
                              && 
                              <Table>
                                <UserListHead
                                  order={order}
                                  orderBy={orderBy}
                                  headLabel={TABLE_HEAD}
                                  rowCount={USERLIST.length}
                                  numSelected={selected.length}
                                  onRequestSort={handleRequestSort}
                                  onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                    
                                      const { id, first_name, mobile, dob, subscription, gender} = row;
                                      const mealPlan =  subscription.meal_plan.parent_meal_plan.display_name;
                                      const subscription_start_date =  formatDate(subscription.meal_plan.active_from, "DD MMM YYYY");
                                      const subscription_end_date =  formatDate(subscription.meal_plan.active_to, "DD MMM YYYY");
                                      const status =  subscription.meal_plan.status;
                                      const age = getAge(dob) ;

                                      const isItemSelected = selected.indexOf(mobile) !== -1;

                                      return <TableRow
                                        hover
                                        key={i}
                                        tabIndex={-1}
                                        role="checkbox"
                                        selected={isItemSelected}
                                        aria-checked={isItemSelected}>
                                        <TableCell align="center">{id}</TableCell>
                                        <TableCell align="center">{first_name}</TableCell>
                                        <TableCell align="center">{mobile}</TableCell>
                                        <TableCell align="center">{age}</TableCell>
                                        <TableCell align="center">{gender}</TableCell>
                                        <TableCell align="center">{`${subscription.order_type}`}</TableCell>
                                        <TableCell align="center">{mealPlan}</TableCell>
                                        <TableCell align="center">{`${subscription_start_date  } - ${  subscription_end_date}` }</TableCell>
                                        <TableCell align="center">
                                          {status}
                                        </TableCell>

                                        <TableCell align="right">
                                          <UserMoreMenu clientId={id} mealPlan={subscription.meal_plan} />
                                        </TableCell>
                                      </TableRow>
                                    
                                  })}

                                  {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                      <TableCell colSpan={6} />
                                    </TableRow>
                                  )}

                                </TableBody>
                                {isUserNotFound && (
                                    <TableBody>
                                      <TableRow>
                                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                          <SearchNotFound searchQuery={filterName} />
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  )} 
                                </Table>
                              }
                              </TableContainer>
                              <TablePagination
                                  rowsPerPageOptions={[5, 10, 25]}
                                  component="div"
                                  count={USERLIST.length}
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  onPageChange={handleChangePage}
                                  onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Scrollbar>
                          </TabPanel>

                          <TabPanel value={1}> 
                            <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                            {
                              USERLIST.length>0 
                              && 
                              <Table>
                                <UserListHead
                                  order={order}
                                  orderBy={orderBy}
                                  headLabel={TABLE_HEAD}
                                  rowCount={USERLIST.length}
                                  numSelected={selected.length}
                                  onRequestSort={handleRequestSort}
                                  onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                     
                                    const { id, first_name, mobile, dob, subscription, gender} = row;
                                    const mealPlan =  subscription.meal_plan.parent_meal_plan.display_name;
                                    const subscription_start_date =  formatDate(subscription.meal_plan.active_from, "DD MMM YYYY");
                                    const subscription_end_date =  formatDate(subscription.meal_plan.active_to, "DD MMM YYYY");
                                    const status =  subscription.meal_plan.status;
                                    const age = getAge(dob) ;

                                    const isItemSelected = selected.indexOf(mobile) !== -1;

                                    return <TableRow
                                      hover
                                      key={i}
                                      tabIndex={-1}
                                      role="checkbox"
                                      selected={isItemSelected}
                                      aria-checked={isItemSelected}>
                                      <TableCell align="center">{id}</TableCell>
                                      <TableCell align="center">{first_name}</TableCell>
                                      <TableCell align="center">{mobile}</TableCell>
                                      <TableCell align="center">{age}</TableCell>
                                      <TableCell align="center">{gender}</TableCell>
                                      <TableCell align="center">{`${subscription.order_type}`}</TableCell>
                                      <TableCell align="center">{mealPlan}</TableCell>
                                      <TableCell align="center">{`${subscription_start_date  } - ${  subscription_end_date}` }</TableCell>
                                      <TableCell align="center">
                                        {status}
                                      </TableCell>

                                      <TableCell align="right">
                                        <UserMoreMenu clientId={id} mealPlan={subscription.meal_plan} />
                                      </TableCell>
                                    </TableRow>
                                  })}

                                  {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                      <TableCell colSpan={6} />
                                    </TableRow>
                                  )}

                                  {isUserNotFound && (
                                      <TableRow>
                                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                          <SearchNotFound searchQuery={filterName} />
                                        </TableCell>
                                      </TableRow>
                                  )}
                                </TableBody>

                                

                                

                                </Table>
                                
                              }
                              
                              </TableContainer>
                              <TablePagination
                                  rowsPerPageOptions={[5, 10, 25]}
                                  component="div"
                                  count={USERLIST.length}
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  onPageChange={handleChangePage}
                                  onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Scrollbar>
                          </TabPanel>

                        </TabContext>            


        </Card>
      </Container>
    </Page>
  );
}
