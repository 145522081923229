import { Container } from "@mui/material";

import Page from "../../../components/Page";
import { StoreMenuList } from "./StoreMenuList";

export const StoreMenu = () => {
    return (
        <Page title="Menu">
            <Container size="xl">
                <StoreMenuList />
            </Container>
        </Page>
    );
}