/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { filter } from 'lodash';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Box, Checkbox, Stack, Table, TableBody, TableCell,Typography, TableContainer, TablePagination, TableRow, Button, Grid } from "@mui/material";
import Scrollbar from "../../../components/Scrollbar";
import Iconify from '../../../components/Iconify';
import CorporateFeedbackListToolbar from './corporate_feedback/CorporateFeedbackListToolbar';
import CorporateFeedbackListHead from './corporate_feedback/CorporateFeedbackListHead';
import SearchNotFound from '../../../components/SearchNotFound';
import { getUserId } from '../../../store/user/reducer';
import { fetchCorporateFeedback } from '../../../data/network/corporate/api';
import { getCorporateFeedback } from '../../../store/corporate/reducer';
import ExportCSV from '../../../components/ExportCSV';

export default function CorporateFeedbackList(){

    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    
    const dataList = useSelector(state => getCorporateFeedback(state));

    const dispatch = useDispatch();
    const partnerId = useSelector(state => getUserId(state));
    const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

    useEffect(() => { 
        console.log(dataList);
    },[dataList])
  
    const TABLE_HEAD = [
        { id: 'id', label: '#', alignRight: false },
        { id: 'feedback_title', label: 'Feedback', alignRight: false },
        { id: 'feedback_description', label: 'Description', alignRight: false },
        { id: 'user', label: 'User', alignRight: false },
        { id: 'dish', label: 'Dish', alignRight: false },
    ];

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
    }
      
    function getComparator(order, orderBy) {
        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
    }
      
    function applySortFilter(array, comparator, query) {
        if(array !== undefined && array != null &&  array.length>0){
          const stabilizedThis = array.map((el, index) => [el, index]);
          stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
          });
          if (query) {
            return filter(array, (_user) => _user.feedback.toLowerCase().indexOf(query.toLowerCase()) !== -1);
          }
          return stabilizedThis.map((el) => el[0]);  
        }
      
        return [];
}  

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
          const newSelecteds = dataList.map((n) => n.name);
          setSelected(newSelecteds);
          return;
        }
        setSelected([]);
    };
    
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };
    
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataList.length) : 0;

    const filteredData = applySortFilter(dataList, getComparator(order, orderBy), filterName);
    const isDataNotFound = filteredData.length === 0;
  
    const headers = [ 
        { label: "User", key: "user" },
        { label: "Dish", key: "dish" },
        { label: "Feedback", key: "feedback_title" },
        { label: "Description", key: "feedback_description" }
    ]; 

    function feedbackData() {
        const data = [];
        if(dataList){
            
            for (let i = 0; i <  dataList.length; i+=1) {
                console.log(dataList[i]);
                // data[i] = {
                //     'user': `${dataList[i]?.user?.first_name  } ${  dataList[i]?.user?.last_name}`,
                //     'dish': dataList[i]?.dish?.display_name,
                //     'feedback_title': dataList[i]?.feedback?.feedback_title,
                //     'feedback_description': dataList[i]?.feedback?.feedback_description,              
                // };
            }
        } 
        return data;
    }

    return (
        <Box>
            <Grid container justifyContent="space-between">
                <Typography variant="h4" gutterBottom>
                    Feedback
                </Typography> 
                <ExportCSV csvHeaders={headers}
                    csvData={feedbackData()}
                    fileName="feedback.csv" /> 
            </Grid>
            {/* <CorporateFeedbackListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName} /> */}

            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    {
                    dataList && 
                    dataList.length>0 
                    && 
                    <Table>
                        <CorporateFeedbackListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={dataList.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                        {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                            // console.log(row);
                            const { user, dish, feedback } = row;
                            const {first_name, last_name} = user;
                            const {display_name} = dish;
                            const {id, feedback_title, feedback_description} = feedback;
                            // const mealPlan =  subscription.meal_plan.parent_meal_plan.display_name;
                            // const subscription_start_date =  subscription.start_date;
                            // const subscription_end_date =  subscription.end_date;
                            // const age = getAge(dob) ;

                            const isItemSelected = selected.indexOf(id) !== -1;

                            return (
                            <TableRow
                                hover
                                key={id}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                            >
                                {/* <TableCell padding="checkbox">
                                <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                                </TableCell> */}
                                <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                    {/* <Avatar alt={mobile} src={avatarUrl} /> */}
                                    <Typography variant="subtitle2" noWrap>
                                    {id}
                                    </Typography>
                                </Stack>
                                </TableCell>
                                <TableCell align="center">{ feedback_title}</TableCell>
                                <TableCell align="center">{ feedback_description}</TableCell>
                                <TableCell align="center">{`${first_name} ${last_name} ` }</TableCell>
                                <TableCell align="center">{ display_name}</TableCell>  
                                {/* <TableCell align="right">
                                    <Button variant="contained" component={RouterLink} to="#" >
                                        <Iconify icon="eva:download-outline" />
                                    </Button>
                                </TableCell> */}
                            </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                            </TableRow>
                        )}
                        </TableBody>

                        {isDataNotFound && (
                        <TableBody>
                            <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                            </TableRow>
                        </TableBody>
                        )}
                    </Table>
                    }
                </TableContainer>
            </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={dataList ? dataList.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}/>
        </Box>
    );
}