import * as React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types'; 
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import {WaterTab, SleepTab, FoodTab, ActivityTab, WeightTab} from './logbook/';
import { fetchUserDailyLog } from '../../../data/network/journal/api';
import { formatDate } from '../../../utils/date';
import { getClientJournalEditDetail } from '../../../store/coach/reducer';


TabPanel.propTypes  = {
  children : PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
}

function TabPanel(props ) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index ) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function LogBook({journalDate}) {
  const [value, setValue] = React.useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
  const [searchParams, setSearchParams] = useSearchParams();

  const clientId = searchParams.get("client_id");
  const journalEditDetail = useSelector(state => getClientJournalEditDetail(state));

  const handleChange = (event  , newValue ) => {
    setValue(newValue);
  };

  console.log(journalDate);

  // useEffect(() => {
  //   // fetchUserDailyLog(dispatch, cookies, {
  //   //   user_id: clientId,
  //   //   start_date: formatDate(new Date(journalEditDetail.from_date)), 
  //   //   end_date: formatDate(new Date(journalEditDetail.to_date)), 
  //   // });
  // },[])

  return (
    <Box sx={{ width: '100%' }}  xs={12} md={12} lg={9}  order={{ xs: 2, sm: 2, md: 2, lg:1, xl:1 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab key="water" label="Water" {...a11yProps(0)} />
          <Tab key="sleep" label="Sleep" {...a11yProps(1)} />
          <Tab key="food" label="Food" {...a11yProps(2)} />
          <Tab key="activity" label="Activity" {...a11yProps(3)} />
          <Tab key="weight" label="Weight" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <WaterTab journalDate={journalDate} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SleepTab journalDate={journalDate} />
      </TabPanel>
      <TabPanel value={value} index={2}>
         <FoodTab journalDate={journalDate} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ActivityTab journalDate={journalDate} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <WeightTab journalDate={journalDate} />
      </TabPanel>
    </Box>
  );
}
