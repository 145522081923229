import { createSlice } from '@reduxjs/toolkit'

const kitchenSlice = createSlice({
  name: 'kitchen',
  initialState: {
    stats: {},
  },
  reducers: {
    setKitchenStats(state,action) {
      state.stats = action.payload.data; 
    },
  }
})

export const getKitchenStats = (state) => {
  if(state.kitchen != null && state.kitchen !== undefined){
    return state.kitchen.stats;
  }
  return null;
}

export const { setKitchenStats } = kitchenSlice.actions
export default kitchenSlice.reducer