import { ONBOARDING_FORM_STEPS } from '../../../constants/app_constants';
import { setOnboardingCurrentStep } from '../../../store/onboarding/reducer';
import { setActiveClient, setClientDetailSubmitted, setClientList,  setClientOrderDetails, setAddTransactionDetails } from '../../../store/coach/reducer';
import axios from '../../../utils/axios-configure';
import { API_GENERATE_ORDER_ID, API_ADD_TRANSACTION } from "../endpoints";
import {getAccessTokenFromCookie} from '../session/api'
import { setLoadingStatus } from '../../../store/layout/reducer';

export const generateOrderId = (dispatch, cookies, data) => {
  dispatch(setLoadingStatus({status: true}));  
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies, data); 
    if(accessToken != null){
        axios({
            method: "post",
            url: `${API_GENERATE_ORDER_ID}`,
            data,
            headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
          })
          .then((response) => {
            // handle success
            console.log(response);
            if(response.status === 200){
              dispatch(setClientOrderDetails({data: response.data.data}));
            }else{
              dispatch(setClientOrderDetails({data: null}));
            } 
            dispatch(setLoadingStatus({status: false}));  
            return true;
          })
          .catch((err) => {
            dispatch(setLoadingStatus({status: false}));  
            dispatch(setClientOrderDetails({data: null}));
          });
    }else{
      dispatch(setLoadingStatus({status: false}));  
      dispatch(setClientOrderDetails({data: null}));
    }
}

export const addTransaction = (dispatch, cookies, data) => {
  dispatch(setLoadingStatus({status: true}));  
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    if(accessToken != null){
        axios({
            method: "post",
            url: `${API_ADD_TRANSACTION}`,
            data,
            headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
          })
          .then((response) => {
              console.log(response);
            // handle success
            if(response.status === 200){
              dispatch(setAddTransactionDetails({data: response.data.data}));              
            }else{
              dispatch(setAddTransactionDetails({data: null}));    
            }
            dispatch(setLoadingStatus({status: false}));  
            return true;
          })
          .catch((err) => {
            dispatch(setAddTransactionDetails({data: null}));    
            dispatch(setLoadingStatus({status: false}));  

          });
    }else{
      dispatch(setAddTransactionDetails({data: null}));    
      dispatch(setLoadingStatus({status: false}));  
    }
}

