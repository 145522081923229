
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Stack, Typography, Box, TextField, Select, MenuItem, Button, styled, Table, TableHead, TableCell, TableRow, Paper, Modal, Dialog, DialogTitle, Autocomplete, TableFooter, Grid } from '@mui/material';
import { useCookies } from 'react-cookie';
import { formatDate } from '../../../utils/date';
import { VENDOR_PAYMENT_TYPE, EXPENSE_STATUS } from '../../../constants/app_constants';
import { updateStoreExpense } from '../../../data/network/store/expense';
import { getActiveStoreId, getStoreStock } from '../../../store/partner_store/reducer';
import Iconify from '../../../components/Iconify';

const TextFieldStyle = styled(TextField)(() => ({
    '& .MuiInputBase-input': {
        padding: '8px 14px',
    }
}));

const SelectStyle = styled(Select)(() => ({
    '& .MuiSelect-select': {
        padding: '8px 14px',
    }
}));


export const StoreExpenseDetail = ({ data, visible, setOpen , editable=true, create = true}) => {
    const [editEnable, setEditEnable] = useState(false);
    const [expenseData, setExpenseData] = useState({});
    const [editableData, setEditableData] = useState({});
    const [date, setDate] = useState(null);

    const dispatch = useDispatch();
    const [cookies] = useCookies(['mobile', 'session', 'access_token']);
    const storeId = useSelector((state) => getActiveStoreId(state));
    const stockList = useSelector((state) => getStoreStock(state));

    useEffect(() => {
        setEditEnable(!editable);
        setExpenseData(data);

        console.log(data);

        setEditableData({
            'status' : '',
            'order_items' : []
        })

        console.log(editableData);

        if (data && data.status && data.status.length > 0){
            setEditableData({...editableData, 'status' : data.status[0].status});
        }
        
        console.log(editableData);

        if (data && data.order_items && data.order_items.length > 0) {
            console.log("p-----");
            setEditableData({...editableData, 'order_items' : data.order_items});
        }

    }, [data])

    const handleChange = (event) => {
        if (event.target.name === 'status') {
            setEditableData({...editableData, 'status' : event.target.value});
        }else{
            setExpenseData({ ...expenseData, [event.target.name]: event.target.value })
        }
    }

    const handleItemChange = (event, data, i) => {
        console.log(event);
        console.log(event.target.name);
        console.log(event.target.value);
        console.log(i);


        setEditableData();
    }

    const handleAddItem = (event, data, i) => {
        console.log(editableData);
        const _orderItems = editableData.order_items;
        if (_orderItems){
            _orderItems.push({'display_name' : '', 'qty': '', 'unit': ''});
        }
        console.log(_orderItems);
        setEditableData({...editableData, 'order_items' : _orderItems});
    }

    // const handleDate = (newValue) => {
    //     setDate(formatDate(newValue));
    //     setExpenseData({ ...expenseData, dob: formatDate(newValue) })
    // }

    const handleUpdate = () => {
        const payload = {...expenseData, 'status' : editableData.status};
        updateStoreExpense(dispatch, cookies, storeId, {'items': [payload]})
        setOpen(false);
    }
    return (
        <Dialog  
            // anchor='right'
            open={visible}
            onClose={() => setOpen(false)}
            maxWidth={'md'}
            fullWidth
        >
            <DialogTitle>
                <Box sx={{ pb: 2 }} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='h6'>Detail View</Typography>
                    <Button size='small' color='info' onClick={() => setEditEnable(false)}>Edit</Button>
                </Box>
            </DialogTitle>
            <Stack sx={{ width: 'md' }} style={{ padding: '20px', gap: '10px' }}>
                {/* <Box sx={{ pb: 2 }} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='h6'>Detail View</Typography>
                    <Button size='small' color='info' onClick={() => setEditEnable(false)}>Edit</Button>
                </Box> */}
                
                {/* <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Name</Typography>
                    <TextFieldStyle disabled={editEnable} name='display_name' value={expenseData?.display_name} onChange={handleChange} />
                </Box> */}
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Purchase Date</Typography>
                    <TextFieldStyle disabled={editEnable} name='purchase_date' value={expenseData?.purchase_date} onChange={handleChange} />
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Delivery Date</Typography>
                    <TextFieldStyle disabled={editEnable} name='delivery_date' value={expenseData?.delivery_date} onChange={handleChange} />
                </Box>
                {/* <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Vendor</Typography>
                    <TextFieldStyle disabled={editEnable} name='vendor' value={expenseData?.vendor} onChange={handleChange} />
                </Box>

                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Items</Typography>
                    <TextFieldStyle disabled={editEnable} name='items' value={expenseData?.items} onChange={handleChange} />
                </Box> */}

                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Payment Type</Typography>
                    {/* <TextFieldStyle disabled={editEnable} name='payment_type' value={expenseData?.payment_type} onChange={handleChange} /> */}
                    <SelectStyle
                        value={expenseData?.payment_type ?? ""}
                        onChange={handleChange}
                        displayEmpty
                        disabled={editEnable}
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{ width: 205 }}
                        name="payment_type"
                    >
                        {
                            Object.keys(VENDOR_PAYMENT_TYPE).map((e, i) => {
                                return <MenuItem key={i} value={e}>{e}</MenuItem>
                            })
                        }
                    </SelectStyle>
                </Box>

                <Table component={Paper}>
                    <TableHead>
                        <TableCell>{''}</TableCell>
                        <TableCell>{'Name'}</TableCell>
                        <TableCell>{'Quantity'}</TableCell>
                        <TableCell>{'Unit'}</TableCell>
                        <TableCell>{''}</TableCell>
                    </TableHead>

                    {
                        
                        editableData && editableData.order_items && editableData.order_items.map((e,i) => {
                            return <TableRow key={i}>
                                <TableCell> {i+1} </TableCell>
                                <TableCell> 
                                    {/* <SelectStyle
                                        disabled={editEnable}
                                        name={`item_display_name`}
                                        value={e.display_name}
                                        onChange={(event, data) => handleItemChange(event, data, i)}
                                        sx={{width: 200}}>
                                        
                                        {
                                            stockList && stockList.data && stockList.data.map((e, i) => {
                                                return <MenuItem key={i} value={e.display_name}>{e.display_name}</MenuItem>
                                            })
                                        }

                                    </SelectStyle> */}

                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        name="item_display_name"
                                        options={stockList.data}
                                        // onInputChange={handleInputChange}
                                        onChange={(event, data) => handleItemChange(event, data, i)}
                                        getOptionLabel={(option) => option.display_name ?? '' }
                                        style={{ width: 200 }}
                                        value={e}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props} key={option.index}>
                                                {option.display_name ?? ''}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Select an item" variant="outlined" />
                                        )}
                                    />
                                 </TableCell>
                                <TableCell> 
                                   <TextFieldStyle disabled={editEnable} name='item_qty' value={e.qty} onChange={(event,data) => handleItemChange(event,data, i)} />
                                </TableCell>

                                <TableCell> {e.unit} </TableCell>
                                <TableCell> 
                                    <Button variant="contained">
                                        <Iconify icon={'eva:trash-2-outline'} />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        })
                    
                    }

                    <TableFooter>
                        <Grid container justifyContent={'center'} alignItems={'center'}>
                            <Button variant="contained" onClick={handleAddItem}>
                                <Typography variant="body1"> Add item </Typography>
                            </Button>
                        </Grid>
                    </TableFooter>
                </Table>

{/* 
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Status</Typography>
                    <SelectStyle
                        value={editableData.status}
                        onChange={handleChange}
                        displayEmpty
                        disabled={editEnable}
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{ width: 220 }}
                        name="status"
                    >
                        {
                            Object.keys(EXPENSE_STATUS).map((e, i) => {
                                return <MenuItem key={i} value={e}>{e}</MenuItem>
                            })
                        }
                    </SelectStyle>
                </Box> */}

                {/* <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Payment Status</Typography>
                    <TextFieldStyle disabled={editEnable} name='payment_status' value={expenseData?.payment_status} onChange={handleChange} />

                </Box> */}

                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Status</Typography>
                    <SelectStyle
                        value={editableData?.status}
                        onChange={handleChange}
                        displayEmpty
                        // disabled={editEnable}
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{ width: 205 }}
                        name="status"
                    >
                        {/* <MenuItem value="Breakfast" >Breakfast</MenuItem> */}
                        {
                            Object.keys(EXPENSE_STATUS).map((e, i) => {
                                return <MenuItem key={i} value={e}>{e}</MenuItem>
                            })
                        }
                    </SelectStyle>
                </Box>

                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Typography>Invoice ID</Typography>
                    <TextFieldStyle disabled={editEnable} name='invoice_id' value={expenseData?.invoice_id} onChange={handleChange} />
                </Box>

                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Invoice File</Typography>
                    <TextFieldStyle disabled={editEnable} name='invoice_file' value={expenseData?.invoice_file} onChange={handleChange} />
                </Box>

                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Invoice Amount</Typography>
                    <TextFieldStyle disabled={editEnable} name='invoice_amount' value={expenseData?.invoice_amount} onChange={handleChange} />
                </Box>

                {/* <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Taxes</Typography>
                    <TextFieldStyle disabled={editEnable} name='taxes' value={expenseData?.taxes} onChange={handleChange} />
                </Box> */}

                {/* <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Delivery Charges</Typography>
                    <TextFieldStyle disabled={editEnable} name='delivery_charges' value={expenseData?.delivery_charges} onChange={handleChange} />
                </Box> */}
{!editEnable && <Button sx={{ mt: 2, pt: 1, pb: 1 }} size='small' variant='contained' onClick={handleUpdate}>
                    {create ? <Typography>Create</Typography>
                     : <Typography>Update</Typography>
                    }
                </Button>}
                {/* {<Button sx={{ mt: 2, pt: 1, pb: 1 }} size='small' variant='contained' onClick={handleUpdate}>Update</Button>} */}
            </Stack>
        </Dialog >
    )

}