import axios from '../../../utils/axios-configure';
import { API_GET_ACTIVITY_LIST, API_ADD_ACTIVITY , API_GET_ACTIVITY_CATEGORY_LIST
} from "../endpoints";

import {getAccessTokenFromCookie} from '../session/api';
import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../../store/layout/reducer';
import { SNACKBAR_SEVERITY, STATUS } from '../../../constants/app_constants';
import { ACTIVITY_CREATE } from '../../../constants/message_constants';
import { setActivityList, setAddActivityStatus, setNewActivity, setActivityCategoryList } from '../../../store/activity/reducer';


export const fetchActivityCategories = (dispatch, cookies) => {

  dispatch(setLoadingStatus({status: true}));  

  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
  if(accessToken != null){
      axios({
          method: "get",
          url : API_GET_ACTIVITY_CATEGORY_LIST,
          headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
      })
      .then((response) => {
          // handle success
          if(response.status === 200){
              dispatch(setActivityCategoryList({data: response.data.data}));
          }else{
          dispatch(setActivityCategoryList({data: {}}));
          } 
          dispatch(setLoadingStatus({status: false}));  

          return true;
      })
      .catch((err) => {
          dispatch(setActivityCategoryList({data: {}})); 
          dispatch(setLoadingStatus({status: false}));  
      });
  }else{
      dispatch(setActivityCategoryList({data: {}}));
      dispatch(setLoadingStatus({status: false}));  
  }
}
 
export const fetchActivity = (dispatch, cookies) => {
    // partnerId = 3;
    dispatch(setLoadingStatus({status: true}));  

    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    if(accessToken != null){
        axios({
            method: "get",
            url : API_GET_ACTIVITY_LIST,
            headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
        })
        .then((response) => {
            // handle success
            if(response.status === 200){
                dispatch(setActivityList({data: response.data.data}));
            }else{
            dispatch(setActivityList({data: []}));
            } 
            dispatch(setLoadingStatus({status: false}));  

            return true;
        })
        .catch((err) => {
            dispatch(setActivityList({data: []})); 
            dispatch(setLoadingStatus({status: false}));  
        });
    }else{
        dispatch(setActivityList({data: []}));
        dispatch(setLoadingStatus({status: false}));  
    }
}
   
export const addActivity = (dispatch, cookies, data) => {
      const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
      dispatch(setLoadingStatus({status: true}));  
      if(accessToken != null){
          axios({
              method: "post",
              url: `${API_ADD_ACTIVITY}`,
              data,
              headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
            })
            .then((response) => {
              // handle success
              if(response.status === 200 && response.data.success === true){
                dispatch(setSnackbarMessage({'message': ACTIVITY_CREATE.SUCCESS , 'severity': SNACKBAR_SEVERITY.SUCCESS}));
                dispatch(setSnackbarVisibilityStatus({'status': true }));     
                dispatch(setNewActivity({data: response.data.data})); 
                dispatch(setAddActivityStatus({status: STATUS.SUCCESS}));
                fetchActivity(dispatch, cookies);
                // dispatch(setPartnerJournal({data: response.data.data}));
              }else{
                dispatch(setSnackbarMessage({'message': ACTIVITY_CREATE.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
                dispatch(setSnackbarVisibilityStatus({'status': true }));    
                // dispatch(setPartnerJournal({data: {}}));
              }
              dispatch(setLoadingStatus({status: false}));  
              return true;
            })
            .catch((err) => {
                dispatch(setSnackbarMessage({'message': ACTIVITY_CREATE.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
                dispatch(setSnackbarVisibilityStatus({'status': true }));     
              dispatch(setLoadingStatus({status: false}));  
            });
      }else{
        dispatch(setSnackbarMessage({'message': ACTIVITY_CREATE.ERROR , 'severity': SNACKBAR_SEVERITY.ERROR}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));     
          dispatch(setLoadingStatus({status: false}));  
      }
  }