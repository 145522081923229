import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton } from '@mui/material';
import { useCookies } from 'react-cookie';
import CheckIcon from '@mui/icons-material/CheckCircle';
// components
import MenuPopover from '../../components/MenuPopover';
// mocks_
import account from '../../_mock/account';
import { getActiveRole, getUser, getUserName, logoutUser, setActiveRole } from '../../store/user/reducer';
import { ROLES } from '../../constants/app_constants';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
    linkTo: '/',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
    linkTo: '#',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
    linkTo: '#',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);

  const navigate = useNavigate();

  const [open, setOpen] = useState(null);
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(['mobile','session', 'access_token']);

  const userName = useSelector(state => getUserName(state));
  const userDetails = useSelector(state => getUser(state));
  const activeRole = useSelector(state => getActiveRole(state));

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    removeCookie('mobile', { path: '/' });
    removeCookie('session', { path: '/' });
    removeCookie('access_token', { path: '/' });
    sessionStorage.removeItem('mobile');
    sessionStorage.removeItem('session');
    sessionStorage.removeItem('access_token');
    dispatch(logoutUser({data: {}}));
    setOpen(null);
    navigate('/');
  }

  const handleRoleChange = (selectedOption) => {
    dispatch(setActiveRole({role: selectedOption}));

    if(Object.keys(ROLES)){
      Object.keys(ROLES).forEach(element => {
        if(ROLES[element].name === selectedOption.name){
          navigate(ROLES[element].path);
        }
      });
    }
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userName}
          </Typography>
          {/* <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {account.email}
          </Typography> */}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ my: 1.5, px: 2.5 }}>
          {
            userDetails && userDetails.roles && userDetails.roles.map((role , idx) => {
              return ROLES[role.name] && 
              <Typography variant="subtitle2" onClick={() => handleRoleChange(role)}>{role.display_name} {(activeRole.name === role.name) && <IconButton size="small"><CheckIcon /></IconButton>}</Typography>
              // <MenuItem key={idx} value={role.name} onClick={() => handleRoleChange(role)}>{role.display_name} </MenuItem>
            }
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

{/* 
        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
