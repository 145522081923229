import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../../store/layout/reducer';
import axios from '../../../utils/axios-configure';
import { getAccessTokenFromCookie } from "../session/api";
import { SNACKBAR_SEVERITY } from '../../../constants/app_constants';
import { setStoreVendor, setStoreVendorStats } from '../../../store/partner_store/reducer';
import { STORE_VENDOR_DELETE, STORE_VENDOR_UPDATE } from '../../../constants/message_constants';
import { API_DELETE_STORE_VENDOR, API_GET_STORE_VENDOR, API_GET_STORE_VENDOR_STATS, API_UPDATE_STORE_VENDOR } from '../endpoints';

export const fetchStoreVendor = (dispatch, cookies,storeId, page = 1, count=10, filterBy = { key: '', value: '' }) => {
    dispatch(setLoadingStatus({ status: true }));
    const accessToken = getAccessTokenFromCookie(dispatch, cookies);
    if (accessToken != null) {
  
      let url = `${API_GET_STORE_VENDOR}?store_id=${storeId}&page=${page}&count=${count}`

      if (filterBy != null) {
        if (filterBy.key != null && filterBy.key !== '' && filterBy.key !== undefined ) {
          url += `&filter=${filterBy.key}`

          if (filterBy.value != null && filterBy.value !== '' && filterBy.value !== undefined) {
            url += `&term=${filterBy.value}`
          }
        }
      }
  
      axios({
        method: "get",
        url,
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
      })
        .then((response) => {
          // handle success
          if (response.status === 200) {
            dispatch(setStoreVendor({ data: response.data }));
          } else {
            //
          }
          dispatch(setLoadingStatus({ status: false }));
          return true;
        })
        .catch((err) => {
          dispatch(setLoadingStatus({ status: false }));
        });
    } else {
      dispatch(setLoadingStatus({ status: false }));
      //
    }
  }
  
  export const updateStoreVendor = (dispatch, cookies, storeId, data) => {
    const accessToken = getAccessTokenFromCookie(dispatch, cookies);
    dispatch(setLoadingStatus({ status: true }));
    if (accessToken != null) {
      data.store_id = storeId;
      const payload = {
        "items" : []
      }
      payload.items.push(data);
      axios({
        method: "post",
        url: `${API_UPDATE_STORE_VENDOR}`,
        data: payload,
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
      }).then((response) => {
        // handle success
        if (response.status === 200) {
          if (response.data.success){
            dispatch(setSnackbarMessage({ 'message': STORE_VENDOR_UPDATE.SUCCESS, 'severity': SNACKBAR_SEVERITY.SUCCESS }));
            dispatch(setSnackbarVisibilityStatus({ 'status': true }));
            fetchStoreVendor(dispatch, cookies, storeId)
          }else{
            dispatch(setSnackbarMessage({ 'message': STORE_VENDOR_UPDATE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
            dispatch(setSnackbarVisibilityStatus({ 'status': true }));
          }
        } else {
          dispatch(setSnackbarMessage({ 'message': STORE_VENDOR_UPDATE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
          dispatch(setSnackbarVisibilityStatus({ 'status': true }));
        }
        dispatch(setLoadingStatus({ status: false }));
        return true;
      })
      .catch((err) => {
        dispatch(setLoadingStatus({ status: false }));
        dispatch(setSnackbarMessage({ 'message': STORE_VENDOR_UPDATE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
        dispatch(setSnackbarVisibilityStatus({ 'status': true }));
      });
    }
    dispatch(setLoadingStatus({ status: false }));
  }
  

  
  export const deleteStoreVendor = (dispatch, cookies, storeId, data) => {
    const accessToken = getAccessTokenFromCookie(dispatch, cookies);
    dispatch(setLoadingStatus({ status: true }));
    if (accessToken != null) {

      data.store_id = storeId;

      axios({
        method: "delete",
        url: `${API_DELETE_STORE_VENDOR}`,
        data,
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
      })
        .then((response) => {
          // handle success
          if (response.status === 200) {
  
            if (response.data.success){
              dispatch(setSnackbarMessage({ 'message': STORE_VENDOR_DELETE.SUCCESS, 'severity': SNACKBAR_SEVERITY.SUCCESS }));
              dispatch(setSnackbarVisibilityStatus({ 'status': true }));
              fetchStoreVendor(dispatch, cookies, storeId);
            }else{
              dispatch(setSnackbarMessage({ 'message': STORE_VENDOR_DELETE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
              dispatch(setSnackbarVisibilityStatus({ 'status': true }));
            }
          } else {
            dispatch(setSnackbarMessage({ 'message': STORE_VENDOR_DELETE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
            dispatch(setSnackbarVisibilityStatus({ 'status': true }));
          }
          dispatch(setLoadingStatus({ status: false }));
          return true;
        })
        .catch((err) => {
          dispatch(setLoadingStatus({ status: false }));
          dispatch(setSnackbarMessage({ 'message': STORE_VENDOR_DELETE.ERROR, 'severity': SNACKBAR_SEVERITY.ERROR }));
          dispatch(setSnackbarVisibilityStatus({ 'status': true }));
        });
    }
    dispatch(setLoadingStatus({ status: false }));
  }
  
  
export const fetchStoreVendorStats = (dispatch, cookies, storeId, filterBy = { key: '', value: '' } ) => {
  dispatch(setLoadingStatus({ status: true }));
  const accessToken = getAccessTokenFromCookie(dispatch, cookies);
  if (accessToken != null) {

    let url = `${API_GET_STORE_VENDOR_STATS}?store_id=${storeId}`

    if (filterBy != null) {
      if (filterBy.key != null && filterBy.key !== '' && filterBy.key !== undefined ) {
        url += `&filter=${filterBy.key}`

        if (filterBy.value != null && filterBy.value !== '' && filterBy.value !== undefined) {
          url += `&term=${filterBy.value}`
        }
      }
    }

    axios({
      method: "get",
      url,
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${accessToken}` },
    })
      .then((response) => {
        // handle success
        if (response.status === 200) {
          dispatch(setStoreVendorStats({ data: response.data }));
        } else {
          //
        }
        dispatch(setLoadingStatus({ status: false }));
        return true;
      })
      .catch((err) => {
        dispatch(setLoadingStatus({ status: false }));
      });
  } else {
    dispatch(setLoadingStatus({ status: false }));
    //
  }
}
