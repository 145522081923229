import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import * as Yup from 'yup';

// material
import { Grid, Button, Container, Stack, Typography, Card, Box, Tabs, Tab, TextField, SwipeableDrawer, CardHeader, CardContent } from '@mui/material';
import { useTheme } from '@mui/material/styles'; 
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { Form, FormikProvider, useFormik } from 'formik';
// components
import Page from '../../components/Page';  
import LogBook from '../../sections/coach/journal/LogBook';
import UserSummary from '../../sections/coach/user/UserSummary';
import { fetchActivitySchedule, fetchClientDetails, fetchClientList } from '../../data/network/client/api';
import { getActiveClient, getClientJournalEditDetail, getClientJournalEditStatus, getClientList, getClientSubscriptionPlanDetails, setClientJournal, setClientJournalEditDetail, setClientJournalEditStatus } from '../../store/coach/reducer'; 
import UserListCard from '../../sections/coach/journal/UserListCard';
import Iconify from '../../components/Iconify';
import { formatDate } from '../../utils/date';
import '../../assets/css/Journal.css';
import CreateJournalForm from '../../sections/coach/journal/CreateJournalForm';
import { getPartnerJournal } from '../../store/user/reducer';
import { createUserJournal, fetchPartnerJournalEntries, fetchUserDailyLog, fetchUserJournal } from '../../data/network/journal/api';

// ----------------------------------------------------------------------

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Journal() {
    const theme = useTheme();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
 
    const [showOverviewDashboard, setShowOverviewDashboard] = useState(false);
    const [showTimeline, setShowTimeline] = useState(true);
    const [showJournal, setShowJournal] = useState(false);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const startDate = searchParams.get("start_date");
    const endDate = searchParams.get("end_date");
    const clientId = searchParams.get("client_id");
    const journalId = searchParams.get("journal_id");

    const clientDetails = useSelector(state => getActiveClient(state));
    const clientList = useSelector(state => getClientList(state));
    const partnerJournalList = useSelector(state => getPartnerJournal(state));

    const subscribedPlanDetails = useSelector(state => getClientSubscriptionPlanDetails(state));

    const journalEditDetail = useSelector(state => getClientJournalEditDetail(state));
    const journalEditStatus = useSelector(state => getClientJournalEditStatus(state));

    const [journalDate, setJournalDate] =  useState(
        new Date() 
    );

    const [startDateValue, setStartDateValue] =  useState(
        new Date() 
    );

    const [endDateValue, setEndDateValue] =  useState(
        new Date() 
    );


    const toggleDrawer = (anchor , open ) => (event ) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event ).key === 'Tab' ||
            (event ).key === 'Shift')
        ) {
            return;
        }
        setIsDrawerOpen(open); 
    };
    
    const handleStartDateChange = (newValue ) => {
        setStartDateValue(newValue);
        setEndDateValue(newValue);
    };


    const handleEndDateChange = (newValue ) => {
        setEndDateValue(newValue);
    };

    const ProfileSchema = Yup.object().shape({
        title: Yup.string() ,
        description: Yup.string() ,
    });
  
    const formik = useFormik({
        initialValues: {
          title: '' ,
          description: '', 
      },
        validationSchema: ProfileSchema,
        onSubmit: () => {
  
        },
    }); 

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
    
    const handleJournalDateChange = (newValue ) => {
        setJournalDate(newValue); 
        fetchUserDailyLog(dispatch, cookies, {
            user_id: clientId,
            date: formatDate(newValue), 
        });

        fetchActivitySchedule(dispatch, cookies, {
            user_id : clientId,
            start_date : formatDate(newValue),
            end_date: formatDate(newValue),
        })
    };

    const handleDateChange = (type) => {
        const todayObj = new Date();

        const startDate = new Date(subscribedPlanDetails.start_date);
        const endDate = new Date(subscribedPlanDetails.end_date);

        const journalDateObj = new Date(journalDate);
        if(type === "prev"){
            if(startDate.setHours(0,0,0,0) < journalDateObj.setHours(0,0,0,0)){                
                journalDateObj.setDate(journalDateObj.getDate() - 1); 
            }
        }else if(type === "next"){
            if(endDate.setHours(0,0,0,0) > journalDateObj.setHours(0,0,0,0)){
                journalDateObj.setDate(journalDateObj.getDate() + 1);  
            }
        } 
        handleJournalDateChange(journalDateObj); 
    }

    const navigateToJournal = () => {
        if(journalEditDetail != null){
            formik.setFieldValue('title', journalEditDetail.title);
            formik.setFieldValue('description', journalEditDetail.description);

            setStartDateValue(new Date(journalEditDetail.from_date));
            setEndDateValue(new Date(journalEditDetail.to_date));
            
            fetchUserDailyLog(dispatch, cookies, {
                user_id: journalEditDetail.user.user_id,
                start_date: formatDate(new Date(journalEditDetail.from_date)), 
                end_date: formatDate(new Date(journalEditDetail.to_date)), 
            });

            setShowTimeline(false);
            setShowJournal(true);

            // navigate(`/coach/journal?client_id=${journalEditDetail.user.user_id}&journal_id=${journalEditDetail.id}`);
        }
    }

    useEffect(() => {
        console.log(12323);
        if(journalEditDetail != null){
            navigateToJournal();
        }
    },[journalEditDetail]);
    
    useEffect(() => { 
        console.log(555);
        if(partnerJournalList !== null && journalId !== null){
            partnerJournalList.forEach((element, i)=> {
                try{
                    if(element.id === parseInt(journalId,10)){

                        dispatch(setClientJournalEditDetail({data: element}));

                        if(i===0 && new Date(element.from_date).setHours(0,0,0,0) === new Date().setHours(0,0,0,0)){
                            dispatch(setClientJournalEditStatus({status: true}));
                        }
                    }    
                }catch(e){
                    //
                }
            });
        }
    },[partnerJournalList, journalId]);

    useEffect(() => {
        console.log(444);
        if(clientId == null){
            setShowOverviewDashboard(false);
            fetchClientList(dispatch, cookies);
            fetchPartnerJournalEntries(dispatch, cookies, []);
            setShowTimeline(true);
            setShowJournal(false);
        }else{
            setShowOverviewDashboard(false);
            fetchClientDetails(dispatch, cookies, clientId);
            fetchPartnerJournalEntries(dispatch, cookies, {client_id: clientId});
            if(journalId == null){
                setShowTimeline(true);
                setShowJournal(false);    
            }
        }
    },[clientId, journalId]);

    // useEffect(() => {
    //     if(clientDetails && clientDetails !== null && clientDetails !== undefined){
    //         fetchPartnerJournalEntries(dispatch, cookies, {
    //             client_id : clientDetails.user_id,
    //         });

    //         if(journalId == null){
    //             setShowTimeline(true);
    //             setShowJournal(false);
    //         }else{
    //             setShowTimeline(false);
    //             setShowJournal(true);
    //         }
    //     }
    // },[clientDetails])

    const handleCreateNewJournal = () => {
        setIsDrawerOpen(true);
    }
    
    const journalCreateCallback = (clientId, journalId) => {
        dispatch(setClientJournalEditStatus({status: true}));
        if(journalEditDetail !== null){
            navigate(`/coach/journal?client_id=${clientId}&journal_id=${journalId}`);
        }

        // setShowTimeline(false);
        // setShowJournal(true);
    };

    const handleUpdateJournal = () => {
        createUserJournal(dispatch, cookies, {
            follow_up_id: journalEditDetail ? journalEditDetail.id : '',
            title: formik.values.title,
            description: formik.values.description,
            start_date: formatDate(startDateValue),
            end_date: formatDate(endDateValue), 
            user_id: journalEditDetail.user.user_id
        });
    }

    const handleUserFilter = (e) => {
        const userId = e.target.value;

        if(userId !== "all"){
            fetchPartnerJournalEntries(dispatch, cookies, {
                "client_id" : userId
            });
        }else{
            fetchPartnerJournalEntries(dispatch, cookies, {});
        }

    }

    return (
        <Page title="Journal">
            <Container>
                { 
                    // (!showOverviewDashboard)
                    // ?  
                        <Grid container spacing={3}> 
                            <Box margin={2} />
                                {
                                    showTimeline
                                    ? 
                                    <Card className='glass-box' sx={{width:'100%'}}>
                                         
                                        <CardHeader title="Recent Entries" />

                                        <Grid container direction="row" justifyContent="space-between" padding={2}>

                                            <TextField 
                                                select
                                                label="Select User"
                                                onChange={handleUserFilter}
                                                // {...getFieldProps('user_id')}
                                                error={Boolean(touched.user_id && errors.user_id)}
                                                defaultValue = {clientId}
                                                helperText={touched.user_id && errors.user_id}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                            >
                                                <option key="all" value="all">
                                                    Show all
                                                </option>

                                                {

                                                    clientList && clientList.map((e, i) => (
                                                        <option key={i} value={e.id}>
                                                            {e.first_name} {e.last_name} - {e.subscription.meal_plan.parent_meal_plan.display_name}
                                                        </option>
                                                    ))
                                                }
                                            </TextField>

                                            <Button variant="contained" size="small" onClick={() => {
                                                console.log("222");
                                                handleCreateNewJournal();
                                            }}>Create new Entry</Button>
                                        </Grid>

                                        <CardContent
                                            sx={{
                                            '& .MuiTimelineItem-missingOppositeContent:before': {
                                                display: 'none',
                                            },
                                            }}
                                        >
                                            <Timeline>
                                                {partnerJournalList && partnerJournalList !==null && partnerJournalList.map((item, index) => (
                                                    <TimelineItem key={index}>
                                                        <TimelineSeparator>
                                                            <TimelineDot
                                                                color={
                                                                    'info'
                                                                // (type === 'order1' && 'primary') ||
                                                                // (type === 'order2' && 'success') ||
                                                                // (type === 'order3' && 'info') ||
                                                                // (type === 'order4' && 'warning') ||
                                                                // 'error'
                                                                }
                                                            />
                                                            {index === partnerJournalList.length - 1 ? null : <TimelineConnector />}
                                                        </TimelineSeparator>
                                                        
                                                        <TimelineContent>

                                                            <Card className='glass-box' sx={{padding:2}}>

                                                                <Grid container direction="column">
                                                                    <Grid item container direction="column">
                                                                        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                                                            {formatDate(new Date(item.followed_up_on), 'DD MMM YYYY HH:mm')}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item container direction="row" justifyContent="space-between">

                                                                        <Typography variant="subtitle2">{item.user.first_name} {item.user.last_name}</Typography> 

                                                                        <Button variant="outlined" onClick={(e) => {

                                                                            // if(partnerJournalList !== null){
                                                                            //     partnerJournalList.forEach(element => {
                                                                            //         if(element.id === item.id){
                                                                            //             dispatch(setClientJournalEditStatus({status: true}));
                                                                            //             dispatch(setClientJournalEditDetail({data: element}));
                                                                            //         }
                                                                            //     });
                                                                            // }

                                                                            // setShowTimeline(false);
                                                                            // setShowJournal(true);
            // navigate(`/coach/journal?client_id=${journalEditDetail.user.user_id}&journal_id=${journalEditDetail.id}`);

                                                                            navigate(`/coach/journal?client_id=${item.user.user_id}&journal_id=${item.id}`);
                                                                        }}>
                                                                            View
                                                                        </Button>
                                                                    </Grid>

                                                                    <Grid item container>
                                                                        <Typography variant="subtitle2">{item.title}</Typography>
                                                                    </Grid>

                                                                    <Grid item container>
                                                                        <Typography variant="caption">{item.description}</Typography> 
                                                                    </Grid>

                                                                    <Grid item container direction="row" justifyContent="space-between">
                                                                        <Grid item  >
                                                                            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                                                                {formatDate(new Date(item.from_date), 'DD MMM YYYY')}
                                                                            </Typography>
                                                                            <Typography variant="caption" px={2} sx={{ color: 'text.secondary' }}>
                                                                                -
                                                                            </Typography>
                                                                            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                                                                {formatDate(new Date(item.to_date), 'DD MMM YYYY')}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item >
                                                                            <Typography  variant="caption" px={2}>Activities Marked : {`${item.activities_marked.length}`}</Typography>
                                                                            
                                                                            <Typography variant="caption">Logs Marked : {`${item.logs_marked.length}`}</Typography>
                                                                        </Grid>
                                                                    </Grid>

                                                                </Grid>
                                                            </Card> 
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                    // <OrderItem key={item.id} item={item} isLast={index === partnerJournalList.length - 1} />
                                                ))}
                                            </Timeline>
                                            
                                        
                                        </CardContent>
                                    </Card>
                                    : null
                                }
                                <Box margin={2} />
                                
                                { showJournal
                                    ? <Grid container>
                                        <Button variant="text" startIcon={<Iconify icon="eva:arrow-back-outline" />} onClick={() => {
                                                // setShowTimeline(true);
                                                // setShowJournal(false);
                                                navigate(`/coach/journal`)
                                            }}>Go Back</Button>
                                        <Box margin={2} />


                                        <Box margin={2} />

                                        <Grid container direction="row" justifyContent="space-between">

                                            <Grid  className="glass-box" padding={2} container item direction="column" xs={12} md={12} lg={8.5} order={{ xs: 1, sm: 1 , md: 1, lg:0, xl:0}}> 
                                                <Grid item>
                                                    <Typography>Journal Details</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <FormikProvider value={formik}>
                                                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

                                                            <Grid  sx={{padding:2, borderRadius: 2}}>
                                                                <Grid container direction="column"  > 
                                                                    <Box margin={2} />
                                                                    <Grid item container direction="column" justifyContent="center">
                                                                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                                                            <TextField
                                                                                fullWidth
                                                                                disabled={!journalEditStatus}
                                                                                label="Title"
                                                                                {...getFieldProps('title')}
                                                                                error={Boolean(touched.title && errors.title)}
                                                                                helperText={touched.title && errors.title}
                                                                            />
                                                                        </Stack>
                                                                        <Box margin={2} />
                                                                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                                                            <TextField
                                                                                fullWidth
                                                                                disabled={!journalEditStatus}
                                                                                label="Description"
                                                                                {...getFieldProps('description')}
                                                                                error={Boolean(touched.description && errors.description)}
                                                                                helperText={touched.description && errors.description}
                                                                            />
                                                                        </Stack>
                                                                        <Box margin={2} />

                                                                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                                <MobileDatePicker
                                                                                    label="Start Date"
                                                                                    disabled={!journalEditStatus}
                                                                                    inputFormat="dd MMM yyyy"
                                                                                    value={startDateValue}
                                                                                    onChange={handleStartDateChange}
                                                                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                                                                />
                                                                            
                                                                            </LocalizationProvider>
                                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                                <MobileDatePicker
                                                                                    label="End Date"
                                                                                    disabled={!journalEditStatus}
                                                                                    inputFormat="dd MMM yyyy"
                                                                                    value={endDateValue}
                                                                                    onChange={handleEndDateChange}
                                                                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                                                                />
                                                                            
                                                                            </LocalizationProvider>
                                                                        </Stack>
                                                                        <Box margin={2} />
                                                                    </Grid>

                                                                    {
                                                                        journalEditStatus
                                                                        ? <Grid item container direction="row" justifyContent="center">
                                                                            
                                                                           
                                                                            <Button variant='contained' onClick={() => {
                                                                                handleUpdateJournal();
                                                                            }}>
                                                                                Update
                                                                            </Button>
                                                                        </Grid>
                                                                        : null
                                                                    }
                                                                    
                                                                </Grid>
                                                            </Grid>
                                                        </Form>
                                                    </FormikProvider> 
                                                </Grid>
                                            </Grid> 
                                            <Grid container item xs={12} md={12} lg={8} mt={1} order={{ xs: 1, sm: 1 , md: 1, lg:2, xl:2}}> 
                                                <LogBook journalDate={formatDate(journalDate, "yyyy-MM-DD")} />
                                            </Grid > 
                                            <Grid item xs={12} md={12} lg={3} order={{ xs: 1, sm: 1 , md: 1, lg:1, xl:1}}>
                                                <UserSummary />
                                            </Grid>
                                        </Grid>                                             
                                    </Grid>
                                    : null
                                }
                        </Grid>  
                }
                <SwipeableDrawer
                    anchor={'bottom'}
                    open={isDrawerOpen}
                    onClose={toggleDrawer( 'bottom', false)}
                    onOpen={toggleDrawer( 'bottom', true)}>
                    <CreateJournalForm drawerHandler={toggleDrawer('bottom', false)} createCallback={journalCreateCallback} />
                </SwipeableDrawer>
            </Container>
        </Page>
    );
}
