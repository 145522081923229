import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
// material
import { styled } from '@mui/material/styles';
import { Box, TextField, Typography, FormControl, MenuItem, Select, Button, IconButton } from '@mui/material';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// component
import Iconify from '../../../../components/Iconify';
import { getUserId, getActiveCorporateClient } from '../../../../store/user/reducer';
import { formatDate } from '../../../../utils/date';

// ----------------------------------------------------------------------
const StyledBox = styled(Box)(({ theme }) => ({
  height: 70,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '18px 22px',
  backgroundColor: '#fff',
  marginBottom: 20,
  boxShadow: '0px 2px 3px #0029700D',
  borderRadius: 10,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    height:'auto',
  },
  '& .box': {
    display: 'flex', 
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width:'100%',
    },
  },
  '.MuiInputBase-input, .MuiInputBase-input.MuiSelect-select': {
    padding: '0px 14px',
    height: '35px',
    lineHeight: '35px',
    fontSize: '12px',
    background: '#F4F8F8',
  },
  '.MuiButton-root': {
    marginLeft: 8,
  },
  '& .MuiFormControl-root': {
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
  }
}));


export default function FilterContainer({ filterKey, filterMethod }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterValue, setFilterValue] = useState({ selectedValue: '', searchType: '' });
  const [searchValue, setSearchValue] = useState('');

  const dispatch = useDispatch();
  const partnerId = useSelector(state => getUserId(state));
  const activeClient = useSelector(state => getActiveCorporateClient(state));
  const [cookies] = useCookies(['mobile', 'session', 'access_token']);

  const handleStartDate = (newValue) => {
    setStartDate(formatDate(newValue));
  };
  const handleEndDate = (newValue) => {
    setEndDate(formatDate(newValue));
  };
  const handleFilterChange = (ele) => {
    setFilterValue({ ...filterValue, searchType: ele.search_type, selectedValue: ele.key });
  };
  const handleFilterValue = (event) => {
    setSearchValue(event.target.value);
  };
  const handleSearch = () => {
    const filterBy = { key: filterValue.selectedValue, value: searchValue, startDate, endDate }
    if (partnerId != null) {
      filterMethod(dispatch, cookies, 1, searchValue);
    }
  }
  const clearFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setFilterValue({ selectedValue: '', searchType: '' });
    setSearchValue('');
    if (partnerId != null) {
      filterMethod(dispatch, cookies, 1, '', {key: '', value: '', startDate: '', endDate: ''});
    }
  }

  return (
    <StyledBox>
      <Box className='box'>
        {/* <Typography variant='caption'>Filter By</Typography> */}
        {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            value={filterValue?.selectedValue}
            // onChange={handleFilterChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >{filterKey?.map((ele) => {
            return <MenuItem key={ele.key} value={ele.key} onClick={() => handleFilterChange(ele)}>{ele.label}</MenuItem>
          })}

          </Select>
        </FormControl> */}
        {/* {filterValue.searchType === 'dropdown' ?
          <Select
            value={searchValue}
            onChange={handleFilterValue}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{width: 100}}
          >{filterKey?.map((ele) => {
            return ele?.allowed_values.map((filter) => {
              return <MenuItem key={filter.key} value={filter.key} >{filter.label}</MenuItem>
            })
          })}
          </Select>
          : */}
          <TextField placeholder='Search' onChange={handleFilterValue} value={searchValue} />
        {/* } */}

        <Button variant="contained" onClick={handleSearch}>Search</Button>
        <Button variant="text" onClick={clearFilters}>Reset Filter <Iconify sx={{ml: 1}} icon='pajamas:clear-all' /></Button>
      </Box>
      {/* <Box className='box'>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            inputFormat="YYYY/MM/DD"
            value={startDate}
            onChange={handleStartDate}
            renderInput={(params) => <TextField {...params} sx={{ width: 150 }} />}
          />
          <DesktopDatePicker
            inputFormat="YYYY/MM/DD"
            value={endDate}
            onChange={handleEndDate}
            renderInput={(params) => <TextField {...params} sx={{ width: 150 }} />}
          />
          <Button variant="contained" onClick={handleSearch}>Go</Button>
        </LocalizationProvider>
      </Box> */}
    </StyledBox>
  );
}
