import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';

import { useSelector } from 'react-redux';
// @mui
import { Card, CardHeader, Box, Container, Typography } from '@mui/material';
// components

import { BaseOptionChart } from '../../../components/chart';
import { getActiveClientReport } from '../../../store/coach/reducer';

// ----------------------------------------------------------------------
 
export default function NutritionChart( {data} ) {

  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  
  // const clientReport = useSelector(state => getActiveClientReport(state));

  useEffect(() => {

    setChartLabels([
      'Protein',
      'Carbs',
      'Fat',
      'Fibre',
    ]);

    let proteinValue = 0;
    let carbsValue = 0;
    let fatValue = 0;
    let fiberValue = 0;
    let calorieValue = 0;


    if(data && data !== null){ 
      data.nutrition.forEach(element => {
        if(element.name === "Protein"){
          proteinValue = element.value;
        }
        if(element.name === "Carbohydrates"){
          carbsValue = element.value;
        }
        if(element.name === "Fat"){
          fatValue = element.value;
        }
        if(element.name === "Calorie"){
          calorieValue = element.value;
        }
        if(element.name === "Fiber"){
          fiberValue = element.value;
        }
      });
    }

    setChartData( [proteinValue, carbsValue, fatValue, fiberValue] );

  },[]);

  const chartOptions = merge(BaseOptionChart(), {
    // plotOptions: { bar: { columnWidth: '16%' } },
    // fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    // xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} gms`;
          }
          return y;
        },
      },
    },     
    colors: [
      '#22ab7d',
      '#9480da',
      '#ff8497',
      '#4eb2fc'
    ],
    legend: {
      position: 'bottom'
    }
  });

  return (
    <Box className=" w-100" >
      <Typography variant="body1">Consumption</Typography>
      <Box  dir="ltr">
        <ReactApexChart type="donut" series={chartData} options={chartOptions} height={200} />
      </Box>
    </Box>
  );
}
