import { createSlice } from '@reduxjs/toolkit';
import { formatDate } from '../../utils/date';
import {STATUS, PAYMENT_STATUS, PLAN_SELECTION_STATUS, PAYMENT_MODE, MEAL_TYPE,
  MEAL_PREFERENCE, ORDER_TYPE, CLIENT_MEAL_SCHEDULE_EDIT_STATUS,
  CLIENT_ACTIVITY_SCHEDULE_EDIT_STATUS
} from '../../constants/app_constants';

const corporateSlice = createSlice({
  name: 'corporate',
  initialState: {
    userList: [],
    invoiceList: [],
    transactionList: [],
    report: null,
    varietyReport: [],
    feedbackList: [],
    feedbackStats: [],
    orderList: [], 
    mealSchedule: [],
    mealMenu: [],
    mealList : [],
  },
  reducers: {
    setCorporateUserList(state, action) {
      state.userList = action.payload.data;
    },
    setCorporateInvoiceList(state, action) {
        state.invoiceList = action.payload.data;
    },
    setCorporateTransactionList(state, action) {
    state.transactionList = action.payload.data;
    },
    setCorporateReport(state, action) {
        state.report = action.payload.data;
    },
    setCorporateVarietyReport(state, action) {
      state.varietyReport = action.payload.data;
    },
    setCorporateFeedback(state, action) {
      state.feedbackList = action.payload.data.list;
      state.feedbackStats = action.payload.data.stats;
    }, 
    setCorporateOrder(state, action) {
      state.orderList = action.payload.data;
    }, 
    setCorporateMealSchedule(state, action) {
      state.mealSchedule = action.payload.data;
    },
    setCorporateMealMenu(state, action) {
      state.mealMenu = action.payload.data;
    },
    setCorporateMealList(state, action) {
      state.mealList = action.payload.data;
    },
    resetCorporateState(state, action){
      state.userList = [];
      state.invoiceList = [];
      state.transactionList =  [];
      state.report = null;
      state.feedbackList = [];
      state.feedbackStats = [];
      state.orderList = [];
      state.mealSchedule = [];
      state.varietyReport = [];
      state.mealMenu = [];
      state.mealList = [];
    }, 
  }
})

export const getCorporateUserList = (state) => {

  if(state.corporate != null && state.corporate !== undefined){
    return state.corporate.userList;
  }
  return [];
}

export const getCorporateInvoiceList = (state) => {

    if(state.corporate != null && state.corporate !== undefined){
      return state.corporate.invoiceList;
    }
    return [];
}

export const getCorporateTransactionList = (state) => {

    if(state.corporate != null && state.corporate !== undefined){
      return state.corporate.transactionList;
    }
    return [];
}
  
export const getCorporateReport = (state) => {

  if(state.corporate != null && state.corporate !== undefined){
    return state.corporate.report;
  }
  return null;
}

export const getCorporateVarietyReport = (state) => {

  if(state.corporate != null && state.corporate !== undefined){
    return state.corporate.varietyReport;
  }
  return null;
}

export const getCorporateFeedback = (state) => {

    if(state.corporate != null && state.corporate !== undefined){
      return state.corporate.feedbackList;
    }
    return null;
}
 

export const getCorporateFeedbackStats = (state) => {

  if(state.corporate != null && state.corporate !== undefined){
    return state.corporate.feedbackStats;
  }
  return null;
}

export const getCorporateOrder = (state) =>  {

  if(state.corporate != null && state.corporate !== undefined){
    return state.corporate.orderList;
  }
  return null;
}

export const getCorporateMealSchedule = (state) => {
  if(state.corporate != null && state.corporate !== undefined){
    return state.corporate.mealSchedule;
  }
  return null;
}

export const getCorporateMealMenu = (state) => {
  if(state.corporate != null && state.corporate !== undefined){
    return state.corporate.mealMenu;
  }
  return null;
}

export const getCorporateMealList = (state) => {
  if(state.corporate != null && state.corporate !== undefined){
    return state.corporate.mealList;
  }
  return null;
}
 
export const { setCorporateUserList, setCorporateInvoiceList, setCorporateTransactionList, 
  setCorporateReport, setCorporateFeedback,resetCorporateState, setCorporateOrder, setCorporateMealSchedule,
   setCorporateVarietyReport, setCorporateMealMenu, setCorporateMealList } = corporateSlice.actions

export default corporateSlice.reducer