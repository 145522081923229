import { AppBar, Card, Container, Dialog, DialogContent, Grid, IconButton, Toolbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Iconify from "../../../components/Iconify";
import BuildForm from "../../../utils/form_builder";

export default function UserProfileForm({profileCategory, element, handleClose}) {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [totalIndex, setTotalIndex] = useState(0);

    const handleCategoryPrevious = () => {

    }

    const handleCategoryNext = () => {

    }

    useEffect(() => {
        console.log(profileCategory);
        console.log(element);
        setTotalIndex(profileCategory.data.length);

        const p = profileCategory.data.find(e => e.key === element.key);
        const i = profileCategory.data.findIndex(e => e.key === element.key);

        console.log(p);
        console.log(i);

        setCurrentIndex(i+1);
        console.log("------");
    },[]);
    

    return (
        <Container>
            <Dialog 
                fullWidth="lg"
                maxWidth="lg"
                open
                onClose={handleClose}
                PaperProps={{
                sx: {
                    minHeight: '50vh',
                    maxHeight: '90vh'
                }
                }}
                // sx={{height:'90vh'}}
                // sx={{height:'80vh', width:'80%', margin:'10%', overflowY:'auto' }}
                >

                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Grid container flexDirection={'column'} justifyContent={'flex-start'} padding={1}>
                            <Grid container item flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Grid item>
                                    <Typography variant="h3">
                                        {profileCategory.label}
                                    </Typography>
                                </Grid>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <Iconify  icon="eva:close-circle-outline" sx={{height:40, width:40, cursor:'pointer'}}/>
                                </IconButton>
                            </Grid>
                            <Grid>

                                <Grid container item flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                                    <Typography variant="body1" sx={{marginRight:1}}>
                                        {`Current viewing ${currentIndex} of ${totalIndex}`}
                                    </Typography>
                                    <Card onClick={handleCategoryPrevious} sx={{paddingRight:1, paddingLeft:1, marginRight: 1,cursor: 'pointer', color: '#000'}}>
                                        <Iconify icon={'fa:chevron-left'} sx={{fontSize: '8px'}} />
                                    </Card>

                                    <Card ref={handleCategoryNext} sx={{paddingRight:1, paddingLeft:1, marginRight: 1, cursor: 'pointer', color: '#000'}}>
                                        <Iconify icon={'fa:chevron-right'} sx={{fontSize: '8px'}} />
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                
                <DialogContent sx={{backgroundColor: element.bg_color}}> 
                    <Grid container flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} columnGap={2}>
                        <Grid item backgroundColor={element.bg_color} borderRadius={20}>
                            {
                                element.img 
                                ? <img src={element.img} alt={element.label} width={48} height={48} borderRadius={20} />
                                : <img src={'https://png.pngtree.com/png-vector/20190328/ourmid/pngtree-vector-health-icon-png-image_872129.jpg'}
                                        alt={element.label} width={48} height={48}  borderRadius={20}/>
                            }
                        </Grid>
                        <Grid item>
                            <Typography variant="h3">
                                {element.label}
                            </Typography>
                        </Grid>
                    </Grid>
                    {
                        element.terms && 
                        element.terms.map((e) => {
                            return <Grid container>
                                <BuildForm data={e} />
                                {/* _buildForm(e); */}
                            </Grid> 
                        })
                    }

                    <Grid container justifyContent={'center'} alignItems={'center'}>
                        <Card sx={{width:'150px'}}>
                            <Grid padding={1} container flexDirection={'row'} justifyContent={'center'} alignItems={'center'} columnGap={2}>
                                <Typography variant="body1">
                                    Save
                                </Typography>
                                <Iconify  icon="eva:arrow-forward-outline" sx={{height:16, width:16, cursor:'pointer'}}/>
                            </Grid>
                        </Card>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Container>
    );

                }