import { useMemo, useEffect } from 'react';
import { useTable } from 'react-table';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid,Card,Stack, Button,  Container, Typography, TableHead, TableRow, TableCell, Checkbox, TableSortLabel, Box } from '@mui/material';
import { useNavigate , Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';

// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import { formatDate } from '../../utils/date';
import CorporatePendingInvoice from '../../sections/corporate/profile/PendingInvoices';
import CorporateTransaction from '../../sections/corporate/profile/Transactions';
import { getActiveCorporateClient, getLoginStatus } from '../../store/user/reducer';
import { getSession } from '../../data/network/session/api';
// sections

// ----------------------------------------------------------------------

export default function CorporateInvoice() {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
  const isLoggedIn = useSelector(state => getLoginStatus(state));
  const theme = useTheme();

  const navigate = useNavigate();
  useEffect(() =>{
    if(!isLoggedIn){
      navigate('/');
    }
  },[isLoggedIn])


  const activeCorporateClient = useSelector(state => getActiveCorporateClient(state));
 
  const data = useMemo(
    () => [
      {
        col1: 'Hello',
        col2: 'World',
      },
      {
        col1: 'react-table',
        col2: 'rocks',
      },
      {
        col1: 'whatever',
        col2: 'you want',
      },
    ],
    []
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Column 1',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Column 2',
        accessor: 'col2',
      },
    ],
    []
  )

  const tableInstance = useTable({ columns, data })

  return (
    <Page title="Invoices">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome to Bytes
        </Typography>

        <Card sx={{padding: 4}} className="glass-box">
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                    Invoices
                </Typography>
                {/* <Button variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:edit-outline" />}>
                    Edit
                </Button> */}
            </Stack>
            <Grid className="corporate-invoice-container"  container padding ={0}>      
              {/* {} */}
            </Grid>
        </Card>
      </Container>
    </Page>
  );
}
