/* eslint-disable camelcase */
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Grid,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Pagination,
  Drawer,
  Box,
  Button,
  TextField,
  Modal,
} from '@mui/material';
import { useCookies } from 'react-cookie';

import { useDispatch, useSelector } from 'react-redux';
import { getUserId, getActiveCorporateClient } from '../../store/user/reducer';
import { getCorporateUserList } from '../../store/corporate/reducer';
import { fetchCorporateUsers, deleteCorporateUser } from '../../data/network/corporate/api';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, FilterContainer } from '../../sections/@dashboard/user';
import { CorporateUserDetail } from './CorporateUserDetail'
// mock
import { getAge } from '../../utils/date';
import { calculateBmi } from '../../utils/calculateBmi';
import ExportCSV from '../../components/ExportCSV';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: '#', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'mobile', label: 'Mobile', alignRight: false },
  { id: 'age', label: 'Age', alignRight: false },
  { id: 'gender', label: 'Gender', alignRight: false },
  { id: 'bmi', label: 'BMI', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  if (array !== undefined && array != null && array.length > 0) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(array, (_user) => _user.first_name && _user.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
  }

  return [];
}

export default function CorporateUser() {
  const [openModal, setModalOpen] = useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const USERLIST = useSelector(state => getCorporateUserList(state));
  const dispatch = useDispatch();
  const partnerId = useSelector(state => getUserId(state));
  const activeClient = useSelector(state => getActiveCorporateClient(state));
  const [cookies] = useCookies(['mobile', 'session', 'access_token']);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (partnerId != null) {
      fetchCorporateUsers(dispatch, cookies, partnerId, activeClient, page);
    }
  }, [partnerId, page, activeClient])


  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleDelete = (id) => {
    const payload = {
      corporate_id: activeClient?.id,
      users: [
        {
          id
        },
      ]
    }
    deleteCorporateUser(dispatch, cookies, payload);
    handleClose();
  };

  const filteredUsers = applySortFilter(USERLIST.data, getComparator(order, orderBy), filterName);
  const isUserNotFound = filteredUsers.length === 0;

  const headers = [
    { label: "Id", key: "id" },
    { label: "Name", key: "name" },
    { label: "Mobile", key: "mobile" },
    { label: "Email", key: "email" },
    { label: "Date of Birth", key: "dob" },
    { label: "Gender", key: "gender" },
    { label: "Height (in cms)", key: "height" },
    { label: "Weight (in kg)", key: "weight" },
  ];

  function userData() {
    const data = [];
    if (USERLIST.data) {
      for (let i = 0; i < USERLIST.data.length; i += 1) {
        data[i] = {
          'id': `${USERLIST.data[i].id}`,
          'name': `${USERLIST.data[i].first_name} ${USERLIST.data[i].last_name}`,
          'mobile': USERLIST.data[i].mobile,
          'email': USERLIST.data[i].email,
          'dob': USERLIST.data[i].dob,
          'gender': USERLIST.data[i].gender,
          'height': USERLIST.data[i].height,
          'weight': USERLIST.data[i].weight,
        };
      }
    }
    return data;
  }

  return (
    <Page title="User">
      <Container maxWidth="xl">
        <Box margin={4} />
        <Grid container justifyContent="space-between" sx={{ pb: 2 }}>
          <Typography variant="h4" gutterBottom>
            {/* All Users */}
          </Typography>
          <ExportCSV csvHeaders={headers}
            csvData={userData()}
            fileName="users.csv" />
        </Grid>
        <FilterContainer filterKey={USERLIST.filter_params} filterMethod={fetchCorporateUsers} />
        <Card className="glass-box">
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, minHeight: 300 }}>
              {
                USERLIST.data &&
                  USERLIST.data.length > 0
                  ?
                  <Table size='small'>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={USERLIST.data.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {USERLIST.data.map((row) => {
                        const { id, first_name, last_name, mobile, dob, gender, weight, height } = row;
                        const age = getAge(dob);
                        const bmi = calculateBmi(weight, height);
                        const isItemSelected = selected.indexOf(mobile) !== -1;

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {id}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="center">{first_name} {last_name}</TableCell>
                            <TableCell align="center">{mobile}</TableCell>
                            <TableCell align="center">{age}</TableCell>
                            <TableCell align="center">{gender}</TableCell>
                            <TableCell align="center">{bmi}</TableCell>
                            <TableCell align="center">
                              <Button style={{ padding: '2px 10px', minWidth: 'auto', fontSize: '12px', marginRight: '5px' }} size='small' color='info' variant='contained'
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedInvoice(row)
                                }}
                              >View</Button>
                              <Button style={{ padding: '2px 10px', minWidth: 'auto', fontSize: '12px' }} size='small' color='error' variant='contained'
                                onClick={handleOpen}
                              >
                                Delete</Button>
                              <CorporateUserDetail data={selectedInvoice} visible={open} setOpen={setOpen} />
                            </TableCell>
                            <Modal
                              open={openModal}
                              onClose={handleClose}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <Box sx={style}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                  Are you sure you want to delete ?
                                </Typography>
                                <Box id="modal-modal-description" sx={{ mt: 2 }}>
                                  <Button sx={{ mr: 1 }} color='error' variant='contained'
                                    onClick={() => handleDelete(id)}
                                  >
                                    Delete</Button>
                                  <Button variant='contained' onClick={handleClose}>Cancel</Button>
                                </Box>

                              </Box>
                            </Modal>
                          </TableRow>
                        );
                      })}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                  : <Typography variant='h6'  sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 4}}>No Data Available</Typography>
              }
            </TableContainer>
          </Scrollbar>
        </Card>
        <Pagination count={USERLIST?.pagination?.total_pages} variant="outlined" shape="rounded" onChange={handleChangePage} />

      </Container>
    </Page>
  );
}
