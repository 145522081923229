
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Stack, Typography, Box, TextField, Select, MenuItem, Button, styled, DialogTitle, Dialog } from '@mui/material';
import { useCookies } from 'react-cookie';
import { formatDate } from '../../../utils/date';
import { updateStoreVendor } from '../../../data/network/store/vendor';
import { getActiveStoreId } from '../../../store/partner_store/reducer';
import { VENDOR_PAYMENT_TYPE } from '../../../constants/app_constants';

const TextFieldStyle = styled(TextField)(() => ({
    '& .MuiInputBase-input': {
        padding: '8px 14px',
    }
}));

const SelectStyle = styled(Select)(() => ({
    '& .MuiSelect-select': {
        padding: '8px 14px',
    }
}));


export const StoreVendorDetail = ({ data, visible, setOpen }) => {
    const [editEnable, setEditEnable] = useState(true);
    const [vendorData, setVendorData] = useState({});
    const [date, setDate] = useState(null);

    const dispatch = useDispatch();
    const [cookies] = useCookies(['mobile', 'session', 'access_token']);

    const storeId = useSelector((state) => getActiveStoreId(state));

    useEffect(() => {
        setVendorData(data);
    }, [data])

    const handleChange = (event) => {
        setVendorData({ ...vendorData, [event.target.name]: event.target.value })
    }

    const handleDate = (newValue) => {
        setDate(formatDate(newValue));
        setVendorData({ ...vendorData, dob: formatDate(newValue) })
    }

    const handleUpdate = () => {
        updateStoreVendor(dispatch, cookies, storeId, vendorData);
        // updateCorporateUser(dispatch, cookies, userData, activeClient)
        setOpen(false);
    }
    return (
        <Dialog  
            // anchor='right'
            open={visible}
            onClose={() => setOpen(false)}
            maxWidth={'md'}
            fullWidth
        >
            <DialogTitle>
                <Box sx={{ pb: 2 }} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='h6'>Vendor Detail</Typography>
                    <Button size='small' color='info' onClick={() => setEditEnable(false)}>Edit</Button>
                </Box>
            </DialogTitle>
            <Stack sx={{ width: 'md' }} style={{ padding: '20px', gap: '10px' }}>
                {/* <Box sx={{ pb: 2 }} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='h6'>Detail View</Typography>
                    <Button size='small' color='info' onClick={() => setEditEnable(false)}>Edit</Button>
                </Box> */}
                
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Name</Typography>
                    <TextFieldStyle disabled={editEnable} name='display_name' value={vendorData?.display_name} onChange={handleChange} />
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Legal Name</Typography>
                    <TextFieldStyle disabled={editEnable} name='legal_name' value={vendorData?.legal_name} onChange={handleChange} />
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>GSTIN</Typography>
                    <TextFieldStyle disabled={editEnable} name='gstin' value={vendorData?.gstin} onChange={handleChange} />
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Address</Typography>
                    <TextFieldStyle disabled={editEnable} name='address' value={vendorData?.address} onChange={handleChange} />
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>City</Typography>
                    <TextFieldStyle disabled={editEnable} name='city' value={vendorData?.city} onChange={handleChange} />
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>State</Typography>
                    <TextFieldStyle disabled={editEnable} name='state' value={vendorData?.state} onChange={handleChange} />
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Payment Type</Typography>
                    <SelectStyle
                        value={vendorData?.payment_type ?? ""}
                        onChange={handleChange}
                        displayEmpty
                        disabled={editEnable}
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{ width: 220 }}
                        name="payment_type"
                    >
                        {
                            Object.keys(VENDOR_PAYMENT_TYPE).map((e, i) => {
                                return <MenuItem key={i} value={e}>{e}</MenuItem>
                            })
                        }
                    </SelectStyle>
                    {/* <TextFieldStyle disabled={editEnable} name='primary_unit' value={stockData?.primary_unit} onChange={handleChange} /> */}
                </Box>
                {/* <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Category</Typography>
                    <TextFieldStyle disabled={editEnable} name='category' value={vendorData?.category} onChange={handleChange} />
                </Box> */}

                {/* TODO : Provide upload file button  */}
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Agreement Doc</Typography>
                    <TextFieldStyle disabled={editEnable} name='agreement_doc' value={vendorData?.agreement_doc} onChange={handleChange} />
                </Box>

                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Agreement Start Date</Typography>
                    <TextFieldStyle disabled={editEnable} name='agreement_start_date' value={vendorData?.agreement_start_date} onChange={handleChange} />
                </Box>

                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Agreement End Date</Typography>
                    <TextFieldStyle disabled={editEnable} name='agreement_end_date' value={vendorData?.agreement_end_date} onChange={handleChange} />
                </Box>


                {<Button sx={{ mt: 2, pt: 1, pb: 1 }} size='small' variant='contained' onClick={handleUpdate}>Update</Button>}
            </Stack>
        </Dialog>
    )

}