import { useState, useEffect,useRef } from "react";
import { Box, Button, Paper, Card, Grid, Modal, Stack, TextField, styled, IconButton, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Collapse, Stepper, Step, StepLabel, TablePagination, TableFooter } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { subDays } from "date-fns";
import { getIcon } from "@iconify/react";

import ExportCSV from "../../../components/ExportCSV";
import Scrollbar from "../../../components/Scrollbar";
import FilterContainer from "./FilterContainer";
import { StoreVendorDetail } from "./StoreVendorDetail";
import { formatDate } from "../../../utils/date";
import { deleteStoreVendor, fetchStoreVendor, fetchStoreVendorStats } from "../../../data/network/store/vendor";
import { getActiveStoreId, getStoreVendor, getStoreVendorStats } from "../../../store/partner_store/reducer";

export const StoreVendorList = () => {

    const StackStyled = styled(Stack)(({ theme }) => ({
        background: '#fff',
        padding: '20px',
        borderRadius: '10px',
        [theme.breakpoints.down('md')]: {
            flexWrap: 'wrap',
            gap: 20,
            flexDirection: 'column'
        },
        '& .MuiBox-root': {
            [theme.breakpoints.down('md')]: {
                marginLeft: 0,
                border: 'none',
                flexWrap: 'wrap',
            },
            display: 'flex',
            alignItems: 'center',
            flexBasis: '30%',
            '&:not(:last-child)': {
                borderRight: '1px solid #ccc',
                [theme.breakpoints.down('md')]: {
                    borderRight: 'none',
                },
            },
            '& .icon': {
                fontSize: '3rem',
                marginRight: '20px',
                '& .MuiBox-root': {
                    border: 'none',
                }
            },
            '& .details': {
                display: 'flex',
                flexDirection: 'column',
                '& .MuiTypography-caption': {
                    textTransform: 'uppercase',
                }
            }
        },
        '& .MuiOutlinedInput-input': {
            padding: '10px 14px',
        }
    }))

    const headers = [
        { label: "Id", key: "id" },
        { label: "Name", key: "name" },
        { label: "Display Name", key: "display_name" },
        { label: "Legal Name", key: "legal_name" },
        { label: "Address", key: "address" },
        { label: "City", key: "city" },
        { label: "State", key: "state" },
        { label: "GSTIN", key: "gstin" },
        { label: "Payment Type", key: "payment_type" },
        { label: "Agreement Start Date", key: "agreement_start_date" },
        { label: "Agreement End Date", key: "agreement_end_date" },
        { label: "Agreement Link", key: "agreement_doc" },
        { label: "Status", key: "status" },
    ];

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [openModal, setModalOpen] = useState(false);
    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(10);
    const [open, setOpen] = useState(false);  
    const [selectedRow, setSelectedRow] = useState(null);
    const [filter, setFilter] = useState('');
    const [addVendorOpen, setAddVendorOpen] = useState(false);
    const [addVendorDetails, setAddVendorDetails] = useState(null);

    const tableRef = useRef();
    const _paginationRef = useRef();

    const dispatch = useDispatch();
    const [cookies] = useCookies(['mobile', 'access_token', 'session']);

    const handleSearch = () => {
        setPage(1);
        fetchStoreVendor(dispatch, cookies,storeId, 1, count, filter);
        fetchStoreVendorStats(dispatch, cookies,storeId, filter);
    }

    const handleDelete = () => {
        const id = selectedRow.id;
        const payload = {
          vendor_id: id
        }
        deleteStoreVendor(dispatch, cookies, storeId, payload);
        // deleteCorporateUser(dispatch, cookies, payload);
        handleClose();
    };

    
    const handleAddVendorClick = () => {
        setAddVendorDetails(null);
        setAddVendorOpen(true);
    }

    function Row(props) {
        const { row } = props;
        const [rowExpanded, setRowExpanded] = useState(false);
      
        const id = row.id;
        const displayName = row.display_name;
        const legalName = row.legal_name;
        const address = row.address;
        const city = row.city;
        const paymentType = row.payment_type;
        const agreementDoc = row.agreement_doc;
        const status = row.status;
        const gstin = row.gstin;
        const agreementStartDate = row.agreement_start_date;
        const agreementEndDate = row.agreement_end_date;

        return (
          <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
              
                <TableCell component="th" scope="row">
                    {id}
                </TableCell>
                <TableCell align="center">
                    {displayName}
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setRowExpanded(!rowExpanded)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="center">
                    {legalName}
                </TableCell>
                <TableCell align="center">{city}</TableCell>
                <TableCell align="center">{paymentType} </TableCell>
                <TableCell align="center">{status}</TableCell>
                {/* <TableCell align="center"><a href={agreementDoc} rel="noreferrer" target="_blank">Click here</a></TableCell> */}
                <TableCell align="center">
                    <Button style={{ padding: '2px 10px', minWidth: 'auto', fontSize: '12px', marginRight: '5px' }} size='small' color='info' variant='contained'
                        onClick={() => {
                        setOpen(true);
                        setSelectedRow(row);
                    }}
                    >Edit</Button>
                    
                    <Button style={{ padding: '2px 10px', minWidth: 'auto', fontSize: '12px', marginRight: '5px' }} size='small' color='warning' variant='contained'
                        onClick={() => {
                            setSelectedRow(row);
                            handleOpen()
                        }}
                    >Delete</Button>
                    
                    <StoreVendorDetail data={selectedRow} visible={open} setOpen={setOpen} />
                </TableCell>
                <Modal
                    open={openModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Are you sure you want to delete ?
                        </Typography>
                        <Box id="modal-modal-description" sx={{ mt: 2 }}>
                            <Button sx={{ mr: 1 }} color='error' variant='contained'
                            onClick={() => handleDelete()}
                            >
                            Delete</Button>
                            <Button variant='contained' onClick={handleClose}>Cancel</Button>
                        </Box>

                    </Box>
                </Modal>
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                <Collapse in={rowExpanded} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1, marginLeft: 3, backgroundColor: '#ffffffcc', padding:1 }}>
                    <Grid container direction="row" justifyContent={'flex-start'} alignItems={'center'} columnSpacing={4}>
                        <Typography variant="body1">Address : </Typography>
                        <Typography variant="body1">{address}</Typography>
                    </Grid>
                    <Grid container direction="row" justifyContent={'flex-start'} alignItems={'center'} columnSpacing={4}>
                        <Typography variant="body1">GSTIN : </Typography>
                        <Typography variant="body1">{gstin}</Typography>
                    </Grid>
                    <Grid container direction="row" justifyContent={'flex-start'} alignItems={'center'} columnSpacing={4}>
                        <Typography variant="body1">Agreement Start Date : </Typography>
                        <Typography variant="body1">{agreementStartDate}</Typography>
                    </Grid>
                    <Grid container direction="row" justifyContent={'flex-start'} alignItems={'center'} columnSpacing={4}>
                        <Typography variant="body1">Agreement End Date : </Typography>
                        <Typography variant="body1">{agreementEndDate}</Typography>
                    </Grid>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </>
        );
    }

    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
        fetchStoreVendor(dispatch, cookies,storeId, newPage, count, filter);
    };

    const handleFilterApiCall = (filterBy) => {
        setFilter(filterBy);
        setPage(1);
        fetchStoreVendor(dispatch, cookies,storeId, 1, count, filterBy);
        fetchStoreVendorStats(dispatch, cookies,storeId, filterBy);
    }

    const VENDORLIST = useSelector((state) => getStoreVendor(state));
    const VENDORSTATS = useSelector((state) => getStoreVendorStats(state));
    
    const storeId = useSelector((state) => getActiveStoreId(state));

    const vendorData = () => {
        const data = [];
        if (VENDORLIST && VENDORLIST.data) {
          for (let i = 0; i < VENDORLIST.data.length; i += 1) {
            data[i] = {
              'id': `${VENDORLIST.data[i].id}`,
              'name': `${VENDORLIST.data[i].name}`,
              'display_name': VENDORLIST.data[i].display_name,
              'legal_name': VENDORLIST.data[i].legal_name,
              'address': VENDORLIST.data[i].address,
              'city': VENDORLIST.data[i].city,
              'state': VENDORLIST.data[i].state,
              'gstin' : VENDORLIST.data[i].gstin,
              'payment_type': VENDORLIST.data[i].payment_type,
              'agreement_start_date': VENDORLIST.data[i].agreement_start_date,
              'agreement_end_date': VENDORLIST.data[i].agreement_end_date,
              'agreement_doc': VENDORLIST.data[i].agreement_doc,
              'status': VENDORLIST.data[i].status,
            };
          }
        }
        return data;
    }

    useEffect(() => {
        fetchStoreVendor(dispatch, cookies,storeId, page, count, filter);
        fetchStoreVendorStats(dispatch, cookies,storeId, filter);
    },[]);

    return (
        <>
        <Box sx={{ pb: 1, pt:2 }} />
            <StackStyled className="glass-box" direction='row' spacing={2}>
                {VENDORSTATS && VENDORSTATS.data && VENDORSTATS.data.map((ele) => (
                    <Box key={ele?.label}>
                        <div className='icon'>
                            {getIcon(ele?.label)}
                        </div>
                        <div className='details'>
                            <Typography variant='h6'>{ele?.value}</Typography>
                            <Typography variant='caption'>{ele?.label}</Typography>
                        </div>
                    </Box>
                )
                )}
            </StackStyled>
            <Box sx={{ pb: 1, pt:2 }} />
            <Grid container justifyContent="space-between" sx={{ pb: 2 }}>
                <Typography variant="h4" gutterBottom>
                    Vendor
                </Typography>
                {/* <ImportCSV csvHeaders={headers}
                    csvData={orderData()}
                    fileName="orders.csv" /> */}
                <Grid>
                    <Button variant="contained" onClick={handleAddVendorClick}>
                        <Typography variant="body1">Add Vendor</Typography>
                    </Button>
                    <StoreVendorDetail data={addVendorDetails} visible={addVendorOpen} setOpen={setAddVendorOpen} editable create />
                    
                    <ExportCSV csvHeaders={headers}
                        csvData={vendorData()}
                        fileName="vendors.csv" />
                </Grid>
            </Grid>
            {VENDORLIST && <FilterContainer filterKey={VENDORLIST.filter_params} filterMethod={handleFilterApiCall} />}

            <Card className="glass-box">
                <Scrollbar>

                <TableContainer className="glass-box" >
                    {
                        VENDORLIST &&
                        VENDORLIST.data &&
                        VENDORLIST.data.length > 0
                        ?
                            <Table className="glass-box" ref={tableRef} size="small" aria-label="collapsible table">
                                <TableHead className="glass-box">
                                    <TableRow>
                                        <TableCell />
                                        <TableCell align="center">Name</TableCell>
                                        <TableCell align="center">Legal Name</TableCell>
                                        <TableCell align="center">City</TableCell>
                                        <TableCell align="center">Payment Type</TableCell>
                                        {/* <TableCell align="center">Agreement</TableCell> */}
                                        <TableCell align="center">Status</TableCell>
                                        <TableCell align="center">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="glass-box">
                                {VENDORLIST.data &&
                                VENDORLIST.data.length > 0 && VENDORLIST.data.map((row) => (
                                    <Row key={row.id} row={row} />
                                ))
                                }
                                </TableBody>
                            </Table>
                        : <Typography variant='h6' sx={{display: 'flex', justifyContent: 'center'}}>No Data Available</Typography>
                    }   
                    </TableContainer>

                </Scrollbar>
            </Card>
            {VENDORLIST && <Pagination ref={_paginationRef} page={page} count={VENDORLIST?.pagination?.total_pages} variant="outlined" shape="rounded" onChange={handleChangePage} />}

        </>

    );
}