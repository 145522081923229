import { faker } from '@faker-js/faker';
import { useTheme } from '@mui/material/styles';
import { Grid,Card,Stack, Button,  Container, Typography } from '@mui/material';
import { useNavigate , Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'; 
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import SnackbarContent from '@mui/material/SnackbarContent';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// components
import Page from '../../components/Page';
import { fetchUserDetails } from '../../data/network/user/api';
import { getLoginStatus, getUser, setActiveRole, getActiveRole, getUserId, getActiveCorporateClient } from '../../store/user/reducer';
import { getSession } from '../../data/network/session/api';
import {ROLES, SNACKBAR_SEVERITY, STATUS} from '../../constants/app_constants';
import { getCorporateMealSchedule } from '../../store/corporate/reducer';
import { fetchCorporateMealSchedule } from '../../data/network/corporate/api';
import { setSnackbarMessage, setSnackbarVisibilityStatus, setSnackbarAutoHideDuration } from '../../store/layout/reducer';


// sections

// ----------------------------------------------------------------------

export default function CorporateSpashScreen() {
    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const navigate = useNavigate();
    const isLoggedIn = useSelector(state => getLoginStatus(state));
    const userDetails = useSelector(state => getUser(state));
    const activeRole = useSelector(state => getActiveRole(state));
    const [isRoleListVisible, setIsRoleListVisible] = useState(false); 
    // console.log(userDetails);
  
    useEffect(() =>{ 
      getSession(dispatch, cookies);
    },[])
  
    useEffect(() =>{
      if(!isLoggedIn){
        dispatch(setActiveRole({role: userDetails.roles[0]}));
        navigate('/');
      }else{
        fetchUserDetails(dispatch, cookies);
      }
    },[isLoggedIn])

    const activeCorporateClient = useSelector(state => getActiveCorporateClient(state));
    const partnerId = useSelector(state => getUserId(state));
    const todayDate = new Date().toISOString("en-US", "Asia/Delhi").replace(/T.*$/, '');

    useEffect(() => {
        if (partnerId != null) {
            fetchCorporateMealSchedule(dispatch, cookies, partnerId, activeCorporateClient, todayDate, todayDate);
    }}, [partnerId, activeCorporateClient])

    const mealSchedule = useSelector(state => getCorporateMealSchedule(state));
    const splashNotifications = []

    useEffect(() => {
        if (mealSchedule) {
            for (let i = 0; i < mealSchedule.length; i += 1) {
                if (mealSchedule[i].menu_options) {
                    const menuOptions = mealSchedule[i].menu_options;
                    for (let j = 0; j < menuOptions.length; j += 1) {
                        if (menuOptions[j].current_status && menuOptions[j].current_status.status) {
                            splashNotifications.push(`Today schedule for ${mealSchedule[i].service_label} has been ${menuOptions[j].current_status.status}`)
                        }
                    }
                }
            }
          }
          navigate('/corporate/calendar');
        }, [mealSchedule, activeCorporateClient])

    const setInititalRole = (selectedOption) => {
        setIsRoleListVisible(false);
        dispatch(setActiveRole({role: selectedOption}));
        if(Object.keys(ROLES)){
            Object.keys(ROLES).forEach(element => {
              if(ROLES[element].name === selectedOption.name){
                navigate(ROLES[element].path);
              }
            });
          }
    };

    const handleRoleChange = (selectedOption) => {
        setIsRoleListVisible(false);
        dispatch(setActiveRole({role: selectedOption}));
    
        if(Object.keys(ROLES)){
          Object.keys(ROLES).forEach(element => {
            if(ROLES[element].name === selectedOption.name){
              navigate(ROLES[element].path);
            }
          });
        }
      };

  return (
    <Page title="FlashScreen">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5, mt: 10 }}>
          Hi, Welcome to Bytes
        </Typography>

        <Card sx={{padding: 4}} className="glass-box">
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                    Logging You In....Please Wait!
                </Typography>
            </Stack>
            <ToggleButtonGroup
            color="primary"
            value={activeRole.name}
            exclusive
            onChange={handleRoleChange}
            aria-label="Platform">
                {
                    userDetails && userDetails.roles && userDetails.roles.map((role , idx) => {
                      return ROLES[role.name] && <ToggleButton key={idx} value={ROLES[role.name]}>{role.display_name} </ToggleButton>
                    }
                  )
            }
            </ToggleButtonGroup>
        </Card>
      </Container>
    </Page>
  );
}
