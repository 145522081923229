import { Button } from "@mui/material";
import React from "react";
import { CSVLink } from "react-csv";

const ExportCSV = ({ csvHeaders, csvData, fileName }) => (
  <CSVLink headers={csvHeaders} data={csvData} filename={fileName}>
    <Button variant="contained">Export CSV</Button>
  </CSVLink>
);

export default ExportCSV;
