import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'user',
  initialState: {
    accessToken: null,
    session: null,
    isLoggedIn: false,
    userDetails: null,
    userId:null,
    activeRole: null,
    userName: null,
    activeCorporateClient: null,
    journal: [],
    dish: [],
    meals: [],
    userMeta: {},
    editMetaCurrentIndex : 0,
    editMetaCurrentCategory : null
  },
  reducers: {
    setUser(state,action) {
      state.userDetails = action.payload.data; 
      state.userId = action.payload.data.id;
    },
    setUserId(state,action) {
      state.userId = action.payload.data; 
    },
    setUserName(state,action) {
      state.userName = action.payload.data; 
    },
    setAccessToken(state, action) {
      state.accessToken = action.payload.data;
    },
    setSessionDetails(state, action) {
      state.session = action.payload.data;
    },
    setLoginStatus(state,action) {
      state.isLoggedIn = action.payload.data; 
    },
    setUserMeta(state,action) {
      state.userMeta = action.payload.data; 
    },
    updateUserMeta(state,action) {      
      const categoryKey = action.payload.data.category_key;
      console.log(action.payload.data);
      // state.userMeta['category_key'] = action.payload.data.category_data;
    },
    logoutUser(state, action){
      state.accessToken = "";
      state.session = "";
      state.isLoggedIn = false;
      state.userId = "";
      state.userName = "";
      state.userDetails = {};
      state.activeCorporateClient = null;
      state.activeRole = null;
      state.userMeta = {};
      state.editMetaCurrentCategory = null;
      state.editMetaCurrentIndex = 0;
      state.meals = [];
    },
    setActiveCorporateClient(state, action){
      state.activeCorporateClient = action.payload.client; 
    },
    setActiveRole(state, action){ 
      state.activeRole = action.payload.role; 
    },
    setPartnerJournal(state,action){
      console.log(action.payload.data);
      state.journal = action.payload.data;
    },
    setPartnerDish(state,action){
      state.dish = action.payload.data;
    },
    setPartnerMealList(state,action){
      state.meals = action.payload.data;
    },
    setPartnerEditMetaCurrentCategory(state,action){
      state.dish = action.payload.data;
    },
    setPartnerEditMetaCurrentIndex(state,action){
      state.dish = action.payload.data;
    }
  }
})

export const getAccessToken = (state) => {
  if(state.user != null && state.user !== undefined){
    return state.user.accessToken;
  }
  return null;
}

export const getSessionDetails = (state) => {

  if(state.user != null && state.user !== undefined){
    return state.user.session;
  }
  return null;
}

export const getUser = (state) => {
  if(state.user != null && state.user !== undefined){
    return state.user.userDetails;
  }
  return null;
}

export const getUserId = (state) => {
  if(state.user != null && state.user !== undefined){
    return state.user.userId;
  }
  return null;
}

export const getUserName = (state) => {
  if(state.user != null && state.user !== undefined &&  state.user.userDetails !== null){
    return state.user.userDetails.user_name;
  }
  return null;
}

export const getLoginStatus = (state) => {

  if(state.user != null && state.user !== undefined){
      return state.user.isLoggedIn;
    // return (state.user.session != null && state.user.session!==undefined);
  }
  return false;
}

export const getActiveCorporateClient = (state) => {

  if(state.user != null && state.user !== undefined){
      return state.user.activeCorporateClient;
    // return (state.user.session != null && state.user.session!==undefined);
  }
  return false;
}

export const getActiveRole = (state) => {

  if(state.user != null && state.user !== undefined){
      return state.user.activeRole;
    // return (state.user.session != null && state.user.session!==undefined);
  }
  return false;
}

export const getPartnerJournal = (state) => {

  if(state.user != null && state.user !== undefined){

    return state.user.journal;
    // return (state.user.session != null && state.user.session!==undefined);
  }
  return [];
}

export const getPartnerDish = (state) => {

  if(state.user != null && state.user !== undefined){
    return state.user.dish;
  }
  return [];
}

export const getPartnerMealList = (state) => {

  if(state.user != null && state.user !== undefined){
    return state.user.meals;
  }
  return [];
}

export const getUserMeta = (state) => {

  if(state.user != null && state.user !== undefined){
    return state.user.dish;
  }
  return [];
}

export const getPartnerDishFiltered = (state) => {
  if (state.user != null && state !== undefined) {
    return state.user.dish;
  }

  return [];
}

export const { setAccessToken, setSessionDetails, setLoginStatus, setPartnerDish, setPartnerMealList,
  setUser, setUserName, setUserId, logoutUser,setActiveCorporateClient, setActiveRole, setPartnerJournal,
  setUserMeta, updateUserMeta
} = userSlice.actions
export default userSlice.reducer