import { useState, useEffect } from 'react';
import { Typography, Container, Grid } from "@mui/material";

import Page from '../../components/Page';
import Iconify from '../../components/Iconify';

export default function CorporateTermConditions() {
    return (
        <Page title="Profile">
            <Container maxWidth="xl">
            <Grid container justifyContent="" padding={2}>
            <Iconify icon='iconoir:privacy-policy' width={30} height={30}/>
                <Typography variant="h4" sx={{ mb: 5, ml: 5}}>
                    Terms and Conditions
                </Typography>
                </Grid>
            </Container>
        </Page>
    );
}