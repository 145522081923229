export const LifestyleOptions = {
    label : 'Lifestyle',
    key: 'lifestyle',    
    data: [
        {
            'key': 'smoking',
            'label' : 'Smoking',
            'description' : '',
            'img' : '',
            'bg_color' : '#F8DEBD',
            'terms' : [
                {
                    'key': 'frequency',
                    'label' : 'Frequenct',
                    'value_type' : 'int',
                    'default_value' : ''
                }
            ],
            'last_updated': '',
            'severity': 'Normal',
        },
        {
            'key': 'alcohol',
            'label' : 'Alcohol',
            'description' : '',
            'img' : '',
            'bg_color' : '#F8DEBD',
            'terms' : [
                {
                    'key': 'frequency',
                    'label' : 'Frequenct',
                    'value_type' : 'int',
                    'default_value' : ''
                }
            ],
            'last_updated': '',
            'severity': '',
        },
        {
            'key': 'kind_of_work',
            'label' : 'Kind of Work',
            'description' : '',
            'img' : '',
            'bg_color' : '#F8DEBD',
            'terms' : [
                {
                    'key': 'type',
                    'label' : 'Select type of work',
                    'value_type' : 'option',
                    'min_option_allowed' : 0,
                    'max_option_allowed' : 1,
                    'default_value' : '',
                    'options' : [
                        {
                            'key': '',
                            'label': ''
                        }
                    ]
                }
            ],
            'last_updated': '',
            'severity': '',
        },
        {
            'key': 'posture',
            'label' : 'Posture',
            'description' : '',
            'img' : '',
            'bg_color' : '#F8DEBD',
            'terms' : [
                {
                    'key': 'type',
                    'label' : 'Type',
                    'value_type' : 'option',
                    'min_option_allowed' : 0,
                    'max_option_allowed' : 1,
                    'default_value' : '',
                    'options' : [
                        {
                            'key' : 'improper_seating',
                            'label': 'Improper Seating',
                        },
                        {
                            'key' : 'continuous_sitting',
                            'label': 'Continuous Sitting',
                        },
                        {
                            'key' : 'continuous_standing',
                            'label': 'Continuous Standing',
                        },
                        {
                            'key' : 'improper_workspace',
                            'label': 'Improper Workspace',
                        },
                    ]
                }
            ],
            'last_updated': '',
            'severity': '',
        },
        {
            'key': 'work_timings',
            'label' : 'Work Timings',
            'description' : '',
            'img' : '',
            'bg_color' : '#F8DEBD',
            'terms' : [
                {
                    'key': 'start_time',
                    'label' : 'Start Time',
                    'value_type' : 'text',
                    'default_value' : ''
                },
                {
                    'key': 'end_time',
                    'label' : 'End Time',
                    'value_type' : 'text',
                    'default_value' : ''
                }
            ],
            'last_updated': '',
            'severity': '',
        },
        {
            'key': 'shifts',
            'label' : 'Shifts',
            'description' : '',
            'img' : '',
            'bg_color' : '#F8DEBD',
            'terms' : [
                {
                    'key': 'start_time',
                    'label' : 'Start Time',
                    'value_type' : 'text',
                    'default_value' : ''
                },
                {
                    'key': 'end_time',
                    'label' : 'End Time',
                    'value_type' : 'text',
                    'default_value' : ''
                }
            ],
            'last_updated': '',
            'severity': '',
        },
        {
            'key': 'sleep_duration',
            'label' : 'Duration of sleep',
            'description' : '',
            'img' : '',
            'bg_color' : '#F8DEBD',
            'terms' : [
                {
                    'key': 'duration',
                    'label' : 'Duration (in hrs)',
                    'value_type' : 'int',
                    'default_value' : ''
                }
            ],
            'last_updated': '',
            'severity': '',
        },
        {
            'key': 'physical_activity',
            'label' : 'Physical Activity',
            'description' : '',
            'img' : '',
            'bg_color' : '#F8DEBD',
            'terms' : [
                {
                    'key': 'activity_type',
                    'label' : 'Type of activity',
                    'value_type' : 'text',
                    'default_value' : ''
                },
                {
                    'key': 'duration',
                    'label' : 'Duration (in mins)',
                    'value_type' : 'int',
                    'default_value' : ''
                },
                {
                    'key': 'start_time',
                    'label' : 'Start Time',
                    'value_type' : 'text',
                    'default_value' : ''
                },
                {
                    'key': 'frequency',
                    'label' : 'Frequency',
                    'value_type' : 'int',
                    'default_value' : ''
                }
            ],
            'last_updated': '',
            'severity': '',
        },
        {
            'key': 'stress',
            'label' : 'Stress',
            'description' : '',
            'img' : '',
            'bg_color' : '#F8DEBD',
            'terms' : [
                {
                    'key': 'level',
                    'label' : 'Level',
                    'value_type' : 'option',
                    'min_option_allowed' : 0,
                    'max_option_allowed' : 1,
                    'default_value' : '',
                    'options' : [
                        {
                            'key' : 'light',
                            'label' : 'Light'
                        },
                        {
                            'key' : 'moderate',
                            'label' : 'Moderate'
                        },
                        {
                            'key' : 'severe',
                            'label' : 'Severe'
                        }
                    ]
                }
            ],
            'last_updated': '',
            'severity': '',
        },
        {
            'key': 'travel',
            'label' : 'Travel',
            'description' : '',
            'img' : '',
            'bg_color' : '#F8DEBD',
            'terms' : [
                {
                    'key': 'frequency',
                    'label' : 'Frequency',
                    'value_type' : 'option',
                    'min_option_allowed' : 0,
                    'max_option_allowed' : 1,
                    'default_value' : '',
                    'options' : [
                        {
                            'key' : 'once_a_week',
                            'label' : 'Once a Week',
                        },
                        {
                            'key' : 'once_a_month',
                            'label' : 'Once a Month',
                        },
                        {
                            'key' : 'once_in_6_months',
                            'label' : 'Once in 6 months'
                        },
                        {
                            'key' : 'once_in_an_year',
                            'label' : 'Once in an year'
                        },
                    ]
                }
            ],
            'last_updated': '',
            'severity': '',
        },
        {
            'key': 'living_situation',
            'label' : 'Living Situation',
            'description' : '',
            'img' : '',
            'bg_color' : '#F8DEBD',
            'terms' : [
                {
                    'key': 'living situation',
                    'label' : 'Select an option',
                    'value_type' : 'option',
                    'min_option_allowed' : 0,
                    'max_option_allowed' : 1,
                    'default_value' : '',
                    'options' : [
                        {
                            'key' : 'lives_with_family',
                            'label' : 'Lives with family',
                        },
                        {
                            'key' : 'pg',
                            'label' : 'PG',
                        },
                        {
                            'key' : 'lives_alone',
                            'label' : 'Lives alone'
                        },
                    ]
                }
            ],
            'last_updated': '',
            'severity': '',
        },
        {
            'key': 'on_waking_up',
            'label' : 'On Wake up',
            'description' : '',
            'img' : '',
            'bg_color' : '#F8DEBD',
            'terms' : [
                {
                    'key': 'frequency',
                    'label' : 'Frequenct',
                    'value_type' : 'int',
                    'default_value' : ''
                }
            ],
            'last_updated': '',
            'severity': '',
        },
        {
            'key': 'breakfast',
            'label' : 'Breakfast',
            'description' : '',
            'img' : '',
            'bg_color' : '#F8DEBD',
            'terms' : [
                {
                    'key': 'time',
                    'label' : 'Timing',
                    'value_type' : 'text',
                    'default_value' : ''
                },
                {
                    'key': 'food',
                    'label' : 'Food',
                    'value_type' : 'option',
                    'default_value' : '',
                    'min_option_allowed': 0,
                    'max_option_allowed' : -1,
                    'options' : [
                        {
                            'key': '',
                            'label': ''
                        }
                    ]
                }
            ],
            'last_updated': '',
            'severity': '',
        },
        {
            'key': 'Mid morning / Pre-lunch',
            'label' : 'Mid morning / Pre-lunch',
            'description' : '',
            'img' : '',
            'bg_color' : '#F8DEBD',
            'terms' : [
                {
                    'key': 'time',
                    'label' : 'Timing',
                    'value_type' : 'text',
                    'default_value' : ''
                },
                {
                    'key': 'food',
                    'label' : 'Food',
                    'value_type' : 'option',
                    'default_value' : '',
                    'min_option_allowed': 0,
                    'max_option_allowed' : -1,
                    'options' : [
                        {
                            'key': '',
                            'label': ''
                        }
                    ]
                }
            ],
            'last_updated': '',
            'severity': '',
        },
        {
            'key': 'lunch',
            'label' : 'Lunch',
            'description' : '',
            'img' : '',
            'bg_color' : '#F8DEBD',
            'terms' : [
                {
                    'key': 'time',
                    'label' : 'Timing',
                    'value_type' : 'text',
                    'default_value' : ''
                },
                {
                    'key': 'food',
                    'label' : 'Food',
                    'value_type' : 'option',
                    'default_value' : '',
                    'min_option_allowed': 0,
                    'max_option_allowed' : -1,
                    'options' : [
                        {
                            'key': '',
                            'label': ''
                        }
                    ]
                }
            ],
            'last_updated': '',
            'severity': '',
        },
        {
            'key': 'tea_time',
            'label' : 'Tea Time',
            'description' : '',
            'img' : '',
            'bg_color' : '#F8DEBD',
            'terms' : [
                {
                    'key': 'time',
                    'label' : 'Timing',
                    'value_type' : 'text',
                    'default_value' : ''
                },
            ],
            'last_updated': '',
            'severity': '',
        },
        {
            'key': 'snacks',
            'label' : 'Evening snacks',
            'description' : '',
            'img' : '',
            'bg_color' : '#F8DEBD',
            'terms' : [
                {
                    'key': 'time',
                    'label' : 'Timing',
                    'value_type' : 'text',
                    'default_value' : ''
                },
                {
                    'key': 'food',
                    'label' : 'Food',
                    'value_type' : 'option',
                    'default_value' : '',
                    'min_option_allowed': 0,
                    'max_option_allowed' : -1,
                    'options' : [
                        {
                            'key': '',
                            'label': ''
                        }
                    ]
                }
            ],
            'last_updated': '',
            'severity': '',
        },
        {
            'key': 'dinner',
            'label' : 'Dinner',
            'description' : '',
            'img' : '',
            'bg_color' : '#F8DEBD',
            'terms' : [
                {
                    'key': 'time',
                    'label' : 'Timing',
                    'value_type' : 'text',
                    'default_value' : ''
                },
                {
                    'key': 'food',
                    'label' : 'Food',
                    'value_type' : 'option',
                    'default_value' : '',
                    'min_option_allowed': 0,
                    'max_option_allowed' : -1,
                    'options' : [
                        {
                            'key': '',
                            'label': ''
                        }
                    ]
                }
            ],
            'last_updated': '',
            'severity': '',
        },
        {
            'key': 'bed_time',
            'label' : 'Bed Time',
            'description' : '',
            'img' : '',
            'bg_color' : '#F8DEBD',
            'terms' : [
                {
                    'key': 'time',
                    'label' : 'Timing',
                    'value_type' : 'text',
                    'default_value' : ''
                }
            ],
            'last_updated': '',
            'severity': '',
        },
    ]
}