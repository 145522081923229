import { useState } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import Page from "../../../components/Page";
import { StaffDetail } from "./StaffDetail";

export const AddStoreStaff = () => {

    const [open, setOpen] = useState(false);
    const [staffData, setStaffData] = useState(false);

    const handleAddBtnClick = () => {
        setOpen(true);

        setStaffData({
            'name': '',
            'mobile' : '',
            'role' : '',
            'is_active' : true
        })

    }

    return (
        <>
            <Button variant="contained" onClick={handleAddBtnClick}>
                <Typography variant="body1">Add Staff</Typography>
                <StaffDetail data={staffData} visible={open} setOpen={setOpen} />
            </Button>
        </>
    );
}