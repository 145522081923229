import { useEffect } from 'react';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Card } from '@mui/material';
import { useNavigate } from 'react-router-dom';
 
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import ActivityList from '../../sections/coach/journal/activity/ActivityList'; 
// sections


// ----------------------------------------------------------------------

export default function PartnerActivity() {
  const theme = useTheme();

  const navigate = useNavigate();
 

  return (
    <Page title="Activity">
      <Container maxWidth="xl"> 
        <Card sx={{padding: 4, marginTop: 4}}  className="glass-box"> 
            <ActivityList />
        </Card>
      </Container>
    </Page>
  );
}
