 // @mui
import { useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Card, CardHeader, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';

import { getActiveClientReport } from '../../../store/coach/reducer';
 // ----------------------------------------------------------------------
 
 export default function UpcomingMealList() {

    const clientReport = useSelector(state => getActiveClientReport(state));

    const [data, setData] = useState([]);

    useEffect(() => {
        const _data = [];
        if(clientReport && clientReport !== null && clientReport.upcoming_meals !== null){
            clientReport.upcoming_meals.forEach((element, i) => {
                if(i<5){
                    _data.push(element);
                }
            })
        }
        setData(_data);
    },[clientReport])
 
     return (
         <Card className="glass-box " style={{minHeight:'500px'}}>
             <CardHeader title="Upcoming Meals" />
 
             <TableContainer >
                 <Table aria-label="upcoming-meals">
                     <TableHead>
                         <TableRow>
                             <TableCell> </TableCell>
                             <TableCell align="right">Meal Type</TableCell>
                             <TableCell align="right">Dish</TableCell>
                         </TableRow>
                     </TableHead>
                     <TableBody>
                         {data.map((row) => (
                             <TableRow
                             key={row.id}
                             sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                             >
                             <TableCell component="th" scope="row" sx={{width:'25%'}}>
                                 {row.start}
                             </TableCell>
                             <TableCell align="center" sx={{width:'25%'}}>{row.meal_type_name}</TableCell>
                             <TableCell align="center" sx={{width:'50%'}}>{row.display_name}</TableCell> 
                             </TableRow>
                         ))}
                     </TableBody>
                 </Table>
             </TableContainer>
         </Card>
     );
 }
 