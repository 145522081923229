import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui

import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Card, CardHeader, Grid, Box, Typography, Button, SwipeableDrawer, FormControl, FormLabel, RadioGroup } from '@mui/material';
// components
import { NutritionChart } from './chart';

import Iconify from '../../../../components/Iconify';
import { getActiveClient, getClientJournalEditDetail, getClientJournalEditStatus, getClientMealSchedule } from '../../../../store/coach/reducer';
import ExternalMealForm from './food/ExternalMealForm';
import CreateJournalForm from '../CreateJournalForm';
import { setLoadingStatus} from '../../../../store/layout/reducer';
import { fetchMealPlanSchedule} from '../../../../data/network/client/api';
import { formatDate } from '../../../../utils/date'; 

// ----------------------------------------------------------------------
 
FoodTab.propTypes  = {
    journalDate : PropTypes.string,
}

export default function FoodTab({journalDate} ) {
   
    const [isCreateDrawerOpen, setIsCreateDrawerOpen] = useState(false);
    const [forceSave, setForceSave] = useState(false);
    const [scheduledMeals, setScheduledMeals] = useState([]);
    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const activeClient = useSelector(state => getActiveClient(state));
    const mealSchedule = useSelector(state => getClientMealSchedule(state));     

    const clientJournalEditDetail = useSelector(state => getClientJournalEditDetail(state));
    const clientJournalEditStatus = useSelector(state => getClientJournalEditStatus(state));

    const [isJournalDrawerOpen, setIsJournalDrawerOpen] = useState(false);
    const toggleJournalDrawer = (anchor , open ) => (event ) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event ).key === 'Tab' ||
            (event ).key === 'Shift')
        ) {
            return;
        }
    
        setIsJournalDrawerOpen(open); 
    };

    const journalCreateCallback = () => {
        dispatch(setLoadingStatus({status: true}));
        setTimeout(() => {
            setForceSave(true);
           
            // handleSave();
        },1500);
    }


    useEffect(() => {
        if(clientJournalEditDetail !== null && forceSave){
            setIsCreateDrawerOpen(true);
        }
    },[clientJournalEditDetail, forceSave])

    const toggleMealConsumptionStatus = (meal) => {

    }

    useEffect(() =>{

        setScheduledMeals([]);

        fetchMealPlanSchedule(dispatch, cookies, {
            user_id: activeClient.user_id,
            // "start_date" : formatDate(clientJournalEditDetail.from_date, "yyyy-MM-DD"),
            // "end_date" : formatDate(clientJournalEditDetail.to_date, "yyyy-MM-DD"), 
        });
    },[clientJournalEditDetail]) 

    useEffect(() => { 
        const scheduledMealList = [];
        console.log(clientJournalEditDetail);
        if(mealSchedule != null){ 
          mealSchedule.forEach(element => {
            if(
                new Date(element.start).setHours(0,0,0,0) >= new Date(clientJournalEditDetail.from_date).setHours(0,0,0,0)
                && new Date(element.start).setHours(0,0,0,0) <= new Date(clientJournalEditDetail.to_date).setHours(0,0,0,0)
            
            ){
                scheduledMealList.push(element)
            }
          });
        }

        

        setScheduledMeals(scheduledMealList) 
      },[mealSchedule]);  

    const showCreateModal =  ()=> {
        if(clientJournalEditDetail && clientJournalEditDetail !== null && clientJournalEditDetail.id !== null){
            setIsCreateDrawerOpen(true);
        }else{
            setIsJournalDrawerOpen(true);
        }
    }

    const toggleCreateModal = (anchor , open ) => (event ) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event ).key === 'Tab' ||
            (event ).key === 'Shift')
        ) {
            return;
        }
    
        setIsCreateDrawerOpen(open); 
    };

    return (

        <Grid container spacing={3} direction="column">
            <Grid item container>
                <Card className='glass-box w-100'>
                    {
                    clientJournalEditStatus
                    ? <Grid container justifyContent="space-between" alignItems="center">
                        <CardHeader title="Log Data" />
                        <Grid item margin={2}>
                                <Button variant="contained" onClick={(a) => {
                                    showCreateModal( );
                                }}>
                                    Add meals
                                </Button>
                            </Grid>
                        </Grid>
                    : null
                    }
                    <Box margin={2} />
                    <Grid container justifyContent="center" alignItems="center" px={4}>
                            {
                                scheduledMeals && scheduledMeals.map(e => 
                                    <Grid className="glass-box w-100" padding={2} mt={2} container item direction="row" key={e.id} justifyContent="space-between" alignItems="center">
                                        <Grid item   style={{borderRadius:0, padding:4, marginRight:20, width:80}}>
                                            <Typography variant="span" >{formatDate(new Date(e.start), "DD MMM YYYY")}</Typography>
                                        </Grid>
                                        
                                        <Grid item   style={{borderRadius:0, padding:4, marginRight:20, width:80}}>
                                            <Typography variant="span" >{e.meal_type_name}</Typography>
                                        </Grid>
                                        
                                        <Grid item>
                                            <Typography variant="span">{e.display_name}</Typography>
                                        </Grid>
                                         
                                    </Grid>
                                )
                            }

                            {
                                scheduledMeals && scheduledMeals.length === 0
                                && <Grid padding={2} mt={2} container item direction="row" justifyContent="center" alignItems="center">                                        
                                    <Typography variant="span" >No meals to display</Typography>
                                </Grid>
                            }
                        </Grid>
                        <Box margin={2} />
                </Card>
            </Grid>
            <SwipeableDrawer
                anchor={'bottom'}
                open={isCreateDrawerOpen}
                onClose={toggleCreateModal( 'bottom', false)}
                onOpen={toggleCreateModal( 'bottom', true)}
            >
                <Grid padding={2} sx={{minHeight: 400}}>
                    <Typography variant="h5">
                        Add External Meal
                    </Typography>

                    <Box margin={2} />
                    <ExternalMealForm date={journalDate} handleClose={toggleCreateModal('bottom', false)} />    
                </Grid>
            </SwipeableDrawer>
             
            <SwipeableDrawer
                anchor={'bottom'}
                open={isJournalDrawerOpen}
                onClose={toggleJournalDrawer( 'bottom', false)}
                onOpen={toggleJournalDrawer( 'bottom', true)}>
                <CreateJournalForm drawerHandler={toggleJournalDrawer('bottom', false)} createCallback={journalCreateCallback} />
            </SwipeableDrawer>
        </Grid>
    );
}
