import { createSlice } from '@reduxjs/toolkit';
import { formatDate } from '../../utils/date';
import {SNACKBAR_SEVERITY, STATUS } from '../../constants/app_constants';
import { MEAL_CREATION_FORM_STEPS } from '../../constants/meal_constants';

const mealSlice = createSlice({
  name: 'meal',
  initialState: {
    currentStep: MEAL_CREATION_FORM_STEPS.BASIC_DETAILS,
    mealList: [],
    selectedMeal: null,
    isMealDetailModalVisible : false,
  },
  reducers: {
    setMealCreationCurrentStep(state, action){
      state.currentStep = action.payload.step;
    },
    setMealList(state, action){
      state.mealList = action.payload.data;
    },
    setSelectedMeal(state, action){
      state.selectedMeal = action.payload.data;
    },
    addDishToSelectedMeal(state,action){
      try{
        if (state.selectedMeal != null) {
          const _selectedMeal = state.selectedMeal;

          _selectedMeal.dishes.push(action.payload.data);

          state.selectedMeal = _selectedMeal;
        }
      }catch(e){
        console.log(e);
      }
    },
    removeDishFromSelectedMeal(state,action){
      try{
        if (state.selectedMeal != null) {
          const _selectedDishes = []
          try{
            state.selectedMeal.dishes.forEach(element => {
              if (element.id !== action.payload.data.id) {
                _selectedDishes.push(element);
              }
            });
          }catch(e){
            console.log(e);  
          }
          
          state.selectedMeal.dishes = _selectedDishes;
        }
      }catch(e){
        console.log(e);
      }
    },
    updateDishPortionWeight(state, action) {
      try{
        if (state.selectedMeal != null) {
          const _selectedDishes = []
          try{
            state.selectedMeal.dishes.forEach(element => {
              if (element.id !== action.payload.dishId) {
                _selectedDishes.push(element);
              }else{
                _selectedDishes.push(action.payload.data);
              }

            });
          }catch(e){
            console.log(e);  
          }

          state.selectedMeal.dishes = _selectedDishes;
        }
      }catch(e){
        console.log(e);
      }
    },
    setMealDetailModalVisibility(state, action){
      state.isMealDetailModalVisible = action.payload.data;
    },
    resetMealCreationState(state, action){
      state.currentStep = MEAL_CREATION_FORM_STEPS.BASIC_DETAILS;
      state.selectedMeal = null;
    },
    resetSelectedMeal(state, action){
      state.mealList = [];
      state.selectedMeal = null;
    },
    resetMealState(state, action){
      state.currentStep = MEAL_CREATION_FORM_STEPS.BASIC_DETAILS;
      state.mealList = [];
      state.selectedMeal = null;
      state.isMealDetailModalVisible = false;
    }    
  }
})

export const getMealCreationCurrentStep = (state) => {
  try{
      return state.meal.currentStep;
  }catch(e){
      return MEAL_CREATION_FORM_STEPS.BASIC_DETAILS;
  } 
} 

export const getMealList = (state) => {
  try{
      return state.meal.mealList;
  }catch(e){
      return [];
  } 
} 

export const getSelectedMeal = (state) => {
    try{
        return state.meal.selectedMeal;
    }catch(e){
        return false;
    }
}

export const getMealDetailModalVisibility = (state) => { 
  try{
    return state.meal.isMealDetailModalVisible;
  }catch(e){
    return false;
  }
}

export const getSelectedMealNutritionInfo = (state) => {
  try{
    const cal = 530
    const protein = 80
    const fat = 40
    const fiber = 30
    const carbs = 50

    return [{
        'label' : 'Calories',
        'value': cal,
        'unit' : 'Kcal'
      },
      {
        'label' : 'Protein',
        'value': protein,
        'unit' : 'gms'
      },
      {
        'label' : 'Carbohydrates',
        'value': carbs,
        'unit' : 'gms'
      },
      {
        'label' : 'Fat',
        'value': fat,
        'unit' : 'gms'
      },
      {
        'label' : 'Fiber',
        'value': fiber,
        'unit' : 'gms'
      },
    ];

  }catch(e) {
    return [];
  }
}

export const { setMealList,removeDishFromSelectedMeal, updateDishPortionWeight, setMealDetailModalVisibility, setSelectedMeal, setMealCreationCurrentStep, resetSelectedMeal, resetMealCreationState, resetMealState, addDishToSelectedMeal } = mealSlice.actions

export default mealSlice.reducer