import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Button, Stack, TextField, Box, Card } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useState,useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux'; 
 
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { subDays } from 'date-fns';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import { getActiveClient, getClientList } from '../../store/coach/reducer';
import { fetchClientDetails, fetchClientList } from '../../data/network/client/api';
import UserListCard from '../../sections/coach/report/UserListCard';
import UserSummary from '../../sections/coach/report/UserSummary';
import { formatDate } from '../../utils/date';
import UserReport from '../../sections/coach/report/UserReport';
// sections

import '../../assets/css/Report.css';
// ----------------------------------------------------------------------

export default function PartnerReport() {
    const theme = useTheme();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const [showOverviewDashboard, setShowOverviewDashboard] = useState(true);

    const [searchParams, setSearchParams] = useSearchParams();
    const clientId = searchParams.get("client_id");
    
    const clientList = useSelector(state => getClientList(state));  

    useEffect(() => {
        if(clientId == null){
            setShowOverviewDashboard(true);
            fetchClientList(dispatch, cookies); 
            
        }else{
            setShowOverviewDashboard(false);
            fetchClientDetails(dispatch, cookies, clientId);
        }
    },[clientId]);
    
    return (
        <Page title="Report" className="partner-client-report">
            <Container maxWidth="xl">
                { 
                (!showOverviewDashboard)
                ? <UserReport />  
                : <Grid container spacing={3}> 

                    <Stack direction="row" alignItems="center" justifyContent="space-between" margin={2}>
                        <Typography variant="h7" gutterBottom>
                            Select an user to view their reports : 
                        </Typography>
                    </Stack>

                    <Grid container spacing={3} > 
                        {
                            clientList.map((e) => <Grid key={e.id} item   xs={12} sm={12} md={4} xl={3} lg={3} onClick={() => {
                                navigate(`/coach/report?client_id=${e.id}`)
                            }} sx={{cursor: 'pointer'}}>
                                    <UserListCard clientDetails={e} />
                                </Grid>)
                        }
                    </Grid>
                </Grid>
                }
            </Container>
        </Page>
    );
}
