import { Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Form, FormikProvider, useFormik } from 'formik';
import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useCookies } from 'react-cookie';

// material
import { Grid, Button, Container, Stack, Typography, Card, IconButton, Avatar, TextField, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import Iconify from '../../../components/Iconify';
import { getActiveClient, getClientJournalEditDetail, getClientList } from '../../../store/coach/reducer';
import { formatDate, getAge } from '../../../utils/date';
import { createUserJournal, fetchUserJournal } from '../../../data/network/journal/api';
import { JOURNAL_CREATE } from '../../../constants/message_constants';
import { SNACKBAR_SEVERITY } from '../../../constants/app_constants';
import { setSnackbarMessage, setSnackbarVisibilityStatus } from '../../../store/layout/reducer';
// ----------------------------------------------------------------------

export default function CreateJournalForm({drawerHandler, createCallback}) {
    const theme = useTheme();
    const clientDetails = useSelector(state => getActiveClient(state));
    const clientList = useSelector(state => getClientList(state));
    const clientJournalEditDetail = useSelector(state => getClientJournalEditDetail(state));

    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const dispatch = useDispatch(); 
    
    const [startDateValue, setStartDateValue] =  useState(
        new Date() 
    );

    const [endDateValue, setEndDateValue] =  useState(
        new Date() 
    );

    const handleStartDateChange = (newValue ) => {
        setStartDateValue(newValue);
        setEndDateValue(newValue);
    };

    const handleEndDateChange = (newValue ) => {
        setEndDateValue(newValue);
    };
      //   const [firstName, setFirstName] = useState(false);
  
    const ProfileSchema = Yup.object().shape({
        title: Yup.string() ,
        description: Yup.string() ,
    });
  
    const formik = useFormik({
        initialValues: {
          title: ' ' ,
          description: ' ' , 
          user_id: ''
      },
        validationSchema: ProfileSchema,
        onSubmit: () => {
  
        },
    }); 

    const handleCreateJournal = () => {
        if(formik.values.user_id !== '') {
            createUserJournal(dispatch, cookies, {
                user_id: formik.values.user_id,
                title: formik.values.title,
                description: formik.values.description,
                start_date: formatDate(startDateValue),
                end_date: formatDate(endDateValue),  
            }, createCallback); 
            drawerHandler();
        }else{
            dispatch(setSnackbarMessage({'message': JOURNAL_CREATE.NO_USER_SELECTED , 'severity': SNACKBAR_SEVERITY.ERROR}));
            dispatch(setSnackbarVisibilityStatus({'status': true }));
        }
    }

    // useEffect(() => {
    //     if(clientList !== null){
    //         console.log("user found");
    //         formik.setFieldValue('user_id', clientList[0].id);
    //     }else{
    //         console.log("No user found");
    //     }
    // },[clientList]);
 
    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (  
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
    
                <Grid  sx={{padding:2, borderRadius: 2}}>
                    {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h6" gutterBottom>
                            User Details
                        </Typography>
                    </Stack> */}
                    <Grid container direction="column"  > 
                        <Grid item container direction="row" justifyContent="center">
                            <Typography variant="h6" gutterBottom>
                                Create new Journal Entry
                            </Typography>
                        </Grid>
                        <Box margin={4} />
                        

                        <Grid item container direction="column" justifyContent="center">
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                <TextField
                                    fullWidth 
                                    select
                                    label="Select User"
                                    {...getFieldProps('user_id')}
                                    error={Boolean(touched.user_id && errors.user_id)}
                                    helperText={touched.user_id && errors.user_id}
                                    SelectProps={{
                                        native: true,
                                    }}
                                >
                                    <option key="select-a-user" value="">
                                        Select a user
                                    </option>
                                    {
                                        clientList && clientList.map((e, i) => (
                                            <option key={i} value={e.id}>
                                                {e.first_name} {e.last_name} - {e.subscription.meal_plan.parent_meal_plan.display_name} - {e.subscription.order_type}
                                            </option>
                                        ))
                                    }
                                </TextField>
                            </Stack>
                            <Box margin={2} />
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <MobileDatePicker
                                        label="Start Date" 
                                        inputFormat="dd MMM yyyy"
                                        value={startDateValue}
                                        onChange={handleStartDateChange}
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                    />
                                
                                </LocalizationProvider>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <MobileDatePicker
                                        label="End Date" 
                                        inputFormat="dd MMM yyyy"
                                        value={endDateValue}
                                        onChange={handleEndDateChange}
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                    />
                                
                                </LocalizationProvider>
                            </Stack>
                            <Box margin={2} />
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                <TextField
                                    fullWidth 
                                    label="Title"
                                    {...getFieldProps('title')}
                                    error={Boolean(touched.title && errors.title)}
                                    helperText={touched.title && errors.title}
                                />
                            </Stack>
                            <Box margin={2} />
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                <TextField
                                    fullWidth
                                    label="Description"
                                    {...getFieldProps('description')}
                                    error={Boolean(touched.description && errors.description)}
                                    helperText={touched.description && errors.description}
                                />
                            </Stack>
                            <Box margin={2} />

                        </Grid>
                        <Grid item container direction="row" justifyContent="center">
                            <Button variant='contained' onClick={() => {
                                handleCreateJournal();
                            }}>
                                Continue
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Form>
        </FormikProvider> 
    );
}
