import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Box } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import { LoginForm } from '../sections/auth/login';
import  LoginBg from '../assets/img/login_bg.png';
import  LoginBanner from '../assets/img/login_banner.png';
import Iconify from '../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  background: `url(${LoginBg}) no-repeat center / cover`,
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  display: 'flex',
  
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const BoxStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 6),
  background: '#F5F6F8',

  width: '80vw',
  gap: '30px',
  borderRadius: '10px',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    height: 'auto',
    width: 'auto',
    padding: '40px',
  },
  '& .left': {
    flexBasis: '40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .right': {
    flexBasis: '60%',
    position: 'relative',
 
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    '& .box': {
      position: 'absolute',
      top: '130px',
      background: '#FD8207',
      color: '#fff',
      padding: '10px',
      borderRadius: '10px',
      right: 0,
      boxShadow: '0px 2px 3px #0029700D',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    }
  }
}));

// ----------------------------------------------------------------------

export default function Login() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <RootStyle>
        {/* <HeaderStyle>
          <Logo /> */}

          {/* {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Don’t have an account? {''}
              <Link variant="subtitle2" component={RouterLink} to="/register">
                Get started
              </Link>
            </Typography>
          )} */}
        {/* </HeaderStyle> */}

        {/* {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src="/static/illustrations/illustration_login.png" alt="login" />
          </SectionStyle>
        )} */}

        {/* <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              Sign in to Bytes
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>Enter your details below.</Typography>

            <LoginForm />

 
          </ContentStyle>
         
        </Container> */}
        <BoxStyle>
        <div className='left'>
          <Typography variant="h4" gutterBottom>
              Welcome to <span style={{ color: '#FD8207', }}>Bytes!</span>
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>Enter your details below.</Typography>

            <LoginForm />
          </div>
          <div className='right'>
          <img src={LoginBanner} alt="login" />
            <div className='box'>
            <Iconify icon="fxemoji:hand"  width={32} height={32} /><Typography>Hi, Welcome Back</Typography>
            </div>
          </div>
        </BoxStyle>
      </RootStyle>
    </Page>
  );
}
