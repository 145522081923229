import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Dialog, DialogContent, SwipeableDrawer, AppBar, Toolbar, Typography, Grid } from '@mui/material';
// component
import Iconify from '../../../../../components/Iconify';
import UpdateActivityForm from './UpdateActivityForm';

// ----------------------------------------------------------------------

export default function ActivityMoreMenu(props) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [showUpdateForm, setShowUpdateForm] = useState(false);
   
  const { activity  } = props;

  const handleClose = () => {
    setShowUpdateForm(false);
  }
  
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }} onClick={() => {
            // setSelectedActivity({data: activity});
//          navigate(`/client/schedule_meal/${clientId}`);
          setShowUpdateForm(true);
        }}>
          <ListItemIcon>
            <Iconify icon="eva:edit-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        {/* <MenuItem sx={{ color: 'text.secondary' }} onClick={() => {
          setShowUpdateForm(true);
        }}>
          <ListItemIcon>
            <Iconify icon="eva:edit-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}
      </Menu>
        {
           <Dialog fullWidth minWidth="xl" maxWidth="xl" open={showUpdateForm} onClose={handleClose}> 
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Grid container direction="column">
                            <Typography sx={{ padding:2, flex: 1 }} variant="h6" component="div">
                                Update Activity
                            </Typography>
                        </Grid>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <Iconify  icon="eva:close-circle-outline" sx={{height:40, width:40, cursor:'pointer'}}/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent> 
                  <UpdateActivityForm activity={activity} handleClose={handleClose} />
                </DialogContent>
            </Dialog>

            // <SwipeableDrawer 
            //   anchor="bottom"
            //   open={}
            // >

            // </SwipeableDrawer>
           
        }
    </>
  );
}
