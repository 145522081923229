import { Typography } from "@mui/material";

const titleStyles = {
    // color:"#555",
    // fontWeight: "100",
    outline: "none",
    // margin: "0px",
    display: "inline-block",
    width: "100%",
    textAlign: "center",
    position: "relative",
    // top: "-75px"
};


const subTitleStyles = {
    position: "relative",
    top: "-95px",
    textAlign: "center",
    // fontWeight: "100",
    // color: "#888"
};
    
export default function CardTitle({title, ...others}) {
    return (
        <div className="titleDiv">
            <Typography variant="body1">{title}</Typography>
            {/* <h4 style={subTitleStyles} className="subTitle">{this.props.subTitle}</h4> */}
        </div>
    );
};
