export const MedicalConditions = {
    label : 'Medical Conditions',
    key: 'medical_conditions',    
    data: [
    {
        'key': 'diabetes',
        'label' : 'Diabetes',
        'description' : '',
        'img' : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRc0iEr0pXdPjj1xq31hBvX0566kAfhkq7C-g&usqp=CAU',
        'bg_color' : '#F8DEBD',
        'terms' : [
            {
                'key': 'medication',
                'label' : 'Medication',
                'value_type' : 'boolean',
                'default_value' : false
            },
            {
                'key': 'comment',
                'label' : 'Comment',
                'value_type' : 'text',
                'default_value' : ''
            }
        ],
        'last_updated': '',
        'severity': '',
    },
    {
        'key': 'thyriodism',
        'label' : 'Thyriodism',
        'description' : '',
        'img' : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRc0iEr0pXdPjj1xq31hBvX0566kAfhkq7C-g&usqp=CAU',
        'bg_color' : '#CA6B6E',
        'terms' : [
            {
                'key': 'medication',
                'label' : 'Medication',
                'value_type' : 'boolean',
                'default_value' : false
            },
            {
                'key': 'comment',
                'label' : 'Comment',
                'value_type' : 'text',
                'default_value' : ''
            }
        ],
        'last_updated': '',
        'severity': '',
    },
    {
        'key': 'high_bp',
        'label' : 'High BP',
        'description' : '',
        'bg_color': '#478F96',
        'terms' : [
            {
                'key': 'medication',
                'label' : 'Medication',
                'value_type' : 'boolean',
                'default_value' : false
            },
            {
                'key': 'comment',
                'label' : 'Comment',
                'value_type' : 'text',
                'default_value' : ''
            }
        ],
        'last_updated': '',
        'severity': '',
    },
    {
        'key': 'low_bp',
        'label' : 'Low BP',
        'description' : '',
        'bg_color' : '#F8DEBD',
        'terms' : [
            {
                'key': 'medication',
                'label' : 'Medication',
                'value_type' : 'boolean',
                'default_value' : false
            },
            {
                'key': 'comment',
                'label' : 'Comment',
                'value_type' : 'text',
                'default_value' : ''
            }
        ],
        'last_updated': '',
        'severity': '',
    },
    {
        'key': 'anaemia',
        'label' : 'Anaemia',
        'description' : '',
        'bg_color': '#478F96',
        'terms' : [
            {
                'key': 'medication',
                'label' : 'Medication',
                'value_type' : 'boolean',
                'default_value' : false
            },
            {
                'key': 'comment',
                'label' : 'Comment',
                'value_type' : 'text',
                'default_value' : ''
            }
        ],
        'last_updated': '',
        'severity': '',
    },
    {
        'key': 'cholesterol',
        'label' : 'Cholesterol',
        'description' : '',
        'bg_color' : '#F8DEBD',
        'terms' : [
            {
                'key': 'medication',
                'label' : 'Medication',
                'value_type' : 'boolean',
                'default_value' : false
            },
            {
                'key': 'comment',
                'label' : 'Comment',
                'value_type' : 'text',
                'default_value' : ''
            }
        ],
        'last_updated': '',
        'severity': '',
    },
    {
        'key': 'pcod',
        'label' : 'PCOD',
        'description' : '',
        'bg_color' : '#F8DEBD',
        'terms' : [
            {
                'key': 'medication',
                'label' : 'Medication',
                'value_type' : 'boolean',
                'default_value' : false
            },
            {
                'key': 'comment',
                'label' : 'Comment',
                'value_type' : 'text',
                'default_value' : ''
            }
        ],
        'last_updated': '',
        'severity': '',
    },
    {
        'key': 'heart_disease',
        'label' : 'Heart Disease',
        'description' : '',
        'bg_color': '#478F96',
        'terms' : [
            {
                'key': 'medication',
                'label' : 'Medication',
                'value_type' : 'boolean',
                'default_value' : false
            },
            {
                'key': 'comment',
                'label' : 'Comment',
                'value_type' : 'text',
                'default_value' : ''
            }
        ],
        'last_updated': '',
        'severity': '',
    },
    {
        'key': 'epilepsy',
        'label' : 'Epilepsy',
        'description' : '',
        'bg_color' : '#F8DEBD',
        'terms' : [
            {
                'key': 'medication',
                'label' : 'Medication',
                'value_type' : 'boolean',
                'default_value' : false
            },
            {
                'key': 'comment',
                'label' : 'Comment',
                'value_type' : 'text',
                'default_value' : ''
            }
        ],
        'last_updated': '',
        'severity': '',
    },
    {
        'key': 'arthritis',
        'label' : 'Arthritis',
        'description' : '',
        'bg_color': '#478F96',
        'terms' : [
            {
                'key': 'medication',
                'label' : 'Medication',
                'value_type' : 'boolean',
                'default_value' : false
            },
            {
                'key': 'comment',
                'label' : 'Comment',
                'value_type' : 'text',
                'default_value' : ''
            }
        ],
        'last_updated': '',
        'severity': '',
    },
    {
        'key': 'high_uric_acid',
        'label' : 'High Uric Acid',
        'description' : '',
        'bg_color' : '#F8DEBD',
        'terms' : [
            {
                'key': 'medication',
                'label' : 'Medication',
                'value_type' : 'boolean',
                'default_value' : false
            },
            {
                'key': 'comment',
                'label' : 'Comment',
                'value_type' : 'text',
                'default_value' : ''
            }
        ],
        'last_updated': '',
        'severity': '',
    },
    {
        'key': 'gastric_issues',
        'label' : 'Gastric Issues',
        'description' : '',
        'bg_color' : '#F8DEBD',
        'terms' : [
            {
                'key': 'medication',
                'label' : 'Medication',
                'value_type' : 'boolean',
                'default_value' : false
            },
            {
                'key': 'comment',
                'label' : 'Comment',
                'value_type' : 'text',
                'default_value' : ''
            }
        ],
        'last_updated': '',
        'severity': '',
    },
    {
        'key': 'mental_health',
        'label' : 'Mental Health',
        'description' : '',
        'bg_color' : '#F8DEBD',
        'terms' : [
            {
                'key': 'medication',
                'label' : 'Medication',
                'value_type' : 'boolean',
                'default_value' : false
            },
            {
                'key': 'comment',
                'label' : 'Comment',
                'value_type' : 'text',
                'default_value' : ''
            }
        ],
        'last_updated': '',
        'severity': '',
    },
    {
        'key': 'kidney_stones',
        'label' : 'Kidney Stones',
        'description' : '',
        'bg_color': '#478F96',
        'terms' : [
            {
                'key': 'medication',
                'label' : 'Medication',
                'value_type' : 'boolean',
                'default_value' : false
            },
            {
                'key': 'comment',
                'label' : 'Comment',
                'value_type' : 'text',
                'default_value' : ''
            }
        ],
        'last_updated': '',
        'severity': '',
    },
    {
        'key': 'past_surgeries',
        'label' : 'Surgeries in the past',
        'description' : '',
        'bg_color' : '#F8DEBD',
        'terms' : [
            {
                'key': 'medication',
                'label' : 'Medication',
                'value_type' : 'boolean',
                'default_value' : false
            },
            {
                'key': 'comment',
                'label' : 'Comment',
                'value_type' : 'text',
                'default_value' : ''
            }
        ],
        'last_updated': '',
        'severity': '',
    },
]
}