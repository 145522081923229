import Iconify from '../components/Iconify';
import { ROLES } from './app_constants';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const DASHBOARD_NAV = {
    key: 'dashboard',
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
    visibility: [
        ROLES.NUTRITIONIST
    ]
};

const CLIENT_NAV = {
    key: 'nutritionist_clients',
    title: 'client',
    path: '/coach/client',
    icon: getIcon('mdi:account-group-outline'),
    visibility: [
        ROLES.NUTRITIONIST
    ]
};

const PROFILE_NAV = {
    key: 'partner_profile',
    title: 'profile',
    path: '/profile',
    icon: getIcon('mdi:account-group-outline'),
    visibility: [
        ROLES.NUTRITIONIST
    ]
};
  

const REPORT_ACTIVITY = {
    key: 'nutritionist_activity',
    title: 'Activity Meta',
    path: '/coach/activity',
    icon: getIcon('mdi:run-fast'),
    visibility: [
        ROLES.NUTRITIONIST
    ]
};

const ACTIVITY_SCHEDULER_NAV = {
    key: 'nutritionist_schedule_activities',
    title: 'Schedule Activity',
    path: '/coach/schedule_activity',
    icon: getIcon('eva:calendar-outline'),
    visibility: [
        ROLES.NUTRITIONIST
    ]
};

const MEAL_SCHEDULER_NAV = {
    key: 'nutritionist_schedule_meal',
    title: 'Schedule Meals',
    path: '/coach/schedule_meal',
    icon: getIcon('mdi:food-fork-drink'),
    visibility: [
        ROLES.NUTRITIONIST
    ]
};

const REPORT_NAV = {
    key: 'nutritionist_reports',
    title: 'Performance & Report',
    path: '/coach/report',
    icon: getIcon('eva:pie-chart-outline'),
    visibility: [
        ROLES.NUTRITIONIST
    ]
};

const JOURNAL_NAV = {
    key: 'nutritionist_journal',
    title: 'User Journal',
    path: '/coach/journal',
    icon: getIcon('eva:book-open-outline'),
    visibility: [
        ROLES.NUTRITIONIST
    ]
};

const DISH_NAV = {
    key: 'nutritionist_dish',
    title: 'Dish Meta',
    path: '/coach/dish',
    icon: getIcon('mdi:food-outline'),
    visibility: [
        ROLES.NUTRITIONIST
    ]
};

const MEALS_NAV = {
    key: 'nutritionist_meals',
    title: 'Meal Meta',
    path: '/coach/meals',
    icon: getIcon('mdi:food-outline'),
    visibility: [
        ROLES.NUTRITIONIST
    ]
};

/** Corporate Manager Routes */

const CORPORATE_DASHBOARD = {
    key: 'corporate_dashboard',
    title: 'Dashboard',
    path: '/corporate/dashboard',
    icon: getIcon('eva:pie-chart-2-fill'),
    visibility: [
        ROLES.CORPORATE_MANAGER
    ]
};

const CORPORATE_PROFILE = {
    key: 'corporate_profile',
    title: 'My Organisation',
    path: '/corporate/profile',
    icon: getIcon('fa:address-card'),
    visibility: [
        ROLES.CORPORATE_MANAGER
    ]
};


const CORPORATE_USER_NAV = {
    key: 'corporate_users',
    title: 'Employees',
    path: '/corporate/users',
    icon: getIcon('mdi:account-group-outline'),
    visibility: [
        ROLES.CORPORATE_MANAGER
    ]
};

const CORPORATE_INVOICE_NAV = {
    key: 'corporate_invoices',
    title: 'Invoices',
    path: '/corporate/invoices',
    icon: getIcon('eva:credit-card-fill'),
    visibility: [
        ROLES.CORPORATE_MANAGER
    ]
};

const CORPORATE_REPORT_NAV = {
    key: 'corporate_reports',
    title: 'Perfomance & Report',
    path: '/corporate/report',
    icon: getIcon('eva:pie-chart-2-fill'),
    visibility: [
        ROLES.CORPORATE_MANAGER
    ]
};

const CORPORATE_FEEDBACK_NAV = {
    key: 'corporate_feedback',
    title: 'Employee Feedbacks',
    path: '/corporate/feedback',
    icon: getIcon('fa:comments'),
    visibility: [
        ROLES.CORPORATE_MANAGER
    ]
};

const CORPORATE_BILLING_NAV = {
    key: 'corporate_billing',
    title: 'Billing & Invoices',
    path: '/corporate/billing',
    icon: getIcon('icon-park-solid:bill'),
    visibility: [
        ROLES.CORPORATE_MANAGER
    ]
};
const CALENDAR_MEAL_NAV = {
    key: 'calendar_meal',
    title: 'Meal Schedules',
    path: '/corporate/calendar',
    icon: getIcon('game-icons:meal'),
    visibility: [
        ROLES.CORPORATE_MANAGER
    ]
};

/** Corporate Manager Routes Ends here */

/** Store Manager Routes Starts here */

const STORE_MANAGER_ORDER_NAV = {
    key: 'store_orders',
    title: 'Orders',
    path: '/store/orders',
    icon: getIcon('mdi:list-box-outline'),
    visibility: [
        ROLES.STORE_MANAGER
    ]
};


const STORE_MANAGER_MENU_NAV = {
    key: 'store_menu',
    title: 'Menu',
    path: '/store/menu',
    icon: getIcon('mdi:food-outline'),
    visibility: [
        ROLES.STORE_MANAGER
    ]
};

const STORE_MANAGER_STOCK_NAV = {
    key: 'store_stock',
    title: 'Stock',
    path: '/store/stock',
    icon: getIcon('mdi:package-variant-closed'),
    visibility: [
        ROLES.STORE_MANAGER
    ]
};

const STORE_MANAGER_VENDOR_NAV = {
    key: 'store_vendor',
    title: 'Vendor',
    path: '/store/vendor',
    icon: getIcon('mdi:handshake-outline'),
    visibility: [
        ROLES.STORE_MANAGER
    ]
};

const STORE_MANAGER_SALES_NAV = {
    key: 'store_sales',
    title: 'Sales',
    path: '/store/sales',
    icon: getIcon('mdi:finance'),
    visibility: [
        ROLES.STORE_MANAGER
    ]
};

const STORE_MANAGER_EXPENSES_NAV = {
    key: 'store_expenses',
    title: 'Expenses',
    path: '/store/expenses',
    icon: getIcon('mdi:cash-multiple'),
    visibility: [
        ROLES.STORE_MANAGER
    ]
};

const STORE_MANAGER_STAFF_NAV = {
    key: 'store_staff',
    title: 'Staff',
    path: '/store/staff',
    icon: getIcon('mdi:account-group-outline'),
    visibility: [
        ROLES.STORE_MANAGER
    ]
};

const STORE_MANAGER_INVOICE_NAV = {
    key: 'store_invoices',
    title: 'Invoices',
    path: '/store/invoices',
    icon: getIcon('mdi:invoice-list-outline'),
    visibility: [
        ROLES.STORE_MANAGER
    ]
};

const STORE_MANAGER_ROLES_NAV = {
    key: 'store_roles',
    title: 'Roles',
    path: '/store/roles',
    icon: getIcon('mdi:invoice-list-outline'),
    visibility: [
        ROLES.STORE_MANAGER
    ]
};

/** Store Manager Routes Ends here */

// static Navs starts here
const NUTRITIONIST_FAQS = {
    key: 'coach_faqs',
    title: 'FAQs',
    path: '/coach/faqs',
    icon: getIcon('mdi:faq'),
    visibility: [
        ROLES.NUTRITIONIST,
    ]
};

const CORPORATE_CLIENT_FAQS = {
    key: 'corporate_client_faqs',
    title: 'FAQs',
    path: '/corporate/faqs',
    icon: getIcon('mdi:faq'),
    visibility: [
        ROLES.CORPORATE_MANAGER,
    ]
};
const STORE_PARTNER_FAQS = {
    key: 'corporate_client_faqs',
    title: 'FAQs',
    path: '/corporate/faqs',
    icon: getIcon('mdi:faq'),
    visibility: [
        ROLES.STORE_MANAGER,
    ]
};

const NUTRITIONIST_TERMS_CONDITIONS = {
    key: 'coach_terms',
    title: 'Terms and Conditions',
    path: '/coach/terms',
    icon: getIcon('iconoir:privacy-policy'),
    visibility: [
        ROLES.NUTRITIONIST,
    ]
};

const CORPORATE_CLIENT_TERMS_CONDITIONS = {
    key: 'corporate_client_terms',
    title: 'Terms and Conditions',
    path: '/corporate/terms',
    icon: getIcon('iconoir:privacy-policy'),
    visibility: [
        ROLES.CORPORATE_MANAGER,
    ]
};
const STORE_PARTNER_TERMS_CONDITIONS = {
    key: 'store_partner_terms',
    title: 'Terms and Conditions',
    path: '/store/terms',
    icon: getIcon('iconoir:privacy-policy'),
    visibility: [
        ROLES.STORE_MANAGER,
    ]
};

// static Navs ends here

export const navigationConfig = [
    CLIENT_NAV,
    ACTIVITY_SCHEDULER_NAV,
    MEAL_SCHEDULER_NAV,
    REPORT_NAV,
    JOURNAL_NAV,
    DISH_NAV, 
    MEALS_NAV,
    REPORT_ACTIVITY, 
    CALENDAR_MEAL_NAV,
    CORPORATE_REPORT_NAV,
    CORPORATE_FEEDBACK_NAV,
    // CORPORATE_INVOICE_NAV,
    CORPORATE_USER_NAV, 
    CORPORATE_BILLING_NAV,
    CORPORATE_PROFILE,
    STORE_MANAGER_ORDER_NAV,
    STORE_MANAGER_STOCK_NAV,
    STORE_MANAGER_VENDOR_NAV,
    STORE_MANAGER_EXPENSES_NAV,
    STORE_MANAGER_SALES_NAV,
    STORE_MANAGER_MENU_NAV,
    STORE_MANAGER_STAFF_NAV,
    STORE_MANAGER_INVOICE_NAV,
    STORE_MANAGER_ROLES_NAV,
];

export const staticNavs = [
    CORPORATE_CLIENT_FAQS,
    STORE_PARTNER_FAQS,
    CORPORATE_CLIENT_TERMS_CONDITIONS,
    STORE_PARTNER_TERMS_CONDITIONS,
    // NUTRITIONIST_FAQS,
    // NUTRITIONIST_TERMS_CONDITIONS,
];
