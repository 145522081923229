import { useSelector, useDispatch } from 'react-redux';  
import { Form, FormikProvider, useFormik } from 'formik';
import { useState, useEffect  } from 'react';
import * as Yup from 'yup';
import { useCookies } from 'react-cookie';

// material
import { Grid, Button, Stack, Typography, TextField, Box, InputAdornment, DialogContent, Dialog, OutlinedInput, FormControl, InputLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// components 
import { getActiveClient, getClientJournalEditDetail } from '../../../../../store/coach/reducer'; 
import { addActivity, fetchActivity } from '../../../../../data/network/activity/api'; 
import { scheduleUserActivity } from '../../../../../data/network/client/api';
import { getActivityList } from '../../../../../store/activity/reducer';
import { formatDate } from '../../../../../utils/date';
import { setSnackbarMessage, setSnackbarVisibilityStatus } from '../../../../../store/layout/reducer';
import { ACTIVITY_CREATE } from '../../../../../constants/message_constants';
import { SNACKBAR_SEVERITY } from '../../../../../constants/app_constants';

export default function ShowDropdownList( {userId, date, handleClose} ) {
    const theme = useTheme(); 
 
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);
    const dispatch = useDispatch(); 
    
    const [activityDuration, setActivityDuration] = useState(null);
    const [restDuration, setRestDuration] = useState(null);
    const [caloriesBurnt, setCaloriesBurnt] = useState(null);

    const activityList = useSelector(state => getActivityList(state));

    const clientJournalEditDetail = useSelector(state => getClientJournalEditDetail(state)); 

    const [startDateValue, setStartDateValue] =  useState(
        new Date(clientJournalEditDetail.from_date)
    );

    const handleStartDateChange = (newValue ) => {
        setStartDateValue(newValue); 
    };

    useEffect(() => {
        fetchActivity(dispatch, cookies);
    },[])
  
    const handleScheduleActivity = () => {
        if(formik.values.activity_meta_id && formik.values.activity_meta_id !== '-1'){
            scheduleUserActivity(dispatch, cookies, {
                'activity_meta_id' : formik.values.activity_meta_id,
                'date' : formatDate(startDateValue, "yyyy-MM-DD"),
                'user_id' : userId,
                'standard_duration' : formik.values.standard_duration,
                'standard_rest_duration' : formik.values.standard_rest_duration,
                'standard_burned_calories' : formik.values.standard_burned_calories
            });
            handleClose();
        }else{
            dispatch(setSnackbarMessage({'message': ACTIVITY_CREATE.MISSING_ACTIVITY,'severity': SNACKBAR_SEVERITY.ERROR}));
            dispatch(setSnackbarVisibilityStatus({'status': true}));
        }
    };

    const ActivitySchema = Yup.object().shape({
        activity_meta_id: Yup.string().required('Activity is required'),
    });
    
    const formik = useFormik({
        initialValues: {
            activity_meta_id: '',
            standard_duration: '',
            standard_rest_duration: '',
            standard_burned_calories: ''
        },
        validationSchema: ActivitySchema,
        onSubmit: () => {
  
        },
    });

    const handleActivityChange = (selected) => {
        activityList.forEach(element => {
            if(selected === element.activity_id.toString()){
                formik.setFieldValue('activity_meta_id', element.activity_id);
                formik.setFieldValue('standard_duration', element.standard_duration);
                formik.setFieldValue('standard_rest_duration', element.standard_rest_duration);
                formik.setFieldValue('standard_burned_calories', element.standard_burned_calories);
            }
        });
    }

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (  
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid item container direction="column" justifyContent="center">                                    
                    <Box margin={1} />
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                                
                                label="Select Date" 
                                inputFormat="dd MMM yyyy"
                                value={startDateValue}
                                minDate={new Date(clientJournalEditDetail.from_date)}
                                maxDate={new Date(clientJournalEditDetail.to_date)}
                                onChange={handleStartDateChange}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        </LocalizationProvider>
                    </Stack>

                    <Box margin={2} />
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                            fullWidth 
                            select
                            label="Select Activity"
                            {...getFieldProps('activity_meta_id')}
                            error={Boolean(touched.activity_meta_id && errors.activity_meta_id)}
                            helperText={touched.activity_meta_id && errors.activity_meta_id}
                            SelectProps={{
                                native: true,
                            }}
                            onChange={(e, i) => {
                                handleActivityChange(e.target.value);
                            }}
                            variant="filled"
                        >
                            <option key="-1" value="-1">
                                Select 
                            </option>
                            {activityList && activityList != null && activityList.map((option) => (
                                <option key={option.activity_id} value={option.activity_id}>
                                    {option.display_name}
                                </option>
                            ))}
                        </TextField>
                    </Stack>
                    
                    <Box margin={2} />
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="standard_duration" variant="outlined">
                                Activity Duration
                            </InputLabel>

                            <OutlinedInput
                                type="number"
                                fullWidth
                                // label="Activity Duration"
                                {...getFieldProps('standard_duration')}
                                error={Boolean(touched.standard_duration && errors.standard_duration)}
                                helperText={touched.standard_duration && errors.standard_duration}
                                endAdornment={<InputAdornment position="end">mins</InputAdornment>}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="standard_rest_duration" variant="outlined">
                                Rest Duration
                            </InputLabel>

                            <OutlinedInput
                                fullWidth
                                type="number"
                                // label="Rest Duration"
                                {...getFieldProps('standard_rest_duration')}
                                error={Boolean(touched.standard_rest_duration && errors.standard_rest_duration)}
                                helperText={touched.standard_rest_duration && errors.standard_rest_duration}
                                endAdornment={<InputAdornment position="end">mins</InputAdornment>}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="calories_burnt" variant="outlined">
                                Calories
                            </InputLabel>

                            <OutlinedInput
                                type="number"
                                fullWidth
                                // label="Calorie Burnt"
                                {...getFieldProps('standard_burned_calories')}
                                error={Boolean(touched.standard_burned_calories && errors.standard_burned_calories)}
                                helperText={touched.standard_burned_calories && errors.standard_burned_calories}
                                endAdornment={<InputAdornment position="end">Kcal</InputAdornment>}
                            />
                        </FormControl>
                    </Stack>
                    <Box margin={2} />
                </Grid>

                <Grid item container direction="row" justifyContent="center">
                    <Button variant='contained' onClick={() => {
                        handleScheduleActivity();
                    }}>
                        Schedule
                    </Button>                            
                </Grid>
            </Form>
        </FormikProvider>
    );
}
