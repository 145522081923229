import { Container, Box, Grid, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { LoadingButton } from "@mui/lab";
import useRazorpay from "react-razorpay";


import { ONBOARDING_FORM_STEPS, PAYMENT_MODE, PAYMENT_STATUS, RAZORPAY_KEY } from "../../../constants/app_constants";
import { getActiveClient, getClientPaymentMode, 
    getClientPaymentStatus, getClientOrderDetails, 
    setPaymentStatus, getAddTransactionDetails, getClientSubscriptionCartDetails, getClientSubscriptionCartTotalAmount
} from "../../../store/coach/reducer";


import { ReactComponent as Loader } from "../../../assets/img/loader.svg";
import FailedIcon from "../../../assets/img/fail.png";
import SuccessIcon from "../../../assets/img/success.png";

import { setOnboardingCurrentStep } from "../../../store/onboarding/reducer";
import { addTransaction, generateOrderId } from "../../../data/network/order/api";
import { confirmOrder } from "../../../data/network/client/api";
import { getUserId } from "../../../store/user/reducer";



export default function PaymentContainer(){

    const clientPaymentStatus = useSelector(state => getClientPaymentStatus(state));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [orderIdGenerated, setOrderIDGenerated] = useState(false);

    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);

    const activeClient = useSelector(state => getActiveClient(state));
    const paymentMode = useSelector(state => getClientPaymentMode(state));
    const orderDetails = useSelector(state => getClientOrderDetails(state));
    const addTransactionDetails = useSelector(state => getAddTransactionDetails(state));
    const partnerId = useSelector(state => getUserId(state));

    const subscriptionCartDetails = useSelector((state) => getClientSubscriptionCartDetails(state));
    const grandTotal = useSelector((state) => getClientSubscriptionCartTotalAmount(state));

    const Razorpay = useRazorpay();

    useEffect(() => {
        switch(clientPaymentStatus){
            case PAYMENT_STATUS.CONFIRMED:
                dispatch(setOnboardingCurrentStep({step: ONBOARDING_FORM_STEPS.ONBOARDING_CONFIRMATION}));
                break;
            default:
                break;
        }
    },[clientPaymentStatus]);

    useEffect(() => {
        
        if(activeClient != null && !orderIdGenerated) {

            setOrderIDGenerated(true);

            let selectedPaymentMode = "PRE_PAID";
            if(paymentMode === PAYMENT_MODE.OFFLINE){
                selectedPaymentMode = "POST_PAID";
            } 
            generateOrderId(dispatch, cookies, {
                'user_id': activeClient.user_id,
                'amount': grandTotal,
                'payment_mode': selectedPaymentMode,
            });
        }
    },[orderIdGenerated]);

    useEffect(() => {

        if(orderIdGenerated && orderDetails != null && orderDetails.order_id != null){
            dispatch(setPaymentStatus({status: PAYMENT_STATUS.PENDING}));

            if(paymentMode === PAYMENT_MODE.ONLINE){
                handleOnlinePayment();
            }
        }
    },[orderDetails]);

    useEffect(() => {
        if(clientPaymentStatus === PAYMENT_STATUS.PENDING && paymentMode === PAYMENT_MODE.ONLINE){
            handleOnlinePayment();
        }
    },[clientPaymentStatus]);

    useEffect(() => {
        if(addTransactionDetails != null){
            // dispatch(setPaymentStatus({status: PAYMENT_STATUS.CONFIRMED}));
            confirmOrderSubscription("PRE_PAID");
        }
    },[addTransactionDetails]);

    const handleOnlinePayment = () => {
        openRazorpay();
    }

    const openRazorpay = async () => {
        const options = {
            key: RAZORPAY_KEY,
            amount: grandTotal*100, 
            currency: "INR",
            name: "ByteRight Technologies Pvt Ltd",
            description: "",
            image: "",
            order_id: orderDetails.transaction_id, 
            handler (response) {
                addTransaction(dispatch, cookies, {
                    "user_id": activeClient.user_id,
                    "order_id": orderDetails.order_id,
                    "transaction_id": orderDetails.transaction_id,
                    "payment_id": response.razorpay_payment_id,
                    "status": "SUCCESS",
                    "type": "DEBIT",
                    "mode_of_transaction": "PRE_PAID",
                    "amount": grandTotal
                });
            },
            prefill: {
              name: activeClient.first_name,
              email: activeClient.email != null ? activeClient.email: "support@the-bytes.com" ,
              contact: activeClient.mobile_no,
            },
        };
        
        const rzp1 = new Razorpay(options);
    
        rzp1.on("payment.failed", (response) => {
            dispatch(setPaymentStatus({status: PAYMENT_STATUS.FAILED}));

            console.log(response);
            console.log(response.error.code);
            console.log(response.error.description);
            console.log(response.error.source);
            console.log(response.error.step);
            console.log(response.error.reason);
            console.log(response.error.metadata.order_id);
            console.log(response.error.metadata.payment_id);
        });
    
        rzp1.open();
    }

    const handleRetryPayment = () => {
        // dispatch(setPaymentStatus({status: PAYMENT_STATUS.INITIAL}));
        dispatch(setPaymentStatus({status: PAYMENT_STATUS.PENDING}));
    }

    const handleOfflinePaymentRejection = () => {
        dispatch(setPaymentStatus({status: PAYMENT_STATUS.CANCELLED}));
    }

    const handleOnlinePaymentRejection = () => {
        dispatch(setPaymentStatus({status: PAYMENT_STATUS.CANCELLED}));
    }

    const handleOfflinePaymentConfirmation = () => {
        // dispatch(setPaymentStatus({status: PAYMENT_STATUS.CONFIRMED}));
        confirmOrderSubscription("POST_PAID");
    }

    const confirmOrderSubscription = (paramPaymentMode) => {
        confirmOrder(dispatch, cookies, {       
            "payment_mode" : paramPaymentMode,
            "user_id" : activeClient.user_id,
            "partner_id" : partnerId, 
            "order_type": "SUBSCRIPTION",
            "order_id" : orderDetails.order_id,
            "transaction_id" : orderDetails.transaction_id,
            "lunch_address_id" : activeClient.lunchAddress,
            "dinner_address_id" : activeClient.dinnerAddress,
            "weekend_address_id" : activeClient.dinnerAddress,
        });
    }

    return (
        <div>
            {
                clientPaymentStatus === PAYMENT_STATUS.INITIAL && 
                <div>
                    <Box padding={1} />
                    <Loader />
                    <Box padding={1} />
                    <Grid container justifyContent="center" alignItems="center" direction="column">
                        <div>We are awaiting confirmation of your payment.</div>
                        <Box padding={1} />
                        <div>Please do not go back or refresh the page</div>
                        <Box padding={1} />
                        <Button variant="contained" onClick={handleOnlinePaymentRejection}>
                            Cancel
                        </Button>
                    </Grid>
                </div>
            }
            {
                clientPaymentStatus === PAYMENT_STATUS.PENDING && paymentMode === PAYMENT_MODE.ONLINE &&

                    <div>
                        <Box padding={1} />
                        <Loader />
                        <Box padding={1} />
                        <Grid container justifyContent="center" alignItems="center" direction="column">
                            <div>We are awaiting confirmation of your payment.</div>
                            <Box padding={1} />
                            <div>Please do not go back or refresh the page</div>
                            <Box padding={1} />
                            <Button variant="contained" onClick={handleOnlinePaymentRejection}>
                                Cancel
                            </Button>
                        </Grid>
                    </div>
            }
            {
                clientPaymentStatus === PAYMENT_STATUS.PENDING && paymentMode === PAYMENT_MODE.OFFLINE &&

                    <div>
                        <Box padding={1} />

                        <Grid container justifyContent="center" alignItems="center" direction="column">
                            
                            <Grid container justifyContent="start" alignItems="start" direction="column">
                                <Box padding={3}>
                                    <h3>Have you collected the final amount?</h3>
                                </Box>
                            </Grid>
                            <Box padding={2} />
                            <Grid  justifyContent="center" alignItems="center" container direction="row">
                                <Box padding={2} style={{width:'40%'}}>
                                    <LoadingButton fullWidth size="large" onClick={() => handleOfflinePaymentRejection()} variant="contained" loading={isSubmitting}>
                                        No, Cancel order
                                    </LoadingButton> 
                                </Box>
                                <Box padding={2} style={{width:'55%'}}>
                                    <LoadingButton fullWidth size="large" onClick={() => handleOfflinePaymentConfirmation()} variant="contained" loading={isSubmitting}>
                                        Yes, I have collected the amount
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
            }
            {
                clientPaymentStatus === PAYMENT_STATUS.CANCELLED && 
                <div>
                    <Box padding={1} />
                    <Grid container justifyContent="center" alignItems="center" direction="column">
                        <img src={FailedIcon} alt="order-fail" height="100" width="100" />
                    </Grid>
                    <Box padding={1} />
                    <Grid container justifyContent="center" alignItems="center" direction="column">
                        <div>Your payment was cancelled.</div>
                    </Grid>
                    <Box padding={1} />
                    <Grid  justifyContent="center" alignItems="center" container>
                        <Box padding={2} style={{width:'30%'}}>
                            <LoadingButton fullWidth size="large" onClick={() => handleRetryPayment()} variant="contained" loading={isSubmitting}>
                                Retry
                            </LoadingButton> 
                        </Box>
                    </Grid>
                </div>
            }
            {
                clientPaymentStatus === PAYMENT_STATUS.FAILED && 
                <div>
                    <Box padding={1} />
                    <Grid container justifyContent="center" alignItems="center" direction="column">
                        <img src={FailedIcon} alt="order-fail" height="100" width="100" />
                    </Grid>
                    <Box padding={3} />
                    <Grid container justifyContent="center" alignItems="center" direction="column">
                        <div>Your payment was failed. Any amount deducted will be credited back to your source account within 3-5 days.</div>
                    </Grid>
                    <Box padding={1} />
                    <Grid  justifyContent="center" alignItems="center" container>
                        <Box padding={2} style={{width:'30%'}}>
                            <LoadingButton fullWidth size="large" onClick={() => handleRetryPayment()} variant="contained" loading={isSubmitting}>
                                Retry
                            </LoadingButton> 
                        </Box>
                    </Grid>
                </div>
            }
            {
                clientPaymentStatus === PAYMENT_STATUS.CONFIRMED && 
                <div>
                    <Box padding={1} />
                    <img src={SuccessIcon} alt="order-success" />
                    <Box padding={1} />
                    <Grid container justifyContent="center" alignItems="center" direction="column">
                        <div>Your payment was successful. Please wait while we create your order and navigate you to next step.</div>
                        <Box padding={1} />
                        <div>This might take a few seconds...</div>
                    </Grid>
                </div>
            }
        </div>

    );

}