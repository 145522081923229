
// export function getJournalEntry(journal, logType ,date) {    

//     console.log(date);
//     if(journal && journal != null){ 
//         for (let index = 0; index < journal.length; index+=1) {
//             const element = journal[index];
//             if(element.log_type === logType && (new Date(element.date).setHours(0,0,0,0) === new Date(date).setHours(0,0,0,0))){
//                 if(element.value != null){ 
//                     return element;
//                 }
//             }
//         }
//     }
//     return null;
// }

export function getJournalEntry(clientJournal, logType){

    const _logData = [];
    if(clientJournal != null){

        clientJournal.forEach(element => {
            let val = 0;
 
            element.data.forEach(e => {
                if(e.log_type === logType){
                    try{
                        if(e.value !== null){
                            console.log(e.value);
                            val = parseInt(e.value, 10)
                        }
                    }catch{
                        val = 0
                    }
                }
            }); 


            _logData.push({
                'date' : element.date,
                'val' : val
            });

        });
    } 

    return _logData;

}