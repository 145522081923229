 
import { useEffect,useState } from 'react';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box } from '@mui/material';

import { useSelector } from 'react-redux';
// components
import { getActiveClientReport } from '../../../store/coach/reducer';
import { BaseOptionChart } from '../../../components/chart';

// ----------------------------------------------------------------------



export default function ProgressChart() {

  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  
  const clientReport = useSelector(state => getActiveClientReport(state));

  useEffect(() => {

    const _chartLabels = [];
    const _chartData = [];

      if(clientReport && clientReport !== null && clientReport.daily_logs !== null){
          if(clientReport.daily_logs.weight !== null){
              clientReport.daily_logs.weight.forEach((element, i) => { 
                _chartLabels.push(element.label);
                _chartData.push(element.value);
              });
          }
      }

      setChartLabels(_chartLabels);
      
      setChartData([
        {
          name: 'Weight',
          type: 'line',
          fill: 'solid',
          data: _chartData,
        },
      ]);

  },[clientReport]);

  const chartOptions = merge(BaseOptionChart(), {
    colors : ['#2d99ff' ],
    chart: {
        type: 'bar',
        height: 350,
        stacked: true,
    },
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    // xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} kgs`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card  className='glass-box w-100'>
      <CardHeader title="Progress" />

      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
